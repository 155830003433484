import Config from '../config'; // confugration and links
const StockUrl = Config.StockUrl;

const Hero = () => {
    return {
        type: 'ecommerce',
        title : 'Do you need an e-commerce website for your business?',
        description : 'Nowadays, users go over your product and services from the internet (website). Today everyone is busy promoting their product and services over the website. To stand out in the herd you should have an eye-catchy website to grow your business.',

        isVideo : true,
        video : 'https://youtu.be/tTioEEHGcL4',
        buttonText : 'Start Project',
        buttonLink : 'start-web-development',
        image : StockUrl + 'services/e-commerce-bg.jpg',
        colormode : '#efdabf',
        theme : 'solid'
    }
};

const Discover = () => {
    return [
        {
            id : 0,
            icon : 'images/services/medical.png',
            title : 'Medical',
            description : 'Website and web apps'
        },
        {
            id : 1,
            icon : 'images/services/food.png',
            title : 'Foods',
            description : 'e-commerce and delivery'
        },
        {
            id : 2,
            icon : 'images/services/services.png',
            title : 'Services',
            description : 'Web and landing pages'
        },
        {
            id : 3,
            icon : 'images/services/education.png',
            title : 'Education',
            description : 'School &amp; Colleges'
        },
        {
            id : 6,
            icon : 'images/services/custom-design.png',
            title : 'Yours?',
            description : 'Let us collaborate.'
        }
    ];
};

const Video = () => {
    return {
        type : 'Web Development',
        title : 'Why you need a website?',
        description : 'Web design usually involves many varied disciplines and skills in the maintenance and production of websites. The different parts that encompass web designing include interface design, graphic design, authoring which includes proprietary software and standardized code, search engine optimization, and user experience design. Nowadays, people are always on the go and have little or no time to spare for leisure activities or shopping, etc. They are on the lookout for means and ways of reducing time spent in doing various tasks.',

        linkText : 'Advantages of Effective Web Design and Development  ➝',
        linkURL : 'asb3214264b0a',
        videoURL : 'https://www.youtube.com/embed/_iPQyIaHsiA',
    }
};

const Features = () => {
    return {
        title : "E-commerce development features!",
        description : "We create e-commerce websites with a unique look and feel, which not only instills trust into the visitor but also leaves them with a positive image of your brand.",
        data : [{
                    key : 1,
                    image : StockUrl + 'features/goal-oriented.svg',
                    title : 'Responsive & Retina Ready',
                    description : 'Our design is fully responsive and ready for retina displays. You will love how the website looks on devices.'
                },
                {
                    key : 2,
                    image : StockUrl + 'features/years-of-experience.svg',
                    title : 'Cross-Browser Support',
                    description : 'We investigate all tiny parts to work perfectly with all browsers throughout the world.'
                },
                {
                    key : 3,
                    image : StockUrl + 'features/quality-process.svg',
                    title : 'Search Engine Friendly',
                    description : 'We implement the latest SEO on-page and off-page tactics to get your business to the top of relevant organic search results.'
                },
                {
                    key : 4,
                    image : StockUrl + 'features/team-work.svg',
                    title : 'Unlimited Color Schemes',
                    description : 'We work with the best color suit on your website, but also once the theme is ready you can easily customize your color.'
                },
                {
                    key : 5,
                    image : StockUrl + 'features/deadline.svg',
                    title : 'Optimized for Morden Devices',
                    description : 'We implement an eye-catching responsive design that adapts with today’s mobile devices.'
                },
                {
                    key : 6,
                    image : StockUrl + 'features/support.svg',
                    title : 'Easy to Customise',
                    description : 'Your website can be fully customizable, we write clean codes which you can do it yourself or just one request will be enough.'
                }]
    }
};

const Portfolio = () => {
    return {
        title : 'E-commerce Portfolio of AStrick',
        description : 'To stand out in the herd you should have an eye-catchy website to grow your business. We create websites with a unique look and feel because, the better your website is - the more visitor is converted into customers.',
        
        data : [
            {
                id : 2,
                title : 'Reddseqiop',
                proptype : 'E-commerce',
                image : StockUrl + 'portfolio/reddsequip.jpg',
                linkType: 'image',
                link : 'reddsequip-home-page.png',
            },
            {
                id : 3,
                title : 'Online Comics Museum',
                proptype : 'Comics Book',
                image : StockUrl + 'portfolio/comics.jpg',
                linkType: 'image',
                link : 'online-comics-home-page.jpg'
            }
        ]
    }
};

const Queries = () => {
    return [
        {
            id : 1,
            icon : 'images/services/choose.png',
            question : 'Why should I choose AStrick?',
            answer : 'At AStrick, we have the power to humanize your brand, promote your products, educate your customers, and so much more! It is not only about having one best Web Design on your homage. AStrick will improve your marketing effort, and better your ongoing strategy.'
        },
        {
            id : 2,
            icon : 'images/services/duration.png',
            question : 'How long does a e-commerce site take?',
            answer : 'It depends upon the site requirements, However, we try to deliver within 45 days of working days for professional and attractive simple cms based e-commerce websites.'
        },
        {
            id : 3,
            icon : 'images/services/video-process.png',
            question : 'How does our web design process work?',
            answer : 'We are dedicated to helping our customers succeed in their business. Our mission is to provide our clients and customers with exceptional service at the best possible value. You can check out our detailed working process.'
        },
        {
            id : 4,
            icon : 'images/services/compare.png',
            question : 'How we differ from others?',
            answer : 'Unlike others, we create our unique and responsive websites, landing pages for each website, and even we add life to your products with an explainer video if needed. All our assets are 100% copyright-free, and thus will give you your won ownership of these items.'
        },
    ]
};

const Review = () => {
    return {
        review : 'There is no doubt in my mind that, this is absolutely the best web design company. Their web development skills are the best I have seen ever and their work ethics are very professional. They created a high-quality web site for my business and it was the best investment I made for my business. Sales have increased dramatically thank you AStrick and the Team!',
        customer : 'Arlington - United States',
    }
};

export default {
    Hero,
    Discover,
    Video,
    Features,
    Portfolio,
    Queries,
    Review,
}