<template>
    <div class="blog--list">
        <div class="wrapper">
            <div class="default-list">
                <ul class="dl-large">
                    <li v-for="item in LargeItems" :key="item" :style="{backgroundImage: 'url('+ item.bgimage +')'}">
                        <router-link :to="{path: '/blog/'+item.id+'/'}">
                            <div class="content">
                                <h3>{{item.title}}</h3>
                                <p>{{item.created}}</p>
                            </div>
                        </router-link>
                    </li>
                </ul>

                <ul class="dl-small">
                    <li v-for="item in SmallITems" :key="item" class="default">
                        <router-link :to="{path: '/blog/'+item.id+'/'}">
                            <div class="image">
                                <ImageApp :source="item.bgimage" />
                            </div>
                            <div class="content">
                                <h3>{{item.title}}</h3>
                                <p>{{item.created}}</p>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import Config from './../../api/config';

    //@ import component
    import ImageApp from './../app/ImageApp.vue';

    export default {
        name: "DefaultList",
        props: ['content'],
        components: {
            ImageApp,
        },

        setup (props) {
            let LargeItems = [];
            let SmallITems = [];
            let BlogStockUrl;

            props.content.map ((item, index) => {
                BlogStockUrl = Config.StockUrl + 'blog/' + item.year + '/' + item.image;
                item['bgimage'] = BlogStockUrl;

                if (index == 0 || index == 1) {
                    LargeItems.push (item);
                }else {                
                    SmallITems.push(item);
                }
            });

            return {LargeItems, SmallITems}
        }
    };
</script>