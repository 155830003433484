<template>
    <div class="newsletters">
        <h3>Ready to improve your customer engagement?</h3>
        <div class="action">
            <span class="order" @click="StartProject('get-started')">Get Started</span>
            <span class="quote" @click="StartProject('request-quote')">Request a Quote</span>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'NewsLetter',
        emits: ['showmodal'],
        components: {
        },

        setup (props, {emit}) {
            
            let StartProject = (project) => {
                emit('showmodal', project);
            };

            return {
                StartProject,
            }
        }
    }

</script>