<script>
    // js import
    import PortfolioInternal from './../../api/PortfolioInternal';
    import Config from './../../api/config';

    //@import component
    import BreadCrumb from './../../components/portfolio/BreadCrumb.vue';
    import MoreProject from './../../components/portfolio/MoreProjects.vue';

    export default {
        components: {
            BreadCrumb,
            MoreProject,
        },

        setup(props) {
            let Internal = PortfolioInternal.PortfolioInternals();
            Internal = Internal.tigerflo;

            let StockUrl = Config.StockUrl;

            // page title
            Config.PageTitle ({
                title : Internal.title + ' - AStrick Software'          
            });
            
            return {
                Internal,
                StockUrl
            }
        }
    };
</script>

<template>
    <BreadCrumb :content="Internal" />

    <section class="features grey">
        <div class="secwrapper">                    
        
            <h3>Project Planning</h3>

            <p>Once we start collecting information about the company business process and the business model, we start planning to make sketches and wireframes in collaboration with the client. They want very simple and well-functioning website and web application for the global payment, Foreign and stock exchange enabled apps.</p>
            
            <img :src="StockUrl + 'portfolio/tigerflo-home-page.png'" />

            <p>We started working on the front-end design and prototyping using the collected wireframes in Adobe XD. After few tweaks they like our design and we move forward to front-end development and back-end.</p>

        </div>
    </section>

    <section class="features">
        <div class="secwrapper"> 
                <div class="multi-image">
                <img :src="StockUrl + 'portfolio/tigerflo-large-icon-1.jpg'"  style="margin-right:2%;" />
                <img :src="StockUrl + 'portfolio/tigerflo-large-icon-2.jpg'" style="margin-left:2%;" />
            </div>
            
            <h3>Website Relevant Images and Stocks</h3>
            
            <p>In the process of understanding their needs and nature of business, we realised that they need many of vector images which can justify their articles. So our team continuously worked on making raw vector images at our lab. Hand drawn concepts to digitize them in vector form is a hell tedious work but we make them simple easy and fun. Finally, we can justify each and every article and their business as a whole.</p>

        </div>
    </section>

    <section class="features grey">
        <div class="secwrapper"> 
            <h3>Dashboard Development</h3>

            <p>They have multiple dashboard to create, we started from the client dashboard with different features like simple signup, account settings, payment activity, sending and requesting payments, Recipients database and if their client are business type they have further extra settings and features too.</p>
            
            <p>We finalise the first version of client and admin dashboard with all required features.</p>
            
            <img :src="StockUrl + 'portfolio/tigerflo-dashboard.jpg'" />

        </div>
    </section>
    
    <MoreProject :current="'Tigerflo'" :type="'project'" />

</template>