<template>
    <ul v-if="type=='icon-menu'" class="skeletion">
        <li v-for="time in loop" :key="time">
            <div class="sk-icon"><span class="skgreylight"></span></div>
            <div class="sk-content">
                <div class="sk-title"><span class="skgreydark"></span></div>
            </div>
        </li>
    </ul>

    <ul v-else-if="type=='large-list'" class="skeletion">
        <li v-for="time in loop" :key="time" style="margin-bottom: 45px;">
            <div class="sk-image"><span class="skgreylight"></span></div>
            <div class="sk-content">
                <div class="sk-title"><span class="skgreydark"></span></div>
                <div class="sk-title-long"><span class="skgreylight"></span></div>
                <div class="sk-title-long"><span class="skgreylight"></span></div>
                <div class="sk-title-long"><span class="skgreylight"></span></div>
            </div>
        </li>
    </ul>

    <ul v-else-if="type=='line'" class="skeletion">
        <li>
            <div class="sk-content">
                <div v-if="dark" class="sk-title"><span class="skgreydark" :style="css"></span></div>
                <div v-else class="sk-title"><span class="skgreylight" :style="css"></span></div>
            </div>
        </li>
    </ul>

    <ul v-else-if="type=='small-line'" class="skeletion">
        <li>
            <div class="sk-content">
                <div v-if="dark" class="sk-title"><span class="skgreydark" :style="css" style="height: 10px;"></span></div>
                <div v-else class="sk-title"><span class="skgreylight" :style="css"></span></div>
            </div>
        </li>
    </ul>

    <ul v-else class="skeletion">
        <li v-for="time in loop" :key="time">
            <div class="sk-image"><span class="skgreylight"></span></div>
            <div class="sk-content">
                <div class="sk-title"><span class="skgreydark"></span></div>
                <div class="sk-title-long"><span class="skgreylight"></span></div>
            </div>
        </li>
    </ul>
</template>

<script>
    
    /**
     * props {loop} and {type}
     * loop defines how many time the skeleton to be displayed
     * type defines what type of skeleton you want to show on the stage
     * 
     * type 1: 'user-list', it will display a circle image, and 2 lines
     * type 2: 'simple-list', it will display only 2 lines
     * type 3: 'large-list', it will display 1 big title and 2 more small lines
     * type 4: 'menu', it will display only one line
     * type 5: 'icon-menu', it will display small cricle with one line
     * type 6: 'single-line', it will display small line
     * 
    */


    export default {
        name: 'Skeleton',
        props: ['loop', 'type', 'dark', 'css'],
    };
</script>

<style scoped>

/* Skeletion */
.skgreydark {
background: linear-gradient(270deg, #ebebeb, #d7d7d7, #ebebeb);
background-size: 600% 600%;
-webkit-animation: sk-grey-dark 2s ease infinite;
-moz-animation: sk-grey-dark 2s ease infinite;
-o-animation: sk-grey-dark 2s ease infinite;
animation: sk-grey-dark 2s ease infinite;
}

@-webkit-keyframes sk-grey-dark {
0%{background-position:0% 50%}
50%{background-position:100% 51%}
100%{background-position:0% 50%}
}
@-moz-keyframes sk-grey-dark {
0%{background-position:0% 50%}
50%{background-position:100% 51%}
100%{background-position:0% 50%}
}
@-o-keyframes sk-grey-dark {
0%{background-position:0% 50%}
50%{background-position:100% 51%}
100%{background-position:0% 50%}
}
@keyframes sk-grey-dark {
0%{background-position:0% 50%}
50%{background-position:100% 51%}
100%{background-position:0% 50%}
}

.skgreylight {
background: linear-gradient(270deg, #ebebeb, #e6e6e6, #ebebeb);
background-size: 600% 600%;

-webkit-animation: sk-grey-light 2s ease infinite;
-moz-animation: sk-grey-light 2s ease infinite;
-o-animation: sk-grey-light 2s ease infinite;
animation: sk-grey-light 2s ease infinite;

}

@-webkit-keyframes sk-grey-light {
0%{background-position:0% 50%}
50%{background-position:100% 51%}
100%{background-position:0% 50%}
}
@-moz-keyframes sk-grey-light {
0%{background-position:0% 50%}
50%{background-position:100% 51%}
100%{background-position:0% 50%}
}
@-o-keyframes sk-grey-light {
0%{background-position:0% 50%}
50%{background-position:100% 51%}
100%{background-position:0% 50%}
}
@keyframes sk-grey-light {
0%{background-position:0% 50%}
50%{background-position:100% 51%}
100%{background-position:0% 50%}
}



ul.skeletion {width: 100%;height: auto;margin: 0px auto;}
ul.skeletion li {    width: auto;
    height: auto;
    overflow: hidden;
    list-style-type: none !important;
    padding: 0;
    margin-left: 0;}
ul.skeletion li:before {background-image: none !important;}

/* small images */
ul.skeletion li .sk-image {width: 50px;height: 50px;margin-right: 10px;float: left;}

/* small icons */
ul.skeletion li .sk-icon {width: 20px;height: 20px;margin-right: 10px;float: left;}

/* content */
ul.skeletion li .sk-content {width: 100%;height: auto;float: left;}

ul.skeletion li .sk-image span {display: block;width: 100%;height: 100%;border-radius: 100%;}
ul.skeletion li .sk-icon span {display: block;width: 100%;height: 100%;border-radius: 100%;}

ul.skeletion li .sk-content .sk-title {width: 100%;height: auto;margin-top: 12px;}
ul.skeletion li .sk-content .sk-title span {display: block;width: 100%;height: 15px;border-radius: 25px;}

</style>