<script>
    // import js
    import Config from './../../api/config';

    // import component
    import Crumb from './../../components/policy/Crumb.vue';
    import Links from './../../components/policy/Links.vue';

    export default {
        name: 'Privacy',
        components: {
            Crumb,
            Links
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'Privacy Policy - AStrick Software'              
            });
            
        }

    };
</script>

<template>
    <Crumb :page="'Privacy and Policy'" />

    <section class="_policies_page">
        <div class="content-wrapper">
            <h3>Privacy and Policy</h3>
                    
            <p>TThe following Privacy Policy Notice describes your privacy rights regarding our collection, use, storage, sharing, and protection of your personal information. It applies to the www.astricksoftware.com website and all services offered by AStrick Software Pvt. Ltd. (hereinafter called as “AStrick”). AStrick ® is owned and operated by AStrick Software Pvt. Ltd. and is offered subject to your acceptance of this Privacy Policy Notice without modification. Please read this Privacy Policy Notice carefully and completely before engaging us for services.</p>
            
            <h2>Nondisclosure agreement (NDA) for AStrick Clients.</h2>
            
            <p>AStrick respects your professional artwork as well as other intellectual property shared with us and will only use it to assist you with your goals. Your copyrights will remain yours.</p>
            <p>AStrick will not take your artwork and other intellectual property for use on any other site, resell it, or give it to anyone under any AStrick guarantees that all information that you share with us, including artwork and other intellectual property, contact information, and client notes, will remain confidential.</p>
            
            <p>AStrick will never contact your clients or the clients of your clients. We respect your business.</p>
            
            <p>Notwithstanding the foregoing, AStrick may disclose any such information in response to a valid order of a court or other government body or as required by law, but only that portion of the Confidential Information which is legally required to be disclosed, or such information as may be required to resolve any dispute between you and AStrick.</p>
            
            <h2>Our commitment to data security</h2>
            
            <p>To prevent unauthorized access, maintain data accuracy, and ensure the correct use of information, we have put in place appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect online.</p>
            
            <h2>How secure is my information?</h2>
            
            <p>This site uses state-of-the-art digital encryption and security technologies to protect the privacy and security of our members' information and has specific security measures in place to protect against the loss, misuse, and alteration of the information under our control. All data passing from your computer to this site via HTTPS is authenticated and encrypted using 256-bit Secure Socket Layer (SSL) encryption. This is the most advanced level of encryption available today, and it means that any information sent from your computer is scrambled in such a way that makes it completely unintelligible if intercepted. When you are on a secure section within this site, a padlock will appear at the bottom (or top) of your web browser's screen. This is your assurance that the encryption is in place and that you are communicating across a secure link.</p>
            
            <h2>Information we collect</h2>
            <p>This notice applies to all the information that is collected or submitted on the AStrick site through the product order page, product request page, and other pages. The following types of personal information are collected:</p>
            
            <ul>
                <li>E-mail address</li>
                <li>The IP address for fraud detection purposes</li>
                <li>Client information requests</li>
                <li>Order-specific information, including source files and client notes</li>
                <li>Information on referring sites through the use of cookies</li>
            </ul>
            
            <h2>How we use your information</h2>
            
            <p>The personal information you provide when placing an order is used only to complete your order. We do not share this information with outside parties.</p>
            
            <p>The e-mail address that you provide in the contact/order form is used only to notify you about the status of your order and to send you occasional AStrick newsletters. We do not use this e-mail address for any other purpose, and we do not share it with outside parties.</p>
            
            <p>To improve the design of our site and to better share it with advertisers, we use only non-identifying, aggregate information. For example, we may tell an advertiser that a certain number of individuals visited some area of our site, but we do not disclose anything that could be used to identify those individuals.</p>
            
            <p>Finally, we never use or share the personally identifiable information provided to us online in ways unrelated to those described above without also providing you with an opportunity to opt-out or otherwise prohibit such unrelated uses.</p>
            
            <h2>How to contact us</h2>
            
            <p>If you have further questions or any queries regarding AStrick services, please complete the contact form, and also we support you through live chat 24x7.</p>

        </div>
    </section>

    <Links />
</template>