<template>
    <section class="internal--landing icontactus" :style="{'background-image':'url('+require('../../images/api/large/home-page.jpg')+')'}">
        <div class="wrapper">
            <div class="content">

                <ul>
                    <li><a href="<?php echo HOST; ?>index.php">Home</a></li>
                    <li><span class="devider"></span></li>
                    <li><span>Contact Us</span></li>
                </ul>

                <h3>Contact AStrick Today!</h3>
                <p>We value our customers and believe them as our god. We stand by 24 x7 to help them, fix their problem and challenges.</p>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name : 'InternalHero',
    }
</script>

<style scoped>

</style>