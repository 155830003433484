<script>
    // js import
    import PortfolioInternal from './../../api/PortfolioInternal';
    import Config from './../../api/config';

    //@import component
    import BreadCrumb from './../../components/portfolio/BreadCrumb.vue';
    import MoreProject from './../../components/portfolio/MoreProjects.vue';

    export default {
        components: {
            BreadCrumb,
            MoreProject,
        },
        
        setup(props) {
            let Internal = PortfolioInternal.PortfolioInternals();
            Internal = Internal.astasks;

            let StockUrl = Config.StockUrl;

            // page title
            Config.PageTitle ({
                title : Internal.title + ' - AStrick Software'          
            });

            return {
                Internal,
                StockUrl
            }
        }
    };
</script>

<template>
    <BreadCrumb :content="Internal" />

    <section class="features grey">
        <div class="secwrapper">
            <div class="multi-image">
                <img :src="StockUrl + 'portfolio/astasks-logo-sketch.jpg'" style="margin-right:2%;">
                <img :src="StockUrl + 'portfolio/astasks-final-logo.jpg'" style="margin-left:2%; border:none;">
            </div>
            <h3>Brand and Project Plan</h3>
            <p>AStasks was founded in 2015, since then we have made many more happy clients with our creativity and product branding. We are moving forward with our creative co-workers to develop our product as a new innovation with an aim to make it as pixel perfect in design, UI/UX, SEO-Friendly and so on.</p>
            <ul class="services">
                <li>
                    <img :src="StockUrl + 'portfolio/speedometer-96.png'" />
                    <p>Dashboard</p>
                </li>
                <li>
                    <img :src="StockUrl + 'portfolio/suitcase-96.png'" />
                    <p>Projects</p>
                </li>
                <li>
                    <img :src="StockUrl + 'portfolio/conference-96.png'" />
                    <p>Team/Profile</p>
                </li>
                
                <li>
                    <img :src="StockUrl + 'portfolio/tasks-96.png'" />
                    <p>Tasks</p>
                </li>
                <li>
                    <img :src="StockUrl + 'portfolio/read_message-96.png'" />
                    <p>Instant Message</p>
                </li>
                <li>
                    <img :src="StockUrl + 'portfolio/appointment_reminders-96.png'" />
                    <p>Notifications</p>
                </li>
            </ul>
            
            <h3>Application Services</h3>
            <p>We have already many more happy clients with our after sales services as we provide our clients, unlike others, 24/7 Support. Project management, detailed team profile, instant chat with the team, notification to the employer even if they are away and clear and handy dashboard are our added features of AStasks.</p>
        </div>
    </section>

    <section class="features">
        <div class="secwrapper">
            <img :src="StockUrl + 'portfolio/login-astasks.jpg'" />
            <h3>Starting App</h3>
            <p>Nowadays, everybody want to go ahead of others, move fast, grow rapidly in their business and henceforth every one and others want the best man in job. Every big corporate houses are competing with each other in every possible ways. Dedicated, motivated and enthusiastic human resource is the key for any successful</p>
            <p>We are group of creative people who work together for the success of any project. Back some time we got an opportunity to work as an IT in Forex Company. Managing the team comprising of more than 45+ staffs from different countries, no doubt, was tough. Follow ups, routine check-up of every staff’s work was very complex in itself. It was really difficult to manage every team members. That’s why we decided to develop a web application that incorporate all the problems and hurdles, we feel, during the then time, with its prominent solution. And as result we were successful to develop this innovative and creative web application as an AStasks. We are now very comfortable with what we do and it’s really easy to monitor my team members.</p>
        </div>
    </section>

    <section class="features grey">
        <div class="secwrapper">           
            <div class="multi-image">
                <img :src="StockUrl + 'portfolio/astasks-screen-1.jpg'" style="margin-right:2%;" />
                <img :src="StockUrl + 'portfolio/astasks-screen-2.jpg'" style="margin-left:2%;" />
            </div>
            <div class="multi-image">
                <img :src="StockUrl + 'portfolio/astasks-screen-3.jpg'" style="margin-right:2%;margin-top: 0px;" />
                <img :src="StockUrl + 'portfolio/astasks-screen-4.jpg'" style="margin-left:2%;margin-top: 0px;" />
            </div>
            <h3>Application Screenshots</h3>
            <p>Sample of the application that demonstrate it works on all devices of any size.</p>
        </div>
    </section>

    <MoreProject :current="'AStasks'" :type="'product'" />

</template>


<style scoped>
    ul.services {
    list-style-type: none;
    margin: 40px 0;
    padding: 0px;
    width: 99%;
    overflow: hidden;
}
    ul.services > li {
    float: left;
    width: calc(16.66% - 22px);
    margin: 10px;
    text-align: center;
    border: 1px solid #E1E1E1;
    overflow: hidden;
}
    ul.services > li > img {
        width: 90px;
        border: none;
        margin: 0px;
        text-align: center;
    }
    ul.services > li > p {
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.5px;
    color: rgba(0, 0, 0, 0.38);
    text-align: center;
    margin: 0px;
    background-color: rgba(225, 225, 225, 0.39);
    padding: 5px;
    border-top: 1px solid #E1E1E1;
    width: calc(100% - 10px);
}
</style>