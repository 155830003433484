<template>
    <section class="testinomials">
        <div class="wrapper">
            <p class="tag-lne">ASTRICK IS TRUSTED BY THOUSANDS OF PROFESSIONAL WORLD-WIDE.</p>            
            
            <ul class="logo-wrp">
                <li title="Allergy Free Air"><img src="../../images/client/allergy-free-air.png"></li>
                <li title="Choose Your Forex"><img src="../../images/client/choose-your-forex.png"></li>
                <li title="Shipma"><img src="../../images/client/shipma.png"></li>
                <li title="Hatudo"><img src="../../images/client/hatudo.png"></li>
                <li title="Scroller Media"><img src="../../images/client/scroller-media.png"></li>
                <li title="Tigerflo Global Payments"><img src="../../images/client/tigerflo.png"></li>
                <li title="5Stars Forex Ltd"><img src="../../images/client/5stars-forex.png"></li>
                <li title="Cloudfiles"><img src="../../images/client/cloudfiles.png"></li>
                <li title="Prestado App"><img src="../../images/client/prestado-logo.png"></li>
            </ul>

            <ul class="testinomials-wrap">
                <li>
                    <div class="avatar-left">
                        <img src="../../images/client/user-1.jpg">
                    </div>
                    <div class="review-right">
                        <p>"There is no doubt in my mind that, this is absolutely the best web design company. Their web development skills is the best I have seen ever and their work ethics is very professional."</p>
                        <h2>Arlington</h2>
                        <p class="designation">Website Design</p>
                    </div>
                </li>
                <li>
                    <div class="avatar-left">
                        <img src="../../images/client/user-2.jpg">
                    </div>
                    <div class="review-right">
                        <p>"It was a pleasure working with AStrick! Not only they understand our vision, but they exceeded our expectations on the quality of work that was produced."</p>
                        <h2>Samuelcogo</h2>
                        <p class="designation">Explainer Video</p>
                    </div>
                </li>
                <li class="n-mar">
                    <div class="avatar-left">
                        <img src="../../images/client/user-3.jpg">
                    </div>
                    <div class="review-right">
                        <p>"Launching a new product for our company and needed a stand-alone site for the project. No hand-holding, no hassle, just an awesome, AWESOME, experience. Just WOW!!"</p>
                        <h2>Yik Jaan Tan</h2>
                        <p class="designation">Mobile Application</p>
                    </div>
                </li>
                <li >
                    <div class="avatar-left">
                        <img src="../../images/client/user-4.jpg">
                    </div>
                    <div class="review-right">
                        <p>"This company did a great job on very little information from me. I cannot wait to see results from my next project where I give a lot more instruction :) Really appreciated! Good Job!!"</p>
                        <h2>Paul Louis</h2>
                        <p class="designation">Web Application</p>
                    </div>
                </li>
                <li>
                    <div class="avatar-left">
                        <img src="../../images/client/user-5.jpg">
                    </div>
                    <div class="review-right">
                        <p>"AStrick software is helping our sales increase through online system. They are a really professional, quick responsive team which helps your business grow digitally."</p>
                        <h2>Eyalbartfeld</h2>
                        <p class="designation">Chairman</p>
                    </div>
                </li>
                <li class="n-mar">
                    <div class="avatar-left">
                        <img src="../../images/client/user-6.jpg">
                    </div>
                    <div class="review-right">
                        <p>"The seller created a video that exactly matched my requirements and maintained the brand from previous videos that I had ordered"</p>
                        <h2>Fuller</h2>
                        <p class="designation">Explainer Video</p>
                    </div>
                </li>
                
                
            </ul>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Testimonials'
    }
</script>