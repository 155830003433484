<template>
    <IllustrationHero :content="BreadCrumbsData" />   

    <!-- Section Open Positions -->
    <section class="open__pos">
        <div class="wrapper">
            <h2>Currently, We are seeking for</h2>
            <p>AStrick is a fast-growing company and we're always expanding our business in every aspect. Currently, we are seeking for a very passionate and enthusiastic youth individual for the following:</p>

            <ul class="op__aply">
                <li @click="StartProject = true">
                    <div class="oa__img">
                        <img src="../../images/icons/front-end.png" alt="Front-End Design">
                    </div>

                    <div class="oa__cnt">
                        <h2>Front-End Designer (1)</h2>
                        <p>We are looking for a creative front-end designer to write our mockups in HTML/CSS.</p>
                    </div>
                </li>

                <li @click="StartProject = true">
                    <div class="oa__img">
                        <img src="../../images/icons/google_seo.png" alt="SEO">
                    </div>

                    <div class="oa__cnt">
                        <h2>SEO Expert (1)</h2>
                        <p>On-Page and Off-Page Search Engine Optimization and Email Marketing expert.</p>
                    </div>
                </li>

            </ul>
        </div>
    </section>
    <!-- Section Open Positions --> 

    <!-- Section Benefits -->
    <PageFeatures :content="Features" :extra="false"  />
    <!-- Section Benefits -->

    <!-- Section Open Positions -->
    <section class="open__pos">
        <div class="wrapper">
            <h2>Currently, We are seeking for</h2>
            <p>AStrick is a fast-growing company and we're always expanding our business in every aspect. Currently, we are seeking for a very passionate and enthusiastic youth individual for the following:</p>

            <ul class="op__aply">
                <li @click="StartProject = true">
                    <div class="oa__img">
                        <img src="../../images/icons/front-end.png" alt="Front-End Design">
                    </div>

                    <div class="oa__cnt">
                        <h2>Front-End Designer (1)</h2>
                        <p>We are looking for a creative front-end designer to write our mockups in HTML/CSS.</p>
                    </div>
                </li>

                <li @click="StartProject = true">
                    <div class="oa__img">
                        <img src="../../images/icons/google_seo.png" alt="SEO">
                    </div>

                    <div class="oa__cnt">
                        <h2>SEO Expert (1)</h2>
                        <p>On-Page and Off-Page Search Engine Optimization and Email Marketing expert.</p>
                    </div>
                </li>

            </ul>
        </div>
    </section>
    <!-- Section Open Positions -->

    <!-- Modals -->
    <Fullscreen v-if="StartProject" :from="'get-started'" :content="'career'" @close="CloseModal" />

</template>

<script>
    // vue
    import { ref } from '@vue/reactivity';

    // component
    import Fullscreen from './../../components/modal/Fullscreen.vue';

    // javascript Library
    import Locale from './../../api/Locale';
    import Config from './../../api/config';
    
    //@ import resuable components
    import IllustrationHero from '../../components/reusable/IllustrationHero.vue';
    import PageFeatures from '../../components/reusable/PageFeatures.vue';
    

    export default {
        name : 'Career',

        components : {
            IllustrationHero,
            PageFeatures,

            Fullscreen,
        },

        setup () {
            // get started
            let StartProject = ref(false);

            // close the modal
            let CloseModal = () => {
                StartProject.value = false;
            };

            // page title
            Config.PageTitle ({
                title : 'Career - AStrick Software'              
            });

            const BreadCrumbsData = {
                page : 'Career',
                title : 'Come and work with AStrick',
                description : 'If you’re bright, excited about world-changing software, and want to do something amazing, join us.',
                image : 'career.svg'
            }

            // page featrues
            let Features = Locale.CareerBenefits();

            return {
                StartProject,
                CloseModal,

                BreadCrumbsData,
                Features,
            };
        }
    };
</script>