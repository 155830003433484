<script>
    export default {
        name : 'ServicesReview',
        props : ['review']
    }
</script>

<template>
    <section class="__trusted_companies">
        <div class="wrapper">
            <h2>Trusted by amazing companies!</h2>
            <p>AStrick is a very famous and creative digital platform. Our main aim is to spread your business to gain more sales in a short time with the help of modern technology.</p>
            
            <div class="hr"><img src="../../images/services/rating.png"></div>
            
            <h3>"{{review.review}}"</h3>            
            <h4>{{review.customer}}</h4>            
            <ul>
                <li class="ease-effect"><img src="../../images/client/tigerflo.png" alt="Tigerflo" title="Tigerflo"></li>
                <li class="ease-effect"><img src="../../images/client/5stars-forex.png" alt="5Stars Forex" title="5Stars Forex"></li>
                <li class="ease-effect"><img src="../../images/client/scroller-media.png" alt="Scroller Media" title="Scroller Media"></li>
                <li class="ease-effect"><img src="../../images/client/choose-your-forex.png" alt="Choose Your Forex" title="Choose Your Forex"></li>
                <li class="ease-effect"><img src="../../images/client/allergy-free-air.png" alt="Allergy Free Air" title="Allergy Free Air"></li>
                <li class="ease-effect"><img src="../../images/client/hatudo.png" alt="Hatudo" title="Hatudo"></li>
            </ul>            
        </div>
    </section>
</template>