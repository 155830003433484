<template>
  
  <!-- Main Naivation -->
  <MainNav />
  
  <!-- Router View -->
  <router-view/>

  <!-- Services Intro -->
  <FooterServices />

  <!-- Footer Section -->
  <Footer />

  <!-- Loader 
  <div class="loadwebsite" id="loadwebsite" style="position: fixed;display: block;inset: 0px;background-color: rgb(255 255 255 / 97%);z-index: 99999999;text-align: center;">
      <div style="display: block;position: relative;width: 190px;background-color: rgb(255, 255, 255);vertical-align: middle;margin: 0px auto;top: calc(50vh - 55px);text-align: center;padding: 10px;border-radius: 18px;box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 35px;border: 1px solid #64aed93b;">
          <Logo />
          <p style="font-size: 9pt;letter-spacing: 0.15px;line-height: 30px;color: #abd8ff;">Loading...</p>
      </div>
  </div> -->

</template>

<script>

  // @import Componernts
  import Logo from './components/navigation/Logo.vue';
  import MainNav from './components/navigation/MainNav.vue';

  // services intro above footer in all pages
  import FooterServicesIntro from './components/footer/FooterServicesIntro.vue';
  import FooterServices from './components/footer/FooterServices.vue';

  // Main Footer
  import Footer from './components/footer/Footer.vue';

  export default {
      components: {
          Logo,
          MainNav,
      
          FooterServicesIntro,
          FooterServices,
          Footer,
      },
  };

</script>