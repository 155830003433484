<template>
    <header id="_sdheader" class="aation animateds" data-nav-status="toggle">    
        <div class="wrapper" style="overflow:visible;">
            <Logo />

            <ul class="innernav navbor innernav-desk">
                <li class="ease-effect">
                    <router-link :to="{name : 'Home'}">
                        Home
                    </router-link>
                </li>

                <li class="ease-effect">
                    <router-link :to="{name : 'Blog'}">
                        Blog
                    </router-link>
                </li>

                <li class="ease-effect" @mouseenter="ServicesMenuHover = true" @click="ServicesMenuHover = false">
                    <a href="#">Services <i class="down"></i></a>
                    <ServicesHover v-if="ServicesMenuHover" :blog="RelatedBlog" :key="RelatedBlog" @quote="RequestQuoteHandler" />
                </li>

                <li class="ease-effect" @mouseenter="WorksMenuHover = true" @click="WorksMenuHover = false">
                    <a href="#">Works <i class="down"></i></a>
                    <WorksHover v-if="WorksMenuHover" />
                </li>

                <li class="ease-effect" @mouseenter="AboutMenuHover = true" @click="AboutMenuHover = false">
                    <a href="#about">About Us <i class="down"></i></a>
                    <AboutHover v-if="AboutMenuHover" style="left: -320px;" />
                </li>

                <li class="ease-effect">
                    <router-link :to="{name : 'Contact'}">Contact</router-link>
                </li>
            </ul>

            <div class="innernav-mobile mobileMenu" @click="ResponsiveMenu = true">
                <img src="./../../images/icons/hamburger.svg" class="mobileMenu" />
            </div>
        </div>
    </header>

    <!-- Modals -->
    <Fullscreen v-if="RequestQuote == true" :from="'get-started'" :content="'get-started'" @close="CloseModal" />
    <Fullscreen v-if="ResponsiveMenu == true" :from="'responsive-menu'" @close="RespMenuClose" />

</template>

<script>
    // js import
    import Request from './../../api/getRequest';

    // import javascript library
    import { ref } from '@vue/reactivity';    

    // @import Componernts
    import Logo from './Logo.vue';
    import ServicesHover from './ServicesHover.vue';
    import WorksHover from './WorksHover.vue';
    import AboutHover from './AboutHover.vue';

    // components
    import Fullscreen from './../modal/Fullscreen.vue';

    export default {
        name: 'MainNav',

        components: {
            Logo,
            ServicesHover,
            WorksHover,
            AboutHover,

            Fullscreen
        },

        setup () {
            const ServicesMenuHover = ref(false);
            const WorksMenuHover = ref(false);
            const AboutMenuHover = ref(false);
            const ResponsiveMenu = ref(false);

            let dataIsLoading = ref(true);
            let RelatedBlog = [];

            // request a quote from navigation
            let RequestQuote = ref(false);
            let RequestQuoteHandler = () => {
                RequestQuote.value = true;
            };

            // close modal
            let CloseModal = () => {
                RequestQuote.value = false;
            };

            // close reponsive menu
            let RespMenuClose = () => {
                ResponsiveMenu.value = false;
            };

            return {
                dataIsLoading,
                RelatedBlog, 
                ServicesMenuHover,
                WorksMenuHover,
                AboutMenuHover,
                ResponsiveMenu,
                
                RequestQuote,
                RequestQuoteHandler,
                CloseModal,
                RespMenuClose,
            }
        },

        async mounted () {
            let Result = await Request.getData('related');
            if (!Result.error) {
                this.RelatedBlog = Result.response;
                this.dataIsLoading = false;
            }
        }
        
    };
</script>