<template>
    
    <div class="resp-menu">
        <div class="image">
            <LogoVue />
        </div>

        <ul>
            <li @click="RouteChange ('/about-us/')">
                <img class="a" src="./../../images/svg/resp-about.svg" />
                <p>About Us</p>
                <img class="z" src="./../../images/icons/right.svg" />
            </li>
            <li @click="RouteChange ('/contact-us/')">
                <img class="a" src="./../../images/svg/resp-contact.svg" />
                <p>Contact Us</p>
                <img class="z" src="./../../images/icons/right.svg" />
            </li>
            <li @click="RouteChange ('/portfolio/')">
                <img class="a" src="./../../images/svg/resp-portfolio.svg" />
                <p>Portfolio</p>
                <img class="z" src="./../../images/icons/right.svg" />
            </li>

            <li @click="RouteChange ('/team/')">
                <img class="a" src="./../../images/svg/resp-team.svg" />
                <p>Meet The Team</p>
                <img class="z" src="./../../images/icons/right.svg" />
            </li>

            <li @click="RouteChange ('/blog/')">
                <img class="a" src="./../../images/svg/resp-blog.svg" />
                <p>Blog</p>
                <img class="z" src="./../../images/icons/right.svg" />
            </li>

            <li @click="RouteChange ('/career/')">
                <img class="a" src="./../../images/svg/resp-career.svg" />
                <p>Career</p>
                <img class="z" src="./../../images/icons/right.svg" />
            </li>
        </ul>

        <ul style="margin-top: 25px;">
            <li @click="RouteChange ('/web-design/')">
                <img class="a" src="./../../images/svg/resp-web.svg" />
                <p>Web Development</p>
                <img class="z" src="./../../images/icons/right.svg" />
            </li>

            <li @click="RouteChange ('/e-commerce/')">
                <img class="a" src="./../../images/svg/resp-ecommerce.svg" />
                <p>E-commerce Websites</p>
                <img class="z" src="./../../images/icons/right.svg" />
            </li>

            <li @click="RouteChange ('/mobile-app/')">
                <img class="a" src="./../../images/svg/resp-mobile.svg" />
                <p>Mobile Apps</p>
                <img class="z" src="./../../images/icons/right.svg" />
            </li>

            <li @click="RouteChange ('/explainer-video/')">
                <img class="a" src="./../../images/svg/resp-explainer.svg" />
                <p>Explainer Video</p>
                <img class="z" src="./../../images/icons/right.svg" />
            </li>
        </ul>
    </div>

</template>

<script>

    // component
    import LogoVue from "../navigation/Logo.vue";

    import { useRouter } from "vue-router";

    export default {
        emits: ['close'],
        components: {
            LogoVue,
        },

        setup (props, {emit}) {
            
            const router = useRouter();
            const RouteChange = (route) => {
                // close modal
                emit('close');
                
                // change route
                router.push (route);

            }

            return {
                RouteChange
            }
        }
    }

</script>

<style scoped>

    div.resp-menu {    width: 100%;
    height: 85vh;
    margin-top: 25px;
    overflow-y: scroll;}
    div.resp-menu div.image {    width: 90%;
    height: 75px;
    float: none;
    /* margin-bottom: 20px; */
    text-align: center;
    margin: 0px auto;
    margin-bottom: 15px;}
    div.resp-menu div.image div.logopart {    float: none;
    margin: 0px auto;}
    div.resp-menu ul {    width: 90%;
    height: auto;
    margin: 0px auto;}
    div.resp-menu ul li {    padding: 15px;
    border: 1px solid #7ebbdf36;
    border-bottom: none;
    background-color: #ffffffc7;
    overflow: hidden;}
    div.resp-menu ul li:hover {
        cursor: pointer;
        background-color: var(--astrick-blue);
    }
    div.resp-menu ul li img.a {    width: 18px;
    height: 18px;
    float: left;
    margin-right: 10px;
    object-fit: contain;}
    div.resp-menu ul li img.z {    width: 10px;
    height: 10px;
    -o-object-fit: contain;
    object-fit: contain;
    float: right;
    opacity: 0.7;
    margin-top: 5px;}
    div.resp-menu ul li p {    font-size: 12pt;
    color: var(--astrick-dark-blue);
    font-weight: 500;
    float: left;}

    div.resp-menu ul li:last-child {border-bottom: 1px solid #7ebbdf36;}


</style>
