<template>
    <Hero />

    <PageFeatures :content="Features" :extra="false" />

    <Technology />

    <VideoBanner :content="Video" />

    <Testimonials />

</template>

<script>

    // javascript Library
    import Config from './../api/config';
    import Locale from './../api/Locale';

    //@ import components
    import Hero from './../components/homepage/Hero.vue';
    import Technology from '../components/homepage/Technology.vue';
    import Testimonials from './../components/homepage/Testimonials.vue';

    //@ resuable components
    import PageFeatures from './../components/reusable/PageFeatures.vue';
    import VideoBanner from './../components/reusable/VideoBanner.vue';

    export default {
        name: 'HomePage',

        components: {
            Hero,
            Technology,
            Testimonials,

            PageFeatures,
            VideoBanner,
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'AStrick Software - All in one Workspace'          
            });
            
            // page features data
            let Features = Locale.AStrickFeatures ();

            // homepage video banner
            let Video = Locale.VideoBannerInfo().homepage;
            
            // return reactive data
            return { Features, Video}
        },
    }
</script>