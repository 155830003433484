<script>
    // vue
    import { ref } from '@vue/reactivity';

    import Config from './../../api/config';

    //@ import 
    import ImageApp from './../app/ImageApp.vue';
    import Fullscreen from './../../components/modal/Fullscreen.vue';

    export default {
        name: 'Hero',
        components : {
            ImageApp,
            Fullscreen
        },

        setup () {
            // get started
            let StartProject = ref(false);

            // close the modal
            let CloseModal = () => {
                StartProject.value = false;
            };

            // hero image
            let HeroImage = Config.StockUrl + 'large/hero-image.jpg';

            return {
                HeroImage,
                StartProject, 
                CloseModal
            }
        }
    }
</script>

<template>
    <section class="homeland anas1s fadeInDown">
        <div class="wrapper">
            <div class="hero-content">
                <h1 class="anas2s fadeInUp">All-in-one Workspace Websites, Apps or Animation</h1>
                <p class="anas3s fadeInUp">Design all your web assets to uplift your business- optimized at one place, today!! <br>At AStrick you can turn your idea into reality.</p>
                <span class="getstart-btn anas4s fadeInUp" @click="StartProject = true">Get Started</span>
            </div>

            <div class="hero-image anas4s fadeInRight">
                <ImageApp :source="HeroImage" />
            </div>
        </div>
    </section>

    <!-- Modals -->
    <Fullscreen v-if="StartProject" :from="'get-started'" :content="'get-started'" @close="CloseModal" />

</template>
