<script>
    // js import
    import PortfolioInternal from './../../api/PortfolioInternal';
    import Config from './../../api/config';

    //@import component
    import BreadCrumb from './../../components/portfolio/BreadCrumb.vue';
    import MoreProject from './../../components/portfolio/MoreProjects.vue';

    export default {
        components: {
            BreadCrumb,
            MoreProject,
        },

        setup(props) {
            let Internal = PortfolioInternal.PortfolioInternals();
            Internal = Internal.encyclopedia;

            let StockUrl = Config.StockUrl;

            // page title
            Config.PageTitle ({
                title : Internal.title + ' - AStrick Software'          
            });

            return {
                Internal,
                StockUrl
            }
        }
    };
</script>


<template>
    <BreadCrumb :content="Internal" />

    <section class="features grey">
        <div class="secwrapper">
            <div class="multi-image">
                <img :src="StockUrl + 'portfolio/nritana-encyclopedia.png'" style="margin-right:2%;" />
                <img :src="StockUrl + 'portfolio/nritana-edutainment.png'" style="margin-left:2%;" />
            </div>
            <h3>Nritana, Encyclopedia of Nepal</h3>
            <p>Nritana is a software package published by AStrick Software for educational purpose. It comprises of two parts: Reference library of Nepal and Edutainment (a combination of Education and Entertainment). Reference Library of Nepal contain information about various natural and cultural resources of Nepal like rivers, lakes, Himalayan peaks, national parks, religious places, festivals and traditional culture of various ethnic groups of Nepal, grouped into various categories.</p>
            <p>It has also incorporated detail information about the geographical and administrative divisions of the country. In the same way, “Nepal &amp; Its History” section covers the historical information regarding various rulers and their regimes from ancient time till today. In short, it can be called as an encyclopedia of Nepal which will be useful to students, learners and anyone interested to know about Nepal in detail.</p> 
            <img :src="StockUrl + 'portfolio/encyclopedia-home-page.jpg'" />
            <p>A wealth of information accumulated in Reference Library can be very useful for everyone, especially tourists. The major source of this information is Nepal Tourism Board (NTB), to whom we are extremely grateful. As a result, Reference Library contains a lot of information about the touristic places like Pokhara, Bandipur, Bhotekoshi, National Parks, etc. where tourists can enjoy sight-seeing, trekking, bungee jumping, paragliding, rafting, kayaking, canoeing, etc. All tourism related contents are grouped separately in "Tourism in Nepal and Touristic Places" section. Thus, it will be quite useful for tourists.</p>                
        </div>
    </section>

    <section class="features">
        <div class="secwrapper">    
            <h3>Nritana, Edutainment for Student</h3>
            <p>Edutainment for student is a software which is included in the Software Package Nritana 2011 (Encyclopedia of Nepal) published by AStrick Software. The most remarkable feature of Nritana is Edutainment for Student which contents are in the form of interactive multimedia, i.e. the use of different mediums like text, graphics, audio, video, etc. together.</p>
            <img :src="StockUrl + 'portfolio/edutainment-for-student-large.jpg'" />
            <p>It has more than 85 educational games along with quizzes, puzzles, IQs and Quick Facts. Similarly, Edutainment includes more than five subject Computer Science, Science and Technology, Maths and Logic, GK of Nepal, Kid Zone for pre-primary students and Quick Facts of Nepal. The games in different subject are of different type which gives entertainment and education both. All the games are not of same type. After playing a game it will shows the mistake, time intervals and even the brain percentage also.</p>
            <p>Edutainment for student is very useful for school students to learn about their educational courses and outside the courses too. It helps to make the mind sharp and gives knowledge about various field.</p>
        </div>
    </section>

    <section class="features grey">
        <div class="secwrapper">   
            <h3>History and Development</h3>
            <p>Back then, when we founded AStrick Software with a team members of 8, we actually want to develop a desktop software to make the company more vibrant in the market and that’s when Nritana, a multimedia software, was all started. In early days we had started with a single application but later on we found that application can go beyond our thoughts and customer's need. We believe adding tourism information in the application will be more fruitful and we did the agreement with the Nepal Tourism Board instantly. And so was the result, as an Nritana now days.</p>
            <h3>85+ Games Design and Development</h3>
            <p>After a huge success of Nritana in youth people, tourist of course, we thought something should be added in the same application to include the people aged 5 to 16. After a meeting, we found people aged 5 to 16 are more appealing with the visual effects and games. 'Edutainment of Student' was the result of the meeting. We developed education games using flash, which we thought, will help in overall learning capacity of the students. This software was more appealing than others as it includes sounds, graphics and animations. This software was a huge success and popular amongst the students and was loved by teachers and parents.</p>
            <div class="video"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/5y5ZaJjkQxU" frameborder="0" allowfullscreen=""></iframe></div>
            <h3>Product Intro Video</h3>
            <p>We develop the grand software and later on, in the process of marketing the product we also started digital marketing. For the digital marketing we have designed animation video using the After-effects for the introduction of software in details. It was easier for us to describe the software details and easier to make understand about the software in the general public.</p>  
        </div>
    </section>

    <MoreProject :current="'Encyclopedia'" :type="'product'" />

</template>