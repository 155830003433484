<template>
    <div class="logopart">
        <router-link :to="{name : 'Home'}">
            <div class="logo-area">
                <!-- <img src="../../images/brand/astrick-logo.svg" alt="AStrick" title="AStrick"> -->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 909.71 227.72" style="width: 140px;height: auto;object-fit: contain;margin-top: 2px;">
                <g id="AStrick" transform="translate(-174.79 -261)">
                    <g id="astrick-logo" transform="translate(-170.683 -892.98)">
                    <g id="astrick-blue" transform="translate(565.786 1205.069) rotate(72)" fill="#64aed9" stroke="#64aed9" stroke-width="2">
                        <path d="M20,0H41.151a20,20,0,0,1,20,20V73.924a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V20A20,20,0,0,1,20,0Z" stroke="none" class="astrick-svg-logo-1"></path>
                        <path d="M20,1H41.151a19,19,0,0,1,19,19V71.924a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V20A19,19,0,0,1,20,1Z" fill="none" class="astrick-svg-logo-2"></path>
                    </g>
                    <g id="astrick-purple" transform="matrix(1, 0, 0, 1, 434.524, 1153.98)" fill="#6362d6" stroke="#6362d6" stroke-width="2">
                        <path d="M20,0H41.151a20,20,0,0,1,20,20V73.924a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V20A20,20,0,0,1,20,0Z" stroke="none" class="astrick-svg-logo-3"></path>
                        <path d="M20,1H41.151a19,19,0,0,1,19,19V71.924a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V20A19,19,0,0,1,20,1Z" fill="none" class="astrick-svg-logo-4"></path>
                    </g>
                    <g id="astrick-yellow" transform="translate(556.992 1346.626) rotate(145)" fill="#f90" stroke="#f90" stroke-width="2">
                        <path d="M20,0H41.151a20,20,0,0,1,20,20V73.924a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V20A20,20,0,0,1,20,0Z" stroke="none" class="astrick-svg-logo-5"></path>
                        <path d="M20,1H41.151a19,19,0,0,1,19,19V71.924a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V20A19,19,0,0,1,20,1Z" fill="none" class="astrick-svg-logo-6"></path>
                    </g>
                    <g id="Rectangle_466" data-name="Rectangle 466" transform="matrix(-0.809, -0.588, 0.588, -0.809, 421.351, 1381.188)" fill="red" stroke="red" stroke-width="2">
                        <path d="M20,0H41.151a20,20,0,0,1,20,20V73.924a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V20A20,20,0,0,1,20,0Z" stroke="none" class="astrick-svg-logo-7"></path>
                        <path d="M20,1H41.151a19,19,0,0,1,19,19V71.924a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V20A19,19,0,0,1,20,1Z" fill="none" class="astrick-svg-logo-8"></path>
                    </g>
                    <g id="astrick-green" transform="translate(345.473 1262.866) rotate(-72)" fill="#00ca8e" stroke="#00ca8e" stroke-width="2">
                        <path d="M20,0H41.151a20,20,0,0,1,20,20V73.924a0,0,0,0,1,0,0H0a0,0,0,0,1,0,0V20A20,20,0,0,1,20,0Z" stroke="none" class="astrick-svg-logo-9"></path>
                        <path d="M20,1H41.151a19,19,0,0,1,19,19V71.924a1,1,0,0,1-1,1H2a1,1,0,0,1-1-1V20A19,19,0,0,1,20,1Z" fill="none" class="astrick-svg-logo-10"></path>
                    </g>
                    </g>
                    <g id="astrick-text">
                    <g id="A">
                        <path id="a-back" d="M-1032,438.606l57.528-125.584s8.955-13.954,17.078,0,57.065,125.584,57.065,125.584h-18.536l-14.162-31.448h-67.061L-1013,438.606Z" transform="translate(1463)" fill="#64aed9" stroke="#64aed9" stroke-width="2" class="astrick-svg-logo-11"></path>
                        <path id="a-front" d="M-966.165,331.766l-27.26,61.646h53.941Z" transform="translate(1463)" fill="#fff" stroke="#64aed9" stroke-width="2" class="astrick-svg-logo-12"></path>
                    </g>
                    <g id="S">
                        <path id="s-single" d="M-846.389,326.56c-10.817-.552-18.453,4.1-22.493,9.58-3.258,4.414-3.528,9.691-3.332,9.788-1.458,18.536,36.655,23.117,36.238,23.534,13.814,3.181,22.447,7.181,28.532,12.079,8.506,6.844,10.622,14.37,10.622,14.37s6.873,37.071-33.531,43.527-62.271-15-62.271-15l6.456-13.537s23.534,19.16,52.691,13.537C-813.038,420.5-812,408.694-814,401.311a16.648,16.648,0,0,0-3.044-5.4c-9.164-7.706-28.1-12.288-36.222-13.537s-20.618-7.914-20.827-8.122c-21.29-13.395-16.3-33.5-10.622-44.569a21.113,21.113,0,0,1,2.91-4.149,23.416,23.416,0,0,1,5.212-4.6c15.555-9.542,31.379-10.408,43.944-9.164,19.285,1.908,31.448,10.83,31.448,10.83l-6.248,14.37a71.331,71.331,0,0,0-16.869-7.289C-830.432,327.874-838.409,326.967-846.389,326.56Z" transform="translate(1463)" fill="#64aed9" stroke="#64aed9" stroke-width="2" class="astrick-svg-logo-13"></path>
                    </g>
                    <g id="T">
                        <path id="t-back" d="M-769.5,322.833h17V412s3,22.667,28.667,9.167c.333,0,5.5,11.833,5.5,11.833s-19.951,14.907-39.667,2.5a38.745,38.745,0,0,1-5.833-5.167A56.624,56.624,0,0,1-767,424c-1.543-3.834-2.7-9.628-2.5-20.5C-769.167,385.5-769.5,322.833-769.5,322.833Z" transform="translate(1463)" fill="#64aed9" stroke="#64aed9" stroke-width="2" class="astrick-svg-logo-14"></path>
                        <g id="t-front" transform="translate(677 343)" fill="#64aed9" stroke="#64aed9" stroke-width="2">
                        <rect width="61" height="15" stroke="none" class="astrick-svg-logo-15"></rect>
                        <rect x="1" y="1" width="59" height="13" fill="none" class="astrick-svg-logo-16"></rect>
                        </g>
                    </g>
                    <g id="R">
                        <g id="r-front" transform="translate(765 343)" fill="#64aed9" stroke="#64aed9" stroke-width="2">
                        <rect width="18" height="96" stroke="none" class="astrick-svg-logo-17"></rect>
                        <rect x="1" y="1" width="16" height="94" fill="none" class="astrick-svg-logo-18"></rect>
                        </g>
                        <path id="r-back" d="M-646.5,343s-18.625-4-33.5,15.125c.125,0,0,29.625,0,29.625s-2.125-28.625,33.5-28.5Z" transform="translate(1462)" fill="#64aed9" stroke="#64aed9" stroke-width="2" class="astrick-svg-logo-19"></path>
                    </g>
                    <g id="I">
                        <g id="i-down" transform="translate(838 344)" fill="#64aed9" stroke="#64aed9" stroke-width="2">
                        <rect width="17" height="95" stroke="none" class="astrick-svg-logo-20"></rect>
                        <rect x="1" y="1" width="15" height="93" fill="none" class="astrick-svg-logo-21"></rect>
                        </g>
                        <g id="i-top" transform="translate(834 302)" fill="#64aed9" stroke="#64aed9" stroke-width="2">
                        <circle cx="12.5" cy="12.5" r="12.5" stroke="none" class="astrick-svg-logo-22"></circle>
                        <circle cx="12.5" cy="12.5" r="11.5" fill="none" class="astrick-svg-logo-23"></circle>
                        </g>
                    </g>
                    <g id="C">
                        <path id="c-single" d="M-507.619,371.45l13.24-8.432s-15.607-26.331-50-19.3-37.8,34.985-37.8,34.985a63.063,63.063,0,0,0,.75,26.089c1.387,5.534,4.109,13,8.45,17.476,14.423,14.867,26.229,16.272,26.229,16.272s18.713,4.586,32.249-1.849A51.278,51.278,0,0,0-494.3,419.453l-13.535-8.136s-14.349,21.746-40.015,10.947-15.976-44.749-15.976-44.749,8.8-21.006,30.7-19.749S-507.619,371.45-507.619,371.45Z" transform="translate(1463)" fill="#64aed9" stroke="#64aed9" stroke-width="2" class="astrick-svg-logo-24"></path>
                    </g>
                    <g id="K">
                        <g id="k-back" transform="translate(1025.305 392.792) rotate(-39)" fill="#64aed9">
                        <path d="M 15.9388427734375 69.76287078857422 L 0.1837198585271835 57.00463104248047 L 0.9857869148254395 0.9999998211860657 L 16.98468208312988 0.9999998211860657 L 15.9388427734375 69.76287078857422 Z" stroke="none" class="astrick-svg-logo-25"></path>
                        <path d="M 1.971567153930664 2 L 1.190579414367676 56.53321075439453 L 14.97022724151611 67.69174194335938 L 15.96935176849365 2 L 1.971567153930664 2 M 0 0 L 18 0 L 16.90745544433594 71.83399963378906 L -0.8231334686279297 57.47605514526367 L 0 0 Z" stroke="none" fill="#64aed9" class="astrick-svg-logo-26"></path>
                        </g>
                        <g id="k-middle" transform="translate(1058.721 343.108) rotate(47)" fill="#64aed9">
                        <path d="M 14.79298782348633 84.82720947265625 L 0.7187894582748413 72.32185363769531 L 0.998360276222229 0.4239523410797119 L 13.99091243743896 -12.21310710906982 L 14.79298782348633 84.82720947265625 Z" stroke="none" class="astrick-svg-logo-27"></path>
                        <path d="M 13.01029109954834 -9.864311218261719 L 1.996719360351562 0.847900390625 L 1.720540046691895 71.87421417236328 L 13.77441787719727 82.58445739746094 L 13.01029109954834 -9.864311218261719 M 14.97152900695801 -14.56187438964844 L 15.81155776977539 87.06995391845703 L -0.2829570770263672 72.76948547363281 L 0 0 L 14.97152900695801 -14.56187438964844 Z" stroke="none" fill="#64aed9" class="astrick-svg-logo-28"></path>
                        </g>
                        <g id="k-front" transform="translate(989 305)" fill="#64aed9" stroke="#64aed9" stroke-width="1">
                        <rect width="18" height="134" stroke="none" class="astrick-svg-logo-29"></rect>
                        <rect x="0.5" y="0.5" width="17" height="133" fill="none" class="astrick-svg-logo-30"></rect>
                        </g>
                    </g>
                    </g>
                </g>
                </svg>
            </div>
            <div class="company-name">
                <div class="cmp-nme">AStrick</div>
            </div>
        </router-link>
    </div>
</template>

<script>
    export default {
        name: 'Logo',
    }
</script>