<script>
    export default {
        name: 'Links',
    };
</script>

<template>
    <section class="_policies_links">
        <div class="wrapper">
            <ul class="_p_lks">
                <li>
                    <router-link :to="{name : 'Privacy'}">
                        <h3>Privacy and Policy</h3>
                    </router-link>
                </li>

                <li>
                    <router-link :to="{name : 'Terms'}">
                        <h3>Terms and Conditions</h3>
                    </router-link>
                </li>

                <li>
                    <router-link :to="{name : 'Refund'}">
                        <h3>Refund Policy</h3>
                    </router-link>
                </li>

                <li>
                    <router-link :to="{name : 'Cookies'}">
                        <h3>Cookies Policy</h3>
                    </router-link>
                </li>
            </ul>
        </div>
    </section>
</template>

<style scoped>
a.router-link-active:after {display: none;}
</style>