<template>
    <div class="fullscreen-modal anas2s fadeInUp">
        <div class="gs--header">
            <img src="./../../images/icons/close.svg" @click="CloseModal(false)" />
        </div>
        
        <!-- get started -->
        <StartProject v-if="from == 'get-started'" :content="content" @close="CloseModal" />

        <!-- portfolio modal -->
        <PortfolioModal v-if="from == 'portfolio-modal'" :content="content" @close="CloseModal" />

        <!-- responsive menu -->
        <ResponsiveMenu v-if="from == 'responsive-menu'" @close="CloseRespModal" />

    </div>
</template>

<script>

    // js import

    // component import
    import StartProject from './StartProject.vue';
    import PortfolioModal from './PortfolioModal.vue';
    import ResponsiveMenu from './ResponsiveMenu.vue';

    export default {
        name: 'Fullscreen',
        props: ['from', 'content'],
        emits: ['close'],
        components: {
            StartProject,
            PortfolioModal,
            ResponsiveMenu,
        },

        setup (props, {emit}) {

            // close the modal with data
            let CloseModal = (data) => {
                emit('close', data);
            };

            // close reponsive menu
            let CloseRespModal = () => {
                emit('close');
            };

            
            // finally return the reactive variables
            return {
                CloseModal,
                CloseRespModal
            }
        }

    }

</script>