<script>
    // js import
    import PortfolioInternal from './../../api/PortfolioInternal';
    import Config from './../../api/config';

    //@import component
    import BreadCrumb from './../../components/portfolio/BreadCrumb.vue';
    import MoreProject from './../../components/portfolio/MoreProjects.vue';

    export default {
        components: {
            BreadCrumb,
            MoreProject,
        },

        setup(props) {
            let Internal = PortfolioInternal.PortfolioInternals();
            Internal = Internal.aaspaas;

            let StockUrl = Config.StockUrl;

            // page title
            Config.PageTitle ({
                title : Internal.title + ' - AStrick Software'          
            });

            return {
                Internal,
                StockUrl
            }
        }
    };
</script>

<template>
    <BreadCrumb :content="Internal" />

    <section class="features grey">
        <div class="secwrapper">
            <img :src="StockUrl + 'portfolio/aaspass-screen-demo.jpg'">

            <h3>Common Problem with Renting</h3>

            <p>An average of 50% of people worldwide rent their property and products to others. Talking about Nepal, more than 40% of the population has lived in rented houses, or at least they have rented something in their lives.</p>

            <p>There are several reasons that make renting a complex and challenging process.</p>

            <p>For instance, let's say you want to rent a car for the weekend. For this purpose, first, you need to contact all the nearby car rental dealers, shortlist a car you want to rent, and search which dealer is offering the minimum rent. </p>

            <p>Then you also might have to bargain on rent and make sure you get the best deal out of all the options. This whole process could be very frustrating, and you can end up wasting a lot of time and effort. The problems are not only limited to renting cars. If you want to rent an apartment or home, the complications remain the same. Moreover, if you wish to rent your property, the most common problem you can face is advertising it efficiently.</p>

        </div>
    </section>

    <section class="features">
        <div class="secwrapper">

            <img :src="StockUrl + 'portfolio/aaspass-get-started.jpg'">

            <h3>Solve All Rental Problems in One Go</h3>

            <p>Well, you don't have to worry about the rental problems anymore because AasPass has got your back. AasPass is an all-inclusive Nepal-based mobile app that simplifies the process of renting properties.</p>

            <p>Let's have a look at some of the rental complications solved by AasPass: </p>

            <p>Find All You Need at One Place  Whether you need to rent an apartment or a vehicle for your use, you don't need to waste time searching for the right one. You can find all the nearby listings on AasPass, so you can easily pick the right choice for you.</p>

        </div>
    </section>

    <section class="features grey">
        <div class="secwrapper">

            <h3>Free To Use</h3>

            <p>AasPass Mobile App is free for everyone. There are no hidden charges; just fill in the required information, create an account and you are all set to post an ad or search for an available listing available for rent.</p>

            <h3>Attract More Potential Customers</h3>

            <p>AasPass solves the crucial problem of advertising your property to find the right customers for it. Unfortunately, most of the property owners can end up losing a lot of their hard-earned money just for advertisement. </p>

            <p>But not anymore because your customers can easily scroll and see all the available listings with rates and other details to decide what they want to rent.</p>

            <img :src="StockUrl + 'portfolio/aaspaas-screens.jpg'">

            <h3>Get Notified with Nearby Listings</h3>

            <p>If you are new in town and are looking for a place to live, then no need to stress out as AasPass gives notification alerts of all the new nearby listings. So, you can get your hands on it before someone else.</p>

            <h3>Upload Your Property Photos</h3>

            <p>AasPass allows you to upload photos of your property so the people who wish to rent it can better understand the condition and easily decide. It also helps you save time from showing your property to everyone before deciding whether they want to rent it or not.</p>

        </div>
    </section>

    <MoreProject :current="'AasPaas'" :type="'product'" />

</template>