<template>
    <section class="blog--page">
        <div class="bpage-content">
            <Skeleton :loop="1" :type="'line'" :dark="true" :css="'width: 70%; margin:0px auto;'" />
            <Skeleton :loop="1" :type="'line'" :dark="true" :css="'width: 50%; margin:0px auto;'" style="margin-bottom: 35px;" />

            <div class="summery" style="padding-bottom: 55px;">
                <div class="s-date">
                    <Skeleton :loop="1" :type="'small-line'" :dark="true" :css="'width: 200px;'" />                    
                </div>
                <div class="s-share">
                    <Skeleton :loop="1" :type="'small-line'" :dark="true" :css="'width: 100px;'" />                    
                </div>
            </div>

            <div class="bpc-image-container" style="background-color: #e4e4e4;"></div>

        </div>
    </section>
</template>

<script>

    import Skeleton from './../app/Skeleton.vue';

    export default {
        name: "BlogDetailSkeletion",
        components: {
            Skeleton,
        }
    };
</script>

<style scoped>

</style>