<template>
    <div class="as--technology">
        <div class="wrapper" style="overflow: visible;">
            <div class="tech-info">
                <h4>We are experts in numerous technologies, we will deliver your product with the best technology.</h4>
            </div>

            <ul>
                <li title="PHP"><img src="./../../images/technology/php.svg" /></li>
                <li title="Laravel"><img src="./../../images/technology/laravel.svg" /></li>
                <li title="Javascript"><img src="./../../images/technology/javascript.svg" /></li>
                <li title="Node Js"><img src="./../../images/technology/nodejs.svg" /></li>
                <li title="React Native"><img src="./../../images/technology/react-native.svg" /></li>
                <li title="Vue.js"><img src="./../../images/technology/vue.svg" /></li>
                <li title="MySQL"><img src="./../../images/technology/mysql.svg" /></li>
                <li title="MongoDB"><img src="./../../images/technology/mongo-db.svg" /></li>
                <li title="Adobe XD"><img src="./../../images/technology/adobe-xd.svg" /></li>
                <li title="Adobe After Effects"><img src="./../../images/technology/adobe-ae.svg" /></li>
            </ul>

            <ul class="sub-tech">
                <li title="Android App"><img src="./../../images/technology/android.svg" /></li>
                <li title="Apple iOS App"><img src="./../../images/technology/ios.svg" /></li>
                <li title="Expo Cli"><img src="./../../images/technology/expo-cli.svg" /></li>
                <li title="Electron.js"><img src="./../../images/technology/electron.svg" /></li>
                <li title="WordPress"><img src="./../../images/technology/wordpress.svg" /></li>
                <li title="Websockets"><img src="./../../images/technology/websocket.svg" /></li>
                <li title="Pinia"><img src="./../../images/technology/pinia.svg" /></li>
                <li title="Firebase Database"><img src="./../../images/technology/firebase.svg" /></li>
                <li title="Nuxt.js"><img src="./../../images/technology/vue-nuxt.svg" /></li>
            </ul>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Technology'
    }
</script>