<template>
    <IllustrationHero :content="BreadCrumbsData" />

    <!-- Section Page Content -->
    <section class="__about-us">
        <div class="wrapper">
            
            <ul class="_contact">

                <li>
                    <img src="../../images/icons/help-desk.png">

                    <h2>Help Desk</h2>
                    <h3>Phone & Email</h3>

                    <hr>

                    <a href="tel:+977-9802974990" class="link">+977-9802974990</a>
                    <a href="mailto:support@astricksoftware.com" class="link">support@astricksoftware.com</a>
                </li>

                <li>
                    <img src="../../images/icons/address.png">

                    <h2>Our address</h2>
                    <h3>Main office</h3>

                    <hr>

                    <a href="https://g.page/astrick-software?gm" class="link" target="_blank">Bharatpur 10, Chitwan. Nepal <br> Hakimchowk</a>
                </li>

                <li>
                    <img src="../../images/icons/social-media.png">

                    <h2>Social media</h2>
                    <h3>Our profiles</h3>

                    <div class="social--media">
                        <a href="https://www.facebook.com/astricksoft" target="_blank"><img src="../../images/icons/facebook.png"></a>
                        <a href="https://www.linkedin.com/company/astricksoftware/" target="_blank"><img src="../../images/icons/linkedin.png"></a>
                        <a href="https://twitter.com/astricksoftware" target="_blank"><img src="../../images/icons/twitter.png"></a>
                        <a href="https://www.youtube.com/channel/UCf0EToFZtBRav4Ns9xNGNxA" target="_blank"><img src="../../images/icons/youtube.png"></a>
                    </div>

                </li>

            </ul>

            <div class="about-footer">
                <div class="af_images">
                    <ul>
                        <li>
                            <ImageApp :source="StockUrl + 'about/woman-call.jpg'" />
                        </li>
                        <li>
                            <ImageApp :source="StockUrl + 'about/social-media.jpg'" />
                        </li>
                    </ul>
                </div>

                <div class="af_content">
                    <div>
                        <h2>If you have any question, feel free to call us</h2>
                        <h3>+977-9802974990</h3>
                        <p>Monday - Friday, 9:00 AM - 6:00 PM</p>
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!-- Section Page Content -->

    <CompanyCount />
</template>

<script>
    // import js
    import Config from './../../api/config';
    
    //@ import resuable components
    import IllustrationHero from '../../components/reusable/IllustrationHero.vue';
    import CompanyCount from '../../components/reusable/CompanyCount.vue';    
    import ImageApp from './../../components/app/ImageApp.vue';


    export default {
        name : 'AboutUs',

        components : {
            IllustrationHero,
            CompanyCount,
            ImageApp
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'Contact - AStrick Software'              
            });

            let StockUrl = Config.StockUrl;

            const BreadCrumbsData = {
                page : 'Contact Us',
                title : 'Contact AStrick Today!',
                description : 'We value our customers and believe them as our god. We stand by 24x7 to help them, fix their problem and challenges.',
                image : 'contact-us.svg'
            }

            return {
                BreadCrumbsData,
                StockUrl
            };
        }
    };
</script>

<style scoped>
.af_content div:before {
    background-image: url(../../images/icons/office-phone.png);
}
</style>