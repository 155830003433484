<template>
    <DetailSekeletion v-if="dataIsLoading" />

    <section class="blog--page" v-else>
        <div class="bpage-content">
            <h1>{{BlogDetail.title}}</h1>

            <div class="tags-container tags">
                <a v-for="tag in BlogTags" :key="tag" href="#">{{tag}}</a>
            </div>

            <div class="summery">
                <div class="s-date">{{BlogDetail.created}}</div>  
                                            
                <div class="s-share">
                    <img src="./../../images/icons/share.svg">
                    <p>Share</p>
                </div>
            </div>
            
            <div class="bpc-image-container">
                <ImageApp :source="BlogStockUrl + BlogDetail.image" />
            </div>

            <BlogContentBuilder v-for="content in BlogContent" :key="content" :content="content" :blogurl="BlogStockUrl" />

        </div>

    </section>    
</template>

<script>
    
    // vvue
    import { ref } from '@vue/reactivity';
    import { useRouter, useRoute } from 'vue-router';

    // js import
    import Config from './../../api/config';
    import Request from './../../api/getRequest';

    import DetailSekeletion from './../../components/blog/BlogDetailSkeletion.vue';

    import MissedList from './../../components/blog/MissedList.vue';
    import ImageApp from './../../components/app/ImageApp.vue';
    import BlogContentBuilder from './../../components/blog/BlogContentBuilder.vue';

    export default {
        name: 'BlogDetail',
        components: {
            MissedList,
            ImageApp,
            BlogContentBuilder,

            DetailSekeletion,
        },

        setup () {

            let dataIsLoading = ref(true);
            const Router = useRouter();
            const Route = useRoute();

            let BlogDetail = {}, BlogContent = {}, PopluarBlog = [];
            let BlogStockUrl, BlogTags = [];

            // page title
            Config.PageTitle ({
                title : 'Blog - AStrick Software'              
            });

            // state variables
            return {
                dataIsLoading,
                Route,
                Router,

                BlogDetail, BlogContent, PopluarBlog, BlogStockUrl, BlogTags,
            }
        },

        async mounted() {
            const BlogID = this.Route.params.blogid;
            let Result = await Request.getData('blog', {key: 'id', value: BlogID});
            if (!Result.error) {
                var Current, Popluar;

                Current = Result.response.current;
                Popluar = Result.response.popular;

                this.dataIsLoading = false;

                this.BlogDetail = Current;
                this.BlogContent = Current.content;
                this.PopluarBlog = Popluar;

                // image url
                this.BlogStockUrl = Config.StockUrl + 'blog/' + this.BlogDetail.year + '/';

                // tags
                this.BlogTags = this.BlogDetail.tags.split(',');

                // page title and seo
                Config.PageTitle ({
                    title : Current.title + ' - AStrick Software'           
                });

            // if any error respond route to 404 error or home page
            }else {
                this.Router.push({name: 'Home'});
            }
        },

    };

</script>