<script>
    
    // vue
    import { reactive, ref } from '@vue/reactivity';

    // javascript Library
    import Locale from './../api/Locale';
    import Config from './../api/config';

    //@ import resuable components
    import IllustrationHero from './../components/reusable/IllustrationHero.vue';
    import CompanyCount from './../components/reusable/CompanyCount.vue';

    // component
    import Fullscreen from './../components/modal/Fullscreen.vue';

    export default {
        name : 'Portfolio',

        components : {
            IllustrationHero,
            CompanyCount,

            Fullscreen
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'Portfolio - AStrick Software'          
            });

            // portfolio modal reactive
            let PortfolioModal = ref(false)
            let PortfolioModalData = reactive({
                data : ''
            });

            // collect portfolio content
            let Portfolio = Locale.AStrickPortfolio ();

            // AStrick products
            let Products = Portfolio.products;

            // AStrick projects
            let Projects = Portfolio.projects;

            // more projects and products
            let More = Portfolio.more;

            // page bread crumbs data
            const BreadCrumbsData = {
                page : 'Portfolio',
                title : 'Portfolio of AStrick',
                description : 'We design and develop websites, web applications, mobile applications, desktop software, and many more.',
                image : 'portfolio.svg'
            };

            // close the portfolio modal
            let ClosePortfolioModal = () => {
                PortfolioModal.value = false;
            };

            // portfolio modal opener
            let ModalPortfolioHandler = (data) => {
                PortfolioModalData.data = data; 
                PortfolioModal.value = true;
            };

            return {
                Products,
                Projects,
                More,
                BreadCrumbsData,

                PortfolioModal,
                PortfolioModalData,
                ClosePortfolioModal,
                ModalPortfolioHandler
            }
        }
    };
</script>

<template>
    <IllustrationHero :content="BreadCrumbsData" />
    
    <!-- AStrick Products -->
    <section class="asportfolio">
        <div class="wrapper">
            
            <div class="prot-title"><h3>AStrick Products</h3></div>

            <div class="product-box">
                <ul class="products">
                    <li v-for="product in Products" :key="product">
                        <router-link :to="{name : product.route}" class="box">
                            <div class="left">
                                <img :src="require(`../images/portfolio/${product.image}`)" :alt="product.title">
                            </div>
                            <div class="right">
                                <h2>{{product.title}}</h2>
                                <p class="career__p">{{product.description}}</p>
                            </div>
                        </router-link>
                    </li>               
                </ul>
            </div>

            <div class="prot-title"><h3>AStrick Web Projects</h3></div>

            <div class="product-box">
                <ul class="products">
                    <li v-for="project in Projects" :key="project">
                        <router-link :to="{name : project.route}" class="box">
                            <div class="left">
                                <img :src="require(`../images/portfolio/${project.image}`)" :alt="project.title">
                            </div>
                            <div class="right">
                                <h2>{{project.title}}</h2>
                                <p class="career__p">{{project.description}}</p>
                            </div>
                        </router-link>
                    </li>                                    
                </ul>
            </div>

            <div class="prot-title"><h3>Few More Projects...</h3></div>

            <div class="more--product">
                <ul>
                    <li v-for="more in More" :key="more" @click="ModalPortfolioHandler(more)">
                        <img v-if="more.type == 'video'" src="../images/portfolio/youtube-play.svg">
                        <img v-if="more.type == 'xd'" src="../images/portfolio/xd.png">
                        <img v-if="more.type == 'psd'" src="../images/portfolio/psd.png">
                        <img v-if="more.type == 'email'" src="../images/portfolio/mailchimp.png">
                        <img v-if="more.type == 'android'" src="../images/portfolio/android.png">
                        <p>{{more.title}}</p>
                    </li>                    
                </ul>
            </div>

        </div>
    </section>
    <!-- AStrick Products -->

    <!-- Modals -->
    <Fullscreen v-if="PortfolioModal == true" :from="'portfolio-modal'" :content="PortfolioModalData" @close="ClosePortfolioModal" />    
    
</template>