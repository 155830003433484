<script>
    // vue
    import { ref } from '@vue/reactivity';

    // component
    import Fullscreen from './../../components/modal/Fullscreen.vue';

    export default {
        name: 'DiscoverArea',
        props: ['content', 'link'],
        components: {
            Fullscreen
        },

        setup (props) {

            // get started
            let StartProject = ref(false);

            // close the modal
            let CloseModal = () => {
                StartProject.value = false;
            };

            // modal data
            let ModalData = props.link;

            return {
                StartProject,
                CloseModal,
                ModalData
            }
        }

    };
</script>

<template>
    <section class="__astrick_services_areas">
        <div class="wrapper">
        <ul class="__asa_rs">
            
            <li>
                <h2>Discover our areas</h2>
                <p>We have experience of working with many sectors.</p>
            </li>

            <li v-for="discover in content" :key="discover"  @click="StartProject = true">
                <img :src="require(`../../${discover.icon}`)" />
                <h3>{{discover.title}}</h3>
                <p>{{discover.description}}</p>
            </li>

        </ul>
        </div>
    </section>

    <!-- Modals -->
    <Fullscreen v-if="StartProject" :from="'get-started'" :content="ModalData" @close="CloseModal" />
</template>