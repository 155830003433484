<script>
    // import js
    import Config from './../../api/config';

    // import component
    import Crumb from './../../components/policy/Crumb.vue';
    import Links from './../../components/policy/Links.vue';

    export default {
        name: 'Terms',
        components: {
            Crumb,
            Links
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'Cookie Policy - AStrick Software'              
            });
            
        }
    };
</script>

<template>

    <Crumb :page="'Cookies Policy'" />

    <section class="_policies_page">
        <div class="content-wrapper">

            <h3>Privacy; Cookies</h3>

            <p>Your use of the Site or Services is subject to the Company's Privacy Policy. Please review the Company's Privacy Policy, which also governs the Site and Services and informs users of the Company's data collection practices.</p>

            <p>From time to time, the Company may use the standard “cookies” feature of major browser applications that allows the Company to store a small piece of data on your computer about your visit to the Platform and your use of the Services. The Company only stores personal information in cookies to identify you as a user and to enhance and customize the Services provided to you on the Platform. Cookies also allow the Company to learn which areas of the Platform are useful and which areas need improvement, and cookies provide the Company with marketing data for analysis. You can choose whether to accept cookies by changing the settings on your browser. However, if you choose to disable this functionality, your experience on the Platform may be diminished and some features may not work as intended.</p>

            <br>

            <p>The Site uses web analytics tools provided by Google Analytics, Crisp Analytics, MailChimp and Bluehost. These tools collect anonymized data related to how visitors to the Site view web pages and interact with the Site. The Company does not use the information provided by such tools to identify or collect data about specific individual users.</p>

            <br>

            <p>Do Not Track (DNT) is an optional browser setting that allows you to limit your sharing preferences concerning third parties. We do not respond to DNT signals.</p>

        </div>
    </section>

    <Links />

</template>