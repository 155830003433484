<template>
    <div class="menu-hover">
        <div class="items">
            <div class="menulinks">
                <ul class="services">
                    <li>
                        <router-link :to="{name : 'WebDesign'}">
                            <div class="image">
                                <img src="../../images/svg/web.svg" />
                            </div>

                            <div class="content">
                                <h3>Development</h3>
                                <p>Web or Desktop</p>
                            </div>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name : 'MobileApp'}">
                            <div class="image">
                                <img src="../../images/svg/mobile.svg" />
                            </div>
                            <div class="content">
                                <h3>Mobile App</h3>
                                <p>Android and iOS Apps</p>
                            </div>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name : 'Ecommerce'}">
                            <div class="image">
                                <img src="../../images/svg/e-commerce.svg" />
                            </div>
                            <div class="content">
                                <h3>E-Commerce</h3>
                                <p>Custom or WordPress</p>
                            </div>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name : 'ExplainerVideo'}">
                            <div class="image">
                                <img src="../../images/svg/video.svg" />
                            </div>
                            <div class="content">
                                <h3>Explainer Video</h3>
                                <p>Character based 2D animation</p>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="ads">
                <h3>Related Blogs</h3>

                <ul class="blogitems">
                    <li v-for="b in blog" :key="b">
                        <router-link :to="{path: '/blog/'+b.id+'/'}">
                            <h3>{{b.title}}</h3>
                            <p v-if="b.type == 'video'">Explainer Video</p>
                            <p v-else-if="b.type == 'web'">Web Development</p>
                            <p v-else-if="b.type == 'e-commerce'">E-commerce Websites</p>
                            <p v-else-if="b.type == 'app'">Mobile Application</p>
                            <p v-else>Search Engine Optimization</p>
                        </router-link>
                    </li>
                </ul>
            </div>

        </div>

        <div class="request" @click="RequestQuote">
            <p>Request a Quote</p>
            <img src="./../../images/icons/arrow-right.svg" />
        </div>
    </div>

</template>

<script>
    import { ref } from '@vue/reactivity';

    export default {
        name: 'ServicesHover',
        props: ['blog'],
        emits: ['quote'],

        setup (props, {emit}) {

            // close the modal
            let RequestQuote = () => {
                emit('quote');
            };

            return {RequestQuote}
        }
    };
</script>