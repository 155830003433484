<template>
    <div class="why_us">
        <div class="wrapper">
            <h2 style="margin-top:45px;">Why Choose AStrick?</h2>
            <p>We design, implement, and supports the full lifecycle of services surrounding today's technology and provides the best possible solutions to our customers.</p>
            <ul class="whyastrick">
                <li v-for="feature in Features" :key="feature.key">
                    <div class="asimgcon">
                        <ImageApp :source="feature.image" />
                    </div>
                    <h3>{{ feature.title }}</h3>
                    <p>{{ feature.description }}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
    
    // javascript Library
    import Locale from './../../api/Locale';

    //@ import component
    import ImageApp from './../app/ImageApp.vue';

    export default {
        name: 'WhyAStrick',
        props: ['features'],

        components : {
            ImageApp,
        },
        
        setup () {
            let Features = Locale.AStrickFeatures ();

            return { Features }
        }
    }
</script>

<style scoped>

.why_us {width: 100%;background-color: #00ca8e;background-position: top center;background-repeat: no-repeat;position: relative;}
.why_us:before {top: -91px;
    border-color: transparent transparent #00ca8e transparent;content: "";
    position: absolute;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 92px 100vw;}
.why_us:after {    bottom: -1px;
    border-color: transparent transparent #fff transparent;content: "";
    position: absolute;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 92px 100vw;}

.why_us h2 {    font-weight: 400;
    margin: 0px;
    color: #ffffff;
    font-size: 26pt;
    line-height: 55px;
    text-align: center;
    letter-spacing: 0.65px;
    font-family: 'Bold-font';}
.why_us p {    font-weight: 400;
    display: block;
    color: #ffffff;
    font-size: 13pt;
    letter-spacing: 0.25px;
    text-align: center;
    line-height: 24px;
    width: 60%;
    margin: 0px auto;}

ul.whyastrick {margin-top: 5%;overflow: hidden;}
ul.whyastrick > li {width: 27%;float: left;margin: 0 3%;height: auto;margin-bottom: 75px;text-align: center;}
ul.whyastrick > li img {     margin-bottom: 10px;
    width: 145px;
    height: 145px;
    -o-object-fit: contain;
    object-fit: contain;}
ul.whyastrick > li > h3 {font-weight: 600;
    margin: 0px;
    margin-bottom: 6px;
    font-size: 14pt;
    letter-spacing: 0.35px;
    color: #fff;
    line-height: 30px;}
ul.whyastrick > li > p {    font-weight: 400;
    margin-bottom: 25px;
    font-size: 12pt;
    color: #fff;
    line-height: 22px;
    letter-spacing: 0.25px;
    width: 90%;
    margin: 0px auto;
    text-align: center;}

.asimgcon {    width: 200px;
    height: auto;
    margin: 0px auto;
    margin-bottom: 20px;
    overflow: hidden;
    text-align: center;}
</style>