<script>
    // import js
    import Config from './../../api/config';
    
    // import component
    import Crumb from './../../components/policy/Crumb.vue';
    import Links from './../../components/policy/Links.vue';

    export default {
        name: 'Terms',
        components: {
            Crumb,
            Links
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'Refund Policy - AStrick Software'              
            });
            
        }
    };
</script>

<template>
    <Crumb :page="'Refund Policy'" />

    <section class="_policies_page">
        <div class="content-wrapper">

            <h3>Refund Policy</h3>
                
            <p>A full refund will be considered based on the following reasons:</p>

            <br>
            
            <ul>
                <li>The client's order cannot be completed;</li>
                <li>The client's request to cancel the order is received before the development is started;</li>
            </ul>
            
            <p>A partial refund will be considered based on the following reasons:</p>

            <br>
            
            <ul>
                <li>The client is not reasonably and legitimately satisfied with the quality of the code or service and provides a comprehensive proof / explanation of the matter;</li>
                <li>The client is not satisfied with the resulting product upon a review on our test server. A refund may be issued upon the confirmation of both parties and the clearance of all details related to the refund. If the order is completed according to the specifications of the client, some minor errors should be deemed acceptable and cannot be a reason for a full or partial refund.</li> 
            </ul>
        </div>
    </section>
    
    <Links />
</template>