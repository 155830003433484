<script>
    import { useRouter } from 'vue-router';

    //@ import component
    import ImageApp from './../app/ImageApp.vue';

    export default {
        name: 'ServicesPortfolio',
        props: ['content'],
        emits: ['modal'],
        components : {
            ImageApp,
        },

        setup (props, {emit}) {
            const Router = useRouter();

            let Content = props.content;
            let title = Content.title;
            let description = Content.description;
            let portfolios = Content.data;

            let PortfolioClicked = (portfolio) => {
                if (portfolio.linkType == 'link') {
                    Router.push({ name : portfolio.link });
                }else if (portfolio.linkType == 'video-modal') {
                    emit ('modal', {
                        type : 'video',
                        video : portfolio.video,
                        title : title
                    });
                }else if (portfolio.linkType == 'image') {
                    emit ('modal', {
                        type : 'xd',
                        title : portfolio.title,
                        image : portfolio.link,
                    });
                }
            };

            return {title, description, portfolios, PortfolioClicked};
        }
    }    
</script>

<template>
    <section class="_services_portfolio">
        <div class="wrapper">
            
            <h2>{{title}}</h2>
            <p>{{description}}</p>
            
            <ul class="__spfolio owl-carousel owl-theme">
                <li class="portfolioitem" v-for="portfolio in portfolios" :key="portfolio" @click="PortfolioClicked(portfolio)">
                    <div class="img web_astasks">
                        <ImageApp :source="portfolio.image" />
                        <span v-if="portfolio.linkType == 'link'"><img src="../../images/services/play-icon.png"></span>
                        <span v-else-if="portfolio.linkType == 'video-modal'"><img src="../../images/services/video-play-icon.png"></span>
                    </div>
                    <h3>{{portfolio.title}}</h3>
                    <p>{{portfolio.proptype}}</p>
                </li>
            </ul>            
        </div>
    </section>
</template>