<script>
    // js import
    import PortfolioInternal from './../../api/PortfolioInternal';
    import Config from './../../api/config';

    //@import component
    import BreadCrumb from './../../components/portfolio/BreadCrumb.vue';
    import MoreProject from './../../components/portfolio/MoreProjects.vue';

    export default {
        components: {
            BreadCrumb,
            MoreProject,
        },

        setup(props) {
            let Internal = PortfolioInternal.PortfolioInternals();
            Internal = Internal.cloudfiles;

            let StockUrl = Config.StockUrl;

            // page title
            Config.PageTitle ({
                title : Internal.title + ' - AStrick Software'          
            });

            return {
                Internal,
                StockUrl
            }
        }
    };
</script>

<template>
    <BreadCrumb :content="Internal" />

    <section class="features grey">
        <div class="secwrapper">                    
        
            <h3>Project Planning</h3>

            <p>After the conversation with client, we found that the client requires a neat and clean website built in WordPress with custom theme. They need workflow of the project in the footstep following up of the XD design. After designing pages with XD, we moved to HTML/CSS version according to their desire. </p>
            
            <img :src="StockUrl + 'portfolio/cloud-files-home-page.jpg'" />

            <p>Finally, we complete working with all the pages and design in XD and HTML/CSS. They were happy with custom HTML/CSS till then, but further they want to move the site to custom WordPress theme so that they can easily update their website in the future.</p>

        </div>
    </section>

    <section class="features grey">
        <div class="secwrapper">                    
        
            <h3>UI/UX Design</h3>

            <p>We always start designing webpage or app screen from the very beginning using Adobe XD. We have worked with more than 500+ pages using XD to make it responsive and vibrant for different devices like Mobile phones, iPad, Surface etc. we take time to design on XD to make neat and clean UI/UX design. We did the same with this website. We created about 8+ pages including homepage and blog and after that moved towards WordPress.</p>

            <h3>Adobe XD to HTML/CSS to WordPress</h3>

            <p>There is nothing new and challenging tasks in coding HTML/CSS. Further we feel documentation of every project is more important as everybody can understand the program. We did same with this project. We need readable codes and clean design, which we did it in the documentation of the project.</p>

            <h3>Project Development</h3>

            <p>After writing into HTML/CSS we move forward creating a custom theme on WordPress. We created all the project and blog using WordPress and all the sections are now easily editable using CMS.</p>

            <div class="video"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/6d0-a5jS0SQ" frameborder="0" allowfullscreen=""></iframe></div>

            <h3>Company Intro Video</h3>

            <p>While working with the project for few weeks we handover their project with all the source files. After then, they wanted to create an animated explainer video describing their business and features. So, we converted the company features and promotions in Visual Format (Shown Above)</p>

        </div>
    </section>

    <MoreProject :current="'CloudFiles'" :type="'project'" />

</template>