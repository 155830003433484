import Config from './config';

const Production = false;

var Server = "https://astricksoftware.com";
var Local = "http://localhost/api.astricksoftware.com";

/* AStrick features for homepage */
const AStrickFeatures = () => {
    return {
        title : "Why Choose AStrick?",
        description : "We design, implement, and supports the full lifecycle of services surrounding today's technology and provides the best possible solutions to our customers.",
        data : [{
                    key : 1,
                    image : Config.StockUrl + 'features/goal-oriented.svg',
                    title : 'Goal-Oriented',
                    description : 'Motivated to get your project done in the most efficient and effective way. We will make sure to achieve your aims in the best way possible.'
                },
                {
                    key : 2,
                    image : Config.StockUrl + 'features/years-of-experience.svg',
                    title : 'Years of Experience',
                    description : 'Motivated to get your project done most efficiently and effectively. We will make sure to achieve your aims in the best way possible.'
                },
                {
                    key : 3,
                    image : Config.StockUrl + 'features/quality-process.svg',
                    title : 'Quality Driven Process',
                    description : 'We leave no stone unturned in providing you the very best anyone can offer. Prime quality content is the very backbone of our service.'
                },
                {
                    key : 4,
                    image : Config.StockUrl + 'features/team-work.svg',
                    title : 'Highly Skilled Team',
                    description : 'This high level of skill set means your project will be adequately understood and completed by implementing the best strategies in the market.'
                },
                {
                    key : 5,
                    image : Config.StockUrl + 'features/deadline.svg',
                    title : 'Meeting Deadlines',
                    description : 'Once we agree on a deadline, we stick to it. We don’t ask for unnecessary extensions.'
                },
                {
                    key : 6,
                    image : Config.StockUrl + 'features/support.svg',
                    title : 'Dedicated Support',
                    description : 'Have any problems? We are present to answer all you query 24/7, just contact our support'
                }]
    };
};

/* AStrick web page design/development features */
const WebDevelopmentFeatures = () => {
  return {
    title : "Design and development features!",
    description : "We create websites with a unique look and feel, which not only instills trust into the visitor but also leaves them with a positive image of your brand.",
    data : [{
                key : 1,
                image : Config.StockUrl + 'features/goal-oriented.svg',
                title : 'Responsive & Retina Ready',
                description : 'Our design is fully responsive and ready for retina displays. You will love how the website looks on devices.'
            },
            {
                key : 2,
                image : Config.StockUrl + 'features/years-of-experience.svg',
                title : 'Cross-Browser Support',
                description : 'We investigate all tiny parts to work perfectly with all browsers throughout the world.'
            },
            {
                key : 3,
                image : Config.StockUrl + 'features/quality-process.svg',
                title : 'Search Engine Friendly',
                description : 'We implement the latest SEO on-page and off-page tactics to get your business to the top of relevant organic search results.'
            },
            {
                key : 4,
                image : Config.StockUrl + 'features/team-work.svg',
                title : 'Unlimited Color Schemes',
                description : 'We work with the best color suit on your website, but also once the theme is ready you can easily customize your color.'
            },
            {
                key : 5,
                image : Config.StockUrl + 'features/deadline.svg',
                title : 'Optimized for Morden Devices',
                description : 'We implement an eye-catching responsive design that adapts with today’s mobile devices.'
            },
            {
                key : 6,
                image : Config.StockUrl + 'features/support.svg',
                title : 'Easy to Customise',
                description : 'Your website can be fully customizable, we write clean codes which you can do it yourself or just one request will be enough.'
            }]
      };
};

/* AStrick portoflio page data */
const AStrickPortfolio = () => {
    return {
        products : [
            {
                key : 0,
                image : 'aas-pass-logo.png',
                title : 'AasPass Mobile App',
                description : 'AasPass solves the crucial problem of advertising your property to find the right customers for it.',
                route : 'AasPass',
            },
            {
                key : 1,
                image : 'astasks.png',
                title : 'Tasks Management Web App',
                description : 'AStasks is a web application purely developed to manage the work loads.',
                route : 'AStasks',
            },
            {
                key : 2,
                image : 'nritana-encyclopedia-of-nepal.jpg',
                title : 'Encyclopedia of Nepal',
                description : 'It contain information about various natural and cultural resources of Nepal',
                route : 'Encyclopedia',
            },
            {
                key : 3,
                image : 'nritana-edutainment-for-student.jpg',
                title : 'Edutainment for Student',
                description : 'Edutainment for student is a software which is included in the Software..',
                route : 'Encyclopedia',
            },
            {
                key : 4,
                image : 'multimedia-typing-guide.jpg',
                title : 'Multimedia Typing Guide',
                description : 'Multimedia Typing Guide offers the lessons you need to get the job done.',
                route : 'TypingGuide',
            }
        ],

        projects : [
            {
                key : 0,
                image : 'cloudfiles-logo.png',
                title : 'CloudFiles App',
                description : 'CloudFiles enables sales & marketing teams to use files as their selling tool.',
                route : 'CloudFiles',
            },
            {
                key : 1,
                image : 'choose-your-forex-logo.png',
                title : 'Choose Your Forex',
                description : 'Forex broker reviews and promotions. A grand project.',
                route : 'ChooseYourForex',
            },
            {
                key : 2,
                image : 'tigerflo.png',
                title : 'Tigerflo Payments',
                description : 'We help businesses save money and simplify international payments.',
                route : 'Tigerflo',
            },
            {
                key : 3,
                image : '5stars-forex.png',
                title : '5Stars Forex Website',
                description : '5Stars Forex is a leading forex company. They have started from the year 2013.',
                route : '5StarsForex',
            },
            {
                key : 4,
                image : 'thrive_logo.png',
                title : 'WordPress Theme',
                description : 'Thrive social media, a WordPress theme, created using WordPress',
                route : 'ThriveSocial',
            },
        ],

        more : [
            {
                id : 1,
                title : 'Website Design',
                type : 'video',
                video : '_iPQyIaHsiA',
            },
            {
                id : 28,
                title : 'AasPass Mobile App',
                type : 'android',
                image : 'aaspass-screen-demo.jpg',
            },
            {
                id : 29,
                title : 'CloudFiles Site',
                type : 'xd',
                image : 'cloud-files-home-page.jpg',
            },
            {
                id : 30,
                title : 'ASvry Mobile App',
                type : 'android',
                image : 'asvry-mobile-app.jpg',
            },
            {
                id : 31,
                title : 'AStry Mobile App',
                type : 'xd',
                image : 'astry-mobile-app.jpg',
            },
            {
                id : 2,
                title : 'You Cook',
                type : 'video',
                video : 'gfp5kZH7UVY',
            },
            {
                id : 3,
                title : 'Arabic Digital',
                type : 'video',
                video : 'mTsaCJTf94s',
            },
            {
                id : 4,
                title : 'Circcuss Application',
                type : 'video',
                video : 'CZUf9SQs9MI',
            },
            {
                id : 5,
                title : 'Posture Therapy',
                type : 'video',
                video : 'jKb0uYvWZvg',
            },
            {
                id : 6,
                title : 'Tigerflo Payments',
                type : 'xd',
                image : 'tigerflo-home-page.png',
            },
            {
                id : 7,
                title : 'Choose Your Forex',
                type : 'psd',
                image : 'choose-your-forex-homepage.jpg',
            },
            {
                id : 8,
                title : '5Stars Forex',
                type : 'psd',
                image : '5stars-home-page.png',
            },
            {
                id : 9,
                title : 'Tigerflo Campaign',
                type : 'email',
                image : 'email-tigerflo.png',
            },
            {
                id : 10,
                title : 'Roberto Ruivo',
                type : 'video',
                video : 'IVYHXArDs7c',
            },
            {
                id : 11,
                title : 'AL-Maqdisi Guide',
                type : 'video',
                video : 'kNr8F_yYDcE',
            },
            {
                id : 12,
                title : 'M.E.F International',
                type : 'video',
                video : '9bwkPHWbUoI',
            },
            {
                id : 13,
                title : 'Versuations',
                type : 'video',
                video : 'XkmZB_gD0Q8',
            },
            {
                id : 14,
                title : 'Studien',
                type : 'video',
                video : 'J0YDLLfLwd0',
            },
            {
                id : 15,
                title : 'How Gobbill?',
                type : 'video',
                video : 'jISkpc0iLnc',
            },
            {
                id : 16,
                title : 'App Concept 1',
                type : 'xd',
                image : 'property-listing-application.jpg',
            },
            {
                id : 17,
                title : 'App Concept 2',
                type : 'xd',
                image : 'nritana-mobile-app-concept.jpg',
            },
            {
                id : 18,
                title : 'Cofx Campaign 1',
                type : 'email',
                image : 'email-cofx-1.png',
            },
            {
                id : 19,
                title : 'Cofx Campaign 2',
                type : 'email',
                image : 'email-cofx-2.png',
            },
            {
                id : 20,
                title : 'Speak And Say',
                type : 'video',
                video : 'CnKjZxJ0q_8',
            },
            {
                id : 21,
                title : 'Online Job',
                type : 'video',
                video : 'ufwC3B51Ewk',
            },
            {
                id : 22,
                title : 'Take It Easy',
                type : 'video',
                video : 'Er99pv4u07w',
            },
            {
                id : 23,
                title : 'Explainer Video',
                type : 'video',
                video : 'tTioEEHGcL4',
            },
            {
                id : 24,
                title : 'Lavinia Errico',
                type : 'psd',
                image : 'inside-out-movement-home-page.png',
            },
            {
                id : 25,
                title : 'Capital One Forex',
                type : 'psd',
                image : 'capital-one-forex-home-page.png',
            },
            {
                id : 26,
                title : 'Reddseqiop',
                type : 'psd',
                image : 'reddsequip-home-page.png',
            },
            {
                id : 27,
                title : 'Comics Museum',
                type : 'psd',
                image : 'online-comics-home-page.jpg',
            },
        ]
    };
};

/* Page Video Banner Data */
const VideoBannerInfo = () => {
    return {
        homepage : {
            type : 'Explainer Video',
            title : 'Social Video Generates 1200% More Shares Than Text And Images Combined',
            description : 'Over the past few years, digital marketing and its method of working have changed drastically. With rapid changes in trends and other factors for growth, digital marketings number platform is social media.',
            linkText : 'Need an explainer video to introduce? ➝',
            linkURL : '#',
            videoURL : 'https://www.youtube.com/embed/tTioEEHGcL4',
        }
    }
};

/* AStrick career benefits data */
const CareerBenefits = () => {
    return {
        title : "Benefits of Working With Us",
        description : "AStrick's values were made with love by AStrickian's back in the early days. We are so proud of our values and use them daily in business decisions",
        data : [
                {
                    id : 1,
                    image : Config.StockUrl + 'career/work-from-home.svg',
                    title : 'Work from Home',
                    description : 'Many of us can work from home, a cafe, or a park whenever we want. Yay freedom!'
                },
                {
                    id : 2,
                    image : Config.StockUrl + 'career/work-travel.svg',
                    title : 'Work and Travel',
                    description : 'You can work and travel around the world for up to three months every year! But we expect postcards.'
                },
                {
                    id : 3,
                    image : Config.StockUrl + 'career/environment.svg',
                    title : 'Environment',
                    description : 'We have a very friendly environment for the new enrollment and you will be guided by our senior developers.'
                },
                {
                    id : 4,
                    image : Config.StockUrl + 'career/flexible-hours.svg',
                    title : 'Flexible Hours',
                    description : 'Need to work around school drop-offs and pick-ups? Just not a morning person? No worries.'
                },
                {
                    id : 5,
                    image : Config.StockUrl + 'career/coffee.svg',
                    title : 'Coffee Bar',
                    description : 'Our kitchens are full of fresh fruit, tea, and coffee - all free. Other goodies are available with a small charity donation.'
                },
                {
                    id : 6,
                    image : Config.StockUrl + 'career/technical-education.svg',
                    title : 'Technical Education',
                    description : 'We are amazing people with mad skills. With our mentoring program you can learn from the best!'
                }
        ]
    };
};

/* Footer services data */
const AStrickServices = () => {
    return [{
        key: 1,
        active: true,
        title : 'Website Design',
        description : 'We are 12 years and more experience designing and developing websites. We are up to date with all the technology used to design a great website.',
        image : Config.StockUrl + 'footer/website.png',
        link: 'WebDesign'
      },
      {
        key: 2,
        active: false,
        title : 'Web App',
        description : 'AStrick writes modern and responsive mobile-enabled web applications. We design single-page applications that offer a more-native-app-like experience to the user.',
        image : Config.StockUrl + 'footer/web-app.png',
        link: 'WebDesign'
      },
      {
        key: 3,
        active: false,
        title : 'Explainer Video',
        description : 'The effectiveness of explainer videos on the chosen focus audience is proven to raise the general interest to about fifty percent and more than regular.',
        image : Config.StockUrl + 'footer/explainer-video.jpg',
        link: 'ExplainerVideo'
      },
      {
        key: 4,
        active: false,
        title : 'Mobile App',
        description : 'Mobile Application uses and development is a new and rapidly growing sector., we have added its development services and serving from 2019.',
        image : Config.StockUrl + 'footer/mobile-app.png',
        link: 'MobileApp'
      },
      {
        key: 5,
        active: false,
        title : 'E-commerce',
        description : 'Our team of professional designers and developers specialize in assisting the designing and development of e-commerce websites and businesses.',
        image : Config.StockUrl + 'footer/e-commerce.png',
        link: 'Ecommerce'
      }];
};

export default {
    AStrickFeatures,
    WebDevelopmentFeatures,
    AStrickPortfolio,
    VideoBannerInfo,
    CareerBenefits,
    
    AStrickServices,
};