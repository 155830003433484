<script>
    export default {
        name: 'Crumb',
        props: ['page'],
    };
</script>

<template>
    <!-- Internal Starting Page -->
    <section class="internalcrumbs">
        <div class="content-wrapper" style="overflow: initial;">
            <ul>
                <li><router-link :to="{name : 'Home'}"><img src="./../../images/icons/home.svg"></router-link></li>
                <li><img src="./../../images/icons/right.svg"></li>
                <li style="width:auto;"><p>{{page}}</p></li>
            </ul>
        </div>
    </section>
    <!-- Internal Starting Page -->
</template>