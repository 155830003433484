<template>
    <div class="blog--landing" :style="{backgroundImage: 'url('+ BlogStockUrl +')'}">
        <div class="wrapper blging">
            <h1>{{content.title}}</h1>
            <p>{{content.excerpt}}</p>
            <router-link :to="{path: '/blog/'+content.id+'/'}">Read More</router-link>
        </div>
    </div>
</template>

<script>
    import Config from './../../api/config';

    export default {
        name: "Current",
        props: ['content'],

        setup (props) {
            let BlogStockUrl;

            if (props.content) {
                BlogStockUrl = Config.StockUrl + 'blog/' + props.content.year + '/' + props.content.image;            
            }

            return {BlogStockUrl}
        }
    };
</script>