<script>
    // js import
    import PortfolioInternal from './../../api/PortfolioInternal';
    import Config from './../../api/config';

    //@import component
    import BreadCrumb from './../../components/portfolio/BreadCrumb.vue';
    import MoreProject from './../../components/portfolio/MoreProjects.vue';

    export default {
        components: {
            BreadCrumb,
            MoreProject,
        },

        setup(props) {
            let Internal = PortfolioInternal.PortfolioInternals();
            Internal = Internal.typinguide;

            let StockUrl = Config.StockUrl;

            // page title
            Config.PageTitle ({
                title : Internal.title + ' - AStrick Software'          
            });

            return {
                Internal,
                StockUrl
            }
        }
    };
</script>

<template>
    <BreadCrumb :content="Internal" />

    <section class="features grey">
        <div class="secwrapper">
            <h3>Software Details</h3>
            <p>If you're looking to increase your typing speed, accuracy Multimedia Typing Guide offers the lessons you need to get the job done. You can focus your typing practice on anything from just one hand to one finger.</p>
            <p>This new version of Typing Guide 4.0 includes so many features which is very valuable for those who want to speed up their typing speed in Nepali and English Language. The new features of this version are Lesson in both English and Nepali Language, Typing Games in both language, storing and creating your own typing lesson, store and prints the typing score, graphical presentation of score (graph chart). Typing Games are created in both languages with attractive animation and labels. Typing Games are totally related to the typing lessons.</p>
            <p>It has two new games named “Character Mario” and “Save Me” with more labels for practice, similarly users can store the typing result in their hard drive and also can make their own typing lessons.</p>
            <img :src="StockUrl + 'portfolio/multimedia_typing_guide.jpg'" />
            <h3>History and Development</h3>
            <p>Typing Guide, a multimedia typing software was started from the year 2005 with CUI version using Quick Basic Language. But later on with many more emerging technology in multimedia we should update our application to new version, earlier developed in CUI. The Second version of this application was developed using the ingle flash windows executable file. We updated this application to run in the browsers also.</p>
            <p>Meanwhile in time we want to make this more dynamic with more features like sound and games and also the graphical notation for the typing report, which will be more user-friendly. Finally, we updated the application with the installation file as version (4.0).</p>
        </div>
    </section>

    <section class="features">
        <div class="secwrapper"> 
            <img :src="StockUrl + 'portfolio/typing_guide_mario_game.jpg'" />
            <h3>Games In Typing Guide</h3>
            <p>We have developed 2 different games on this version similar to Mario shown on the above figure. This is an interesting way to learn typing in preferred language. The level goes on increasing as the level of your typing speed. The version 4.0 was more popular only because of this game design.</p>
        </div>
    </section>

    <MoreProject :current="'TypingGuide'" :type="'product'" />

</template>