<template>
    <div class="menu-hover">
        <div class="items">
            <div class="menulinks">
                <ul class="services">
                    <li>
                        <router-link :to="{name : 'AboutUs'}">
                            <div class="image">
                                <img src="../../images/svg/about.svg" />
                            </div>

                            <div class="content">
                                <h3>About Us</h3>
                                <p>Company Information</p>
                            </div>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name : 'WorkingProcess'}">
                            <div class="image">
                                <img src="../../images/svg/process.svg" />
                            </div>

                            <div class="content">
                                <h3>Working Process</h3>
                                <p>Process of working</p>
                            </div>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name : 'MeetTheTeam'}">
                            <div class="image">
                                <img src="../../images/svg/team.svg" />
                            </div>

                            <div class="content">
                                <h3>Our Team</h3>
                                <p>Team members of AStrick</p>
                            </div>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name : 'Career'}">
                            <div class="image">
                                <img src="../../images/svg/career.svg" />
                            </div>

                            <div class="content">
                                <h3>Career</h3>
                                <p>Apply for the open jobs</p>
                            </div>
                        </router-link>
                    </li>
                </ul>
            </div>

            <div class="ads">
                <h3>Policy</h3>

                <ul class="blogitems">
                    <li>
                        <router-link :to="{name : 'Blog'}">
                            <h3>Blog</h3>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name : 'Contact'}">
                            <h3>Contact Us</h3>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name : 'Privacy'}">
                            <h3>Privacy and Policy</h3>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name : 'Terms'}">
                            <h3>Terms and Conditions</h3>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name : 'Refund'}">
                            <h3>Refund Policy</h3>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name : 'Cookies'}">
                            <h3>Cookies Policy</h3>
                        </router-link>
                    </li>
                </ul>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServicesHover',
    };
</script>

<style scoped>


</style>