<script>
    
    // js import
    import Config from './../../api/config';
    import Locale from './../../api/Locale';

    //@ import resuable components
    import IllustrationHero from '../../components/reusable/IllustrationHero.vue';
    import CompanyCount from '../../components/reusable/CompanyCount.vue';

    import PageFeatures from './../../components/reusable/PageFeatures.vue';
    import ImageApp from './../../components/app/ImageApp.vue';

    export default {
        name : 'AboutUs',

        components : {
            IllustrationHero,
            CompanyCount,
            PageFeatures,

            ImageApp
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'About Us - AStrick Software'              
            });

            let StockUrl = Config.StockUrl;
            
            const BreadCrumbsData = {
                page : 'About Us',
                title : 'About AStrick',
                description : 'We are dedicated to supporting our customers to succeed in their business. Our mission is to provide our clients and customers with exceptional service at the best possible value.',
                image : 'about-us.svg'
            };            

            // page features data
            let Features = Locale.AStrickFeatures();

            return {
                BreadCrumbsData, 
                Features,
                StockUrl
            };
        }
    };
</script>

<template>    
    <IllustrationHero :content="BreadCrumbsData" />

    <!-- Section Page Content -->
    <section class="__about-us">
        <div class="wrapper">            
            <div class="about">
                <div class="content">
                    <h1>We provide technology-enabled business solutions and services.</h1>

                    <p>Incepted in 2007, AStrick Software (Pvt) Ltd is a leading Development and Design company providing a superior software, web, mobile and Creative Designing solutions and services to companies globally. Having a strong Technology Development and Innovation center in Nepal and with more than 500+ fortune companies across diverse industries, AStrick Software has made its mark as a well- established and sound company driven by the industry's highly professional and trained individuals. AStrick has surely a come long way and headed in becoming a leading organization which facilitates, enhances and provides measurable business value to customers through most effective use of technology and Resources to organization Globally.</p>

                    <p>Highly skilled intellectual personnel, most having substantial work experience in diverse areas of information Technology make up our team. Our management ensures that each individual excels in their specific assigned tasks by working closely with them. Only those individuals are hired who not only possess strong technical and analytical skills, high intellect and integrity but also who have confidence, decision making abilities and above all the drive to excel whether it is at the management level or at a technology/ software development position.</p>

                    <p>Our focused and Innovative approach is derived from the cumulative experience of our team members. As a result of their combined practical business experience with strong technology delivery capabilities, we are able to comprehend the Client's business requirements, propose IT and Creative strategies in line with vision of the business and execute the business plan by building or implementing high quality scalable business solutions through the use of latest cutting edge Technologies.</p>                    
                </div>

                <div class="image">
                    <ImageApp :source="StockUrl + 'about/about-image.png'" />
                </div>
            </div>
        </div>
    </section>

    <PageFeatures :content="Features" :extra="false" />

    <section class="__about-us">
        <div class="wrapper">
            <div class="about-footer">
                <div class="af_images">
                    <ul>
                        <li>
                            <ImageApp :source="StockUrl + 'about/woman-call.jpg'" />
                        </li>
                        <li>
                            <ImageApp :source="StockUrl + 'about/social-media.jpg'" />
                        </li>
                    </ul>
                </div>

                <div class="af_content">
                    <div>
                        <h2>If you have any question, feel free to call us</h2>
                        <h3>+977-9802974990</h3>
                        <p>Monday - Friday, 9:00 AM - 6:00 PM</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Section Page Content -->

    <CompanyCount />
</template>

<style scoped>
.af_content div:before {
    background-image: url(../../images/icons/office-phone.png);
}
</style>