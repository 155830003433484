<script>
    
    import { ref } from '@vue/reactivity';

    // js import
    import Config from './../../api/config';
    
    //@ import component
    import ImageApp from './../app/ImageApp.vue';

    export default {
        name: 'PortfolioModal',
        props: ['content'],
        emits: ['close'],
        components: {
            ImageApp,
        },

        setup (props) {

            let ImageUrl = ref('');
            let VideoUrl = ref('');
            let PortfolioType = ref(props.content.data.type);

            if (props.content.data.type == 'video') {
                VideoUrl.value = '//www.youtube.com/embed/'+ props.content.data.video +'?autoplay=1&rel=0&showinfo=0&fs=0&iv_load_policy=3&loop=1&controls=1';
            }else {
                PortfolioType = 'image';
                ImageUrl.value = Config.StockUrl + 'portfolio/' + props.content.data.image;
            }


            return {
                ImageUrl,
                VideoUrl,

                PortfolioType
            };
        },

    }
</script>

<template>
    <div class="nriauth--content anas2s fadeInUp">

        <div v-if="PortfolioType == 'image'" class="portfolio-image">
            <ImageApp :source="ImageUrl" />
        </div>

        <iframe v-if="PortfolioType == 'video'"  style="width:100%; height:100%;" enablejsapi="true" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" :src="VideoUrl"></iframe>


    </div>
</template>