<script>
    export default {
        name : 'ServicesFaQs',
        props : ['content']
    }
</script>

<template>
    <section class="_services_faqs" :style="'background-image: url('+require('../../images/services/black-pattern-bg.png')+')'">
        <div class="wrapper">                
            <div class="__sfaqs" v-for="query in content" :key="query">
                <div class="image"><img :src="require(`../../${query.icon}`)"></div>
                <div class="content">
                    <h2>{{query.question}}</h2>
                    <p>{{query.answer}}</p>
                </div>
            </div>            
        </div>
    </section>
</template>
