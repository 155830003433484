<template>
    <section class="endfooter">
        <div class="wrapper">
            <div class="weaccept">
                <ul>
                    <li><img src="../../images/api/icons/mastercard.png" alt="Master Card" title="Master Card"></li>
                    <li><img src="../../images/api/icons/visa.png" alt="Visa" title="Visa"></li>
                    <li><img src="../../images/api/icons/masetro.png" alt="Masetro" title="Maestro"></li>
                    <li><img src="../../images/api/icons/american-express.png" alt="American Express" title="American Express"></li>
                    <li><img src="../../images/api/icons/paypal.png" alt="Paypal" title="Paypal"></li>  
                    <li><img src="../../images/api/icons/esewa.png" alt="eSewa" title="eSewa"></li>
                    <li><img src="../../images/api/icons/khalti.png" alt="Khalti" title="Khalti"></li>
                </ul>
            </div>

            <div class="abouttext" style="text-align:center; font-size:12px !important;">
                <router-link :to="{name : 'AboutUs'}">Company Info</router-link> - <router-link :to="{name : 'AboutUs'}">Partnerships</router-link><br><br>
                
                <router-link :to="{name : 'AboutUs'}">About Us</router-link> - <router-link :to="{name : 'Portfolio'}">Portfolio</router-link> - <router-link :to="{name : 'Blog'}">Blog</router-link> - <router-link :to="{name : 'MeetTheTeam'}">Team Members</router-link> - <router-link :to="{name : 'WorkingProcess'}">Working Process</router-link> - <router-link :to="{name : 'Career'}">Careers</router-link> - <router-link :to="{name : 'Contact'}">Contacts</router-link><br>

                <router-link :to="{name : 'Privacy'}">Privacy &amp; Policy</router-link> - <router-link :to="{name : 'Terms'}">Terms & Conditions</router-link> - <router-link :to="{name : 'Refund'}">Refund Policy</router-link><br>

                <p class="rights">Copyright © 2007 - {{new Date().getFullYear()}} AStrick Software Pvt. Ltd. All Rights Reserved</p>

            </div>
        </div>
    </section>
</template>

<script>

//@ import components

export default {
  name: 'Copyrights',
  components: {
  }
}
</script>