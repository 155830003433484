<script>
  // vue
  import { ref } from '@vue/reactivity';

  // component
  import Fullscreen from './../modal/Fullscreen.vue';

  //@ import components
  import NewsLetter from './NewsLetter.vue';
  import FooterNav from './FooterNav.vue';
  import Copyrights from './Copyrights.vue';

  export default {
    name: 'Footer',

    components: {
        NewsLetter,
        FooterNav,
        Copyrights,

        Fullscreen
    },

    setup (props) {

            // get started
            let StartProject = ref(false);
            let ServiceType = ref('get-started');

            // close the modal
            let CloseModal = () => {
                StartProject.value = false;
            };

            // show modal
            let FullScreenModal = (type) => {
                ServiceType.value = type;
                StartProject.value = true;
            };

            return {
              StartProject,
              ServiceType,
              CloseModal,
              FullScreenModal
            }
        }
  }
</script>

<template>
  <footer :style="{'background-image':'url('+require('../../images/api/large/footer.jpg')+')'}">
      <div class="content" style="overflow:initial;">
          <NewsLetter @showmodal="FullScreenModal" />
          <FooterNav @showmodal="FullScreenModal" />
      </div>

      <Copyrights />
  </footer>
  
  <!-- Modals -->
  <Fullscreen v-if="StartProject" :from="'get-started'" :content="ServiceType" @close="CloseModal" />
</template>