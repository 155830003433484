import Config from './config';

const BlogUrl = Config.apiUri + 'blog/'
const UserToken = 'ASvisitor@via-web-platform';
const AppExtension = '.php';

// post to server
const BaseUrl = Config.apiUri;

const Error = {
    'status': false,
    'reason': "error"
};

const sendgrid = "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";

// make request and get JSON data GET
const getData = async (RequestPage, getdata) => {

    const Token = UserToken;
    let datatosend = '';

    let ApiUrl = BlogUrl + RequestPage + AppExtension;

    if (getdata) {
        datatosend = '?' + getdata.key + '=' + getdata.value;
        ApiUrl += datatosend;
    }

    // start request using fetch Api
    try {
        let response = await fetch(ApiUrl, {
            // requested method
            method: 'GET',

            // headers
            headers: {
                Accept: 'application/json',
                'X-User-Token': Token,
            }
        });

        let responseData = await response.json();
        if (responseData)
            return responseData;
        else
            return Error;

    } catch (error) {
        return Error;
    }
};

// post data to server
const postServer = async (RequestPage, Data) => {
    const Token = UserToken;
    
    // start request to the server using fetch api
    try {
        let response = await fetch(BaseUrl + RequestPage, {
            // request Method
            method : 'POST',

            // headers
            headers: {
                Accept: 'application/json',
                'X-User-Token': Token,
            },

            body: Data
        });

        let responseData = await response.json();
        if (responseData)
            return responseData;
        else
            return Error;

    } catch (error) {
        return error
    }

};

export default {
    getData,
    postServer
}