<script>

    import { ref } from '@vue/reactivity';

    // js import
    import Config from './../../api/config';
    import Validation from './../../api/Validation';
    import Request from './../../api/getRequest';
    
    //@ import component
    import ImageApp from './../app/ImageApp.vue';

    export default {
        name: 'StartProject',
        props: ['content'],
        emits: ['close'],
        components: {
            ImageApp,
        },

        setup (props,  {emit}) {

            let CurrentPageContent, Requesting = ref(false);
            let FormError = ref(false);
            let ErrorText = ref('Something went wrong! Please try again later.');

            const pageContent = {
                getstarted : {
                    title : 'Start project with us!',
                    info : 'We design, implement, and supports the full lifecycle of services surrounding todays technology.',
                    textarea : 'Your Project Description',
                    btn : 'Get Started'
                },

                development : {
                    title : 'Start web project with us!',
                    info : 'We create websites with a unique look and feel, It leaves your visitors with a positive image of your brand.',
                    textarea : 'Web Project Description',
                    btn : 'Start Project'
                },

                mobileapp : {
                    title : 'Mobile App Development',
                    info : 'We create apps with a unique look and feel, It leaves your customers with a positive image of your brand.',
                    textarea : 'App Description',
                    btn : 'Start Project'
                },

                ecommerce : {
                    title : 'E-commerce Web Development',
                    info : 'We create ecommerce sites with a unique look and feel, It leaves your customers with a positive image of your brand.',
                    textarea : 'E-commerce Description',
                    btn : 'Start Project'
                },

                video : {
                    title : 'Animated Explainer Video',
                    info : 'We work with customers globally producing memorable explainer videos and campaigns that support tell their story and convert viewers into clients.',
                    textarea : 'Your Project Description',
                    btn : 'Start Project'
                },

                request : {
                    title : 'Request a Quote',
                    info : 'Design all your web assets to uplift your business- optimized at one place, today!!',
                    textarea : 'Quotation Description',
                    btn : 'Request a Quote'
                },

                career : {
                    title : 'Apply for the Position',
                    info : 'AStrick is a fast-growing company and we are always expanding our business in every aspect. Currently, we are seeking for a very passionate youth',
                    textarea : 'Cover Letter',
                    btn : 'Apply Now'
                }
            }

            // select the data
            if (props.content == 'get-started') {
                CurrentPageContent = pageContent.getstarted;
            }else if (props.content == 'development') {
                CurrentPageContent = pageContent.development;
            }else if (props.content == 'mobile') {
                CurrentPageContent = pageContent.mobileapp;
            }else if (props.content == 'ecommerce') {
                CurrentPageContent = pageContent.ecommerce;
            }else if (props.content == 'video') {
                CurrentPageContent = pageContent.video;
            }else if (props.content == 'request-quote') {
                CurrentPageContent = pageContent.request;
            }else if (props.content == 'career') {
                CurrentPageContent = pageContent.career;
            }else {
                CurrentPageContent = pageContent.getstarted;
            }
            
            const ImageName = props.content;
            let Illustration = Config.StockUrl + 'services/' + ImageName +'.svg';

            // input modal
            let Fullname = ref('');
            let Email = ref('');
            let Description = ref('');

            // submit form
            let FormSubmission = async () => {
                if (Requesting.value) return;

                // form & validation
                let form = document.getElementById('astrick-form');
                let canGoAhead = Validation.Validate (form);
                if (canGoAhead) {
                    Requesting.value = true;

                    let formData = new FormData();
                    formData.append('fullname', Fullname.value);
                    formData.append('email', Email.value);
                    formData.append('description', Description.value);
                    formData.append('form', props.content);

                    // load data from server
                    const ReturnData = await Request.postServer('request.php', formData);
                    Requesting.value = false;

                    if (ReturnData.error == false) {
                        alert ('Thank You! Your request has been received and a AStrick team member will follow up with you soon.');
                        emit('close', 'success');                    
                    // if error
                    }else {
                        FormError.value = true;
                    }

                    // Project Description

                }
            };
            
            return {
                Illustration,
                CurrentPageContent,
                Requesting,

                Fullname,
                Email,
                Description,
                FormSubmission,

                FormError,
                ErrorText,
            }
        }

    }
</script>

<template>
    <div class="nriauth--content">            
        <div class="nricon--media anas3s fadeInRight">
            <ImageApp :source="Illustration" />
        </div>
            
        <div class="nricon--form">
            <div class="content" id="logincontent">
                <img class="anas1s fadeInUp" src="./../../images/icons/astrick-logo.svg" />
                <h2 class="anas1s fadeInUp">{{CurrentPageContent.title}}</h2>
                <p class="anas2s fadeInUp">{{ CurrentPageContent.info }}</p>

                <form class="nuth--form" id="astrick-form">
                    <div class="nform--field">
                        
                        <div class="nfeld-input anas2s fadeInUp" id="felb-Fullname">
                            <span class="icon">
                                <img src="../../images/icons/user.svg">
                            </span>
                            <div class="input">
                                <input type="text" placeholder="Fullname" v-model="Fullname" id="Fullname" data-type="len" data-max="3">
                            </div>
                        </div>

                        <div class="nfeld-input anas3s fadeInUp" id="felb-Email">
                            <span class="icon">
                                <img src="../../images/icons/mail.svg">
                            </span>
                            <div class="input">
                                <input type="text" placeholder="Email Address" v-model="Email" id="Email" data-type="email">
                            </div>
                        </div>

                        <div class="nfeld-input anas3s fadeInUp" style="height: 81px;" id="felb-Description">
                            <span class="icon">
                                <img src="../../images/icons/info.svg">
                            </span>
                            <div class="input">
                                <textarea :placeholder="CurrentPageContent.textarea" style="height: 65px;" v-model="Description" id="Description" data-type="len" data-max="7"></textarea>
                            </div>
                        </div>                        
                    </div>
                </form>

                <p class="error-field" v-if="FormError">{{ ErrorText }}</p>

                <div class="nuth-btn-field anas4s fadeInUp">
                    <div class="btn-primary" @click="FormSubmission">
                        <p v-if="!Requesting">{{ CurrentPageContent.btn }}</p>
                        <img v-else class="loader" src="./../../images/gif/btn-loader.gif" />
                    </div>                    
                </div>

            </div>
        </div>        
    </div>
</template>