<template>

    <CurrentSkeleton v-if="dataIsLoading" />
    <BlogListSkeleton v-if="dataIsLoading" />

    <div v-else>
        <Current :content="CurrentBlog" />

        <DefaultList :content="DefaultBlogs" />

        <h2 class="centered" style="padding-top:0px;"><span>Most Popular <strong>Articles</strong></span></h2>

        <DefaultList :content="PopularBlogs" />

        <h2 class="centered" style="padding-top:0px;"><span>Incase You <strong>Missed</strong></span></h2>

        <MissedList :content="MissedBlogs" />
    </div>

</template>

<script>
    // vue import
    import { ref } from '@vue/reactivity';

    // js import
    import Request from './../../api/getRequest';
    import Config from './../../api/config';
    
    // components import
    import Current from './../../components/blog/Current.vue';
    import DefaultList from './../../components/blog/DefaultList.vue';
    import MissedList from './../../components/blog/MissedList.vue';

    import CurrentSkeleton from './../../components/blog/CurrentSkeleton.vue';
    import BlogListSkeleton from './../../components/blog/BlogListSkeleton.vue';

    export default {
        name: 'BlogIndex',
        components: {
            Current,
            DefaultList,
            MissedList,

            CurrentSkeleton,
            BlogListSkeleton
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'Blog - AStrick Software'              
            });

            let dataIsLoading = ref(true);
            let CurrentBlog, DefaultBlogs, PopularBlogs, MissedBlogs;

            return {dataIsLoading, CurrentBlog, DefaultBlogs, PopularBlogs, MissedBlogs}
        },

        async mounted () {
            let Result = await Request.getData('list');
            if (!Result.error) {
                Result = Result.response;
                this.CurrentBlog = Result.current;
                this.DefaultBlogs = Result.default;
                this.PopularBlogs = Result.popular;
                this.MissedBlogs = Result.missed;

                this.dataIsLoading = false;
            }
        }
        
    }
</script>