<template>
    <div class="menu-hover">
        <div class="items">
            <div class="menulinks">
                <ul class="services">
                    <li>
                        <router-link :to="{name : 'AasPass'}">
                            <div class="image">
                                <img src="../../images/portfolio/aas-pass-logo.png" />
                            </div>
                            <div class="content">
                                <h3>AasPaas - Mobile App</h3>
                                <p>Mobile Application for Rent and Property</p>
                            </div>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name : 'AStasks'}">
                            <div class="image">
                                <img src="../../images/portfolio/astasks.png" />
                            </div>

                            <div class="content">
                                <h3>Tasks Management</h3>
                                <p>AStasks - Tasks Management Application</p>
                            </div>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name : 'Encyclopedia'}">
                            <div class="image">
                                <img src="../../images/portfolio/nritana-encyclopedia-of-nepal.jpg" />
                            </div>
                            <div class="content">
                                <h3>Encyclopedia</h3>
                                <p>Desktop Software - Encyclopedia of Nepal</p>
                            </div>
                        </router-link>
                    </li>

                    <li>
                        <router-link :to="{name : 'TypingGuide'}">
                            <div class="image">
                                <img src="../../images/portfolio/multimedia-typing-guide.jpg" />
                            </div>
                            <div class="content">
                                <h3>Multimedia Typing Guide</h3>
                                <p>Typing Software</p>
                            </div>
                        </router-link>
                    </li>

                </ul>
            </div>

            <div class="ads">
                <h3>Case Studies</h3>

                <ul class="blogitems">
                    <li>
                        <router-link :to="{name : 'ChooseYourForex'}">
                            <h3>Choose Your Forex</h3>
                            <p>Forex broker reviews and promotions. A grand project.</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name : 'Tigerflo'}">
                            <h3>Tigerflo Payments</h3>
                            <p>We help businesses save money and simplify international..</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name : '5StarsForex'}">
                            <h3>5Stars Forex Website</h3>
                            <p>5Stars Forex is a leading forex company. They have started..</p>
                        </router-link>
                    </li>
                    <li>
                        <router-link :to="{name : 'CloudFiles'}">
                            <h3>Secure File Sharing</h3>
                            <p>Supercharge your files with enterprise grade security..</p>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>

        <div class="request">
            <router-link :to="{name : 'Portfolio'}" style="text-decoration: none;display: flex;">
                <p>AStrick's Work & Portfolio</p>
                <img src="./../../images/icons/arrow-right.svg" />
            </router-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ServicesHover',
    };
</script>

<style scoped>


</style>