<template>
    <div class="blog--landing">
        <div class="wrapper blging">
            <Skeleton :loop="1" :type="'line'" :dark="true" :css="'width: 70%; margin:0px auto;'" />
            <Skeleton :loop="1" :type="'line'" :dark="true" :css="'width: 50%; margin:0px auto;'" style="margin-bottom: 35px;" />

            <Skeleton :loop="1" :type="'small-line'" :dark="true" :css="'width: 90%; margin: 0px auto;'" />
            <Skeleton :loop="1" :type="'small-line'" :dark="true" :css="'width: 70%; margin: 0px auto;'" />
        </div>
    </div>
</template>

<script>

    import Skeleton from './../app/Skeleton.vue';

    export default {
        name: "CurrentSkeleton",
        components: {
            Skeleton,
        }
    };
</script>

<style scoped>
div.blog--landing:after {
    background-color: rgb(243 243 243);
}
</style>