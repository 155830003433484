const Production = true;

var Server = "https://astricksoftware.com";
var Local = "http://localhost/api.astricksoftware.com";

const apiUri = Production ? Server + '/api/v1/' : Local + '/v1/';
const StockUrl = Production ? Server + '/api/stocks/' : Local + '/stocks/';

const title = "AStrick Software - Web and Mobile Development";

let dom = document;

// page title & seo mgmt
const PageTitle = (metadata) => {
    // scroll to the top of the page
    window.scrollTo(0, 0);

    var siteTitle;

    // site title
    if (!metadata.title) siteTitle = title;
    else siteTitle = metadata.title;
    dom.title = siteTitle;
 
}

export default {
    apiUri,
    StockUrl,
    PageTitle,
}