import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

/* finalize version of css */
import './css/astrick.min.css';
import './css/about.min.css';
import './css/blog.min.css';
import './css/portfolio.min.css';
import './css/navbar.min.css';

import './css/responsive.min.css';

// Animate
import '@/plugins/animate/animate.css';

createApp(App).use(router).mount('#app');
