<template>
    <p v-if="content.type == 'paragraph'">{{content.data.text}}</p>

    <div v-if="content.type == 'image'" class="bpc-image-container">
        <ImageApp :source="blogurl + content.data.url" />
    </div>

    <div v-if="content.type == 'video'" class="bpc-video-container">
        <iframe :src="content.data.url" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
    </div>

    <h2 v-if="content.type == 'header' && content.data.level == 2">{{content.data.text}}</h2>
    
    <h3 v-if="content.type == 'header' && content.data.level == 3">{{content.data.text}}</h3>

    <ul v-if="content.type == 'list'">
        <li v-for="item in content.data.items" :key="item">{{item}}</li>
    </ul>

</template>

<script>

    import ImageApp from './../app/ImageApp.vue';
    
    export default {
        name: 'BlogContentBuilder',
        props: ['content', 'blogurl'],
        components: {
            ImageApp,
        }
    }

</script>