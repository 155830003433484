<template>
    <section class="aservices">
        <div class="wrapper">        
            <ul class="aser-header">
                <li v-for="service in ftrservices" :key="service.key"  :class="{ active : service.active}"  @click="serviceClicked(service)">
                    <img :src="require(`../../${service.icon}`)" />
                    <p>{{ service.title }}</p>
                </li>          
            </ul>
            
            <ul class="aser-content">
                <li v-for="service in ftrservices" :key="service.key" :class="{ active : service.active}" >
                    <p>{{ service.description }}</p>
                    <img :src="require(`../../${service.image}`)" />
                </li>
            </ul>        
        </div>
    </section>
</template>

<script>

    //@ import components

    export default {
        name: 'FooterServicesIntro',
        props: ['ftrservices'],

        components: {

        },

        methods : {
            // emit to parrent component once clicked on each headings
            serviceClicked (service) {
                this.$emit('ftrchange', service);
            }
        }
    }

</script>

<style scoped>

section.aservices {background-color: #64aed9;border-top: 3px solid #5c9bbf;padding: 45px 0;padding-bottom: 135px;}
section.aservices h3 {font-weight: 200;margin: 0px;color: #fff;font-size: 40px;line-height: 55px;text-align: center;}
ul.aser-header {width: 70%;height: 105px;float: none;overflow: initial;margin: 0px auto;margin-top: 15px;border-bottom: 1px solid rgba(255, 255, 255, 0.45);padding-bottom: 13px;}
ul.aser-header > li.active {opacity: 1;}
ul.aser-header > li.active p {font-weight:600;}
ul.aser-header > li.active:after {position: absolute;content: '';background-color: #64aed9;border: 1px solid rgba(255, 255, 255, 0.64);border-left: none;width: 30px;height: 30px;
display: block;bottom: -32px;left: 40%;border-radius: 0px;transform: rotate(45deg);border-top: 0;}
ul.aser-header > li {width: 15%;float: left;text-align: center;padding: 0px 20px;cursor: pointer;opacity: 0.4;position: relative;}
ul.aser-header > li > img {width: 35px; height:35px; border-radius: 25px;border-bottom: 5px solid #5c9bbf;background-color: #fff;padding: 15px;}
ul.aser-header > li > p {width: auto;
    font-size: 11pt;
    margin-top: 10px;
    line-height: 18px;
    letter-spacing: 0.35px;
    font-weight: 500;
    color: #fff;
    text-align: center;}
ul.aser-content {width: 100%;text-align: center;margin-top: 45px;}
ul.aser-content > li {display:none;}
ul.aser-content > li.active {display: block;}
ul.aser-content > li > p {font-size: 14pt;
    line-height: 24px;
    letter-spacing: 0.35px;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    font-weight: 400;
    color: #fff;
    text-align: center;
    width: 70%;
    margin: 0px auto;
    margin-bottom: 55px;}
ul.aser-content > li > img {width: 80%;-webkit-animation-duration: 0.5s;animation-duration: 0.5s;-webkit-animation-fill-mode: both;animation-fill-mode: both;}

</style>