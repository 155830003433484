<script>
    //@ import component
    import ImageApp from './../app/ImageApp.vue';

    export default {
        name: 'PageFeatures',
        props: ['content', 'extra'],
        components : {
            ImageApp,
        },

        setup (props) {
            let Content = props.content;
            let title = Content.title;
            let description = Content.description;
            let features = Content.data;

            return {
                title,
                description,
                features
            };
        }
    }    
</script>

<template>
    <div class="page-features">
        <div class="wrapper">
            <h2 style="margin-top:45px;">{{title}}</h2>
            <p>{{description}}</p>   

            <ul>
                <li v-for="feature in features" :key="feature.key">
                    <div class="asimgcon">
                        <ImageApp :source="feature.image" />
                    </div>
                    <h3>{{ feature.title }}</h3>
                    <p>{{ feature.description }}</p>
                </li>
            </ul>

            <ul class="web__more__features webFeatures" v-if="extra">
                <p>More Features</p>
                <li>
                    <ul style="justify-content: left;">
                        <li><img src="./../../images/services/uiux.png" alt="UI/UX Design">UI/UX Design</li>
                        <li><img src="./../../images/services/htmlcss.png" alt="HTML/CSS">Valid and Clean HTML/CSS code</li>
                        <li><img src="./../../images/services/cross-browser.png" alt="Cross Browser">Cross Browser Support</li>
                        <li><img src="./../../images/services/support.png" alt="Full Support">Easy to Customise and Full Support</li>
                        <li><img src="./../../images/services/popup.png" alt="Popups">Magnific and Custom Popup</li>
                        <li><img src="./../../images/services/retina.png" alt="Retina">Retina Support Ready</li>
                        <li><img src="./../../images/services/responsive.png" alt="Responsive">Fully Responsive Design</li>
                        <li><img src="./../../images/services/w3c.png" alt="Validated">Fully W3C Validated Pages</li>
                        <li><img src="./../../images/services/menu.png" alt="Menus">Mega Menu &amp; Vertical Mega Menu</li>
                    </ul>
                </li>    
                <li>
                    <ul style="justify-content: left;">
                        <li><img src="./../../images/services/seo.png" alt="SEO">SEO Friendly</li>
                        <li><img src="./../../images/services/lazyloading.png" alt="Loading">Lazy Loading</li>
                        <li><img src="./../../images/services/backends.png" alt="Backends">CMS and Custom Backends</li>
                        <li><img src="./../../images/services/email-campaign.png" alt="Email">Email Campaigns Design</li>
                        <li><img src="./../../images/services/payment.png" alt="Payment">Payment Integration</li>
                        <li><img src="./../../images/services/api.png" alt="API">Other API Integration</li>
                        <li><img src="./../../images/services/font.png" alt="Icons">SVG/PNG and Font Icon Support</li>                        
                        <li><img src="./../../images/services/effects.png" alt="Effects">Very Smooth Transition Effects</li>
                    </ul>
                </li>    
                <li>
                    <ul style="justify-content: left;">
                        <li><img src="./../../images/services/support.png" alt="Full Support">Free 24/7 Customer Support</li>
                        <li><img src="./../../images/services/setup.png" alt="Setup">No Setup or Activation Fees</li>
                        <li><img src="./../../images/services/color.png" alt="Colors">Unlimited Color Schemes with Customizer</li>
                        <li><img src="./../../images/services/vector.png" alt="Vector Icons">Vector and Font based Icons</li>
                        <li><img src="./../../images/services/parallax.png" alt="Parallax">Parallax Backgrounds</li>
                        <li><img src="./../../images/services/material.png" alt="Material">Material Icon Sets</li>
                        <li><img src="./../../images/services/404.png" alt="404 Page">Custom 404 Page</li>
                        <li><img src="./../../images/services/devices.png" alt="Mordern Devices">Optimized for Modern Devices</li>
                    </ul>
                </li>
            </ul>

        </div>
    </div>
</template>