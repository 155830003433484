import Config from '../config'; // confugration and links
const StockUrl = Config.StockUrl;

const Hero = () => {
    return {
        type: 'video',
        title : 'Need an animated explainer video to introduce?',
        description : 'Most of the marketing videos lack the most attractive part of the video - being animated characters in the video creatively adds humanity and humor. What are you waiting for? step-up, and make your first simple humanity video.',

        isVideo : true,
        video : 'https://youtu.be/tTioEEHGcL4',
        buttonText : 'Start Project',
        buttonLink : 'start-explainer-video',
        image : StockUrl + 'services/explainer-video-bg.jpg',
        colormode : '#4e88c8',
        theme : 'dark'
    }
};

const Discover = () => {
    return [
        {
            id : 0,
            icon : 'images/services/medical.png',
            title : 'Medical',
            description : 'Website and web apps'
        },
        {
            id : 1,
            icon : 'images/services/food.png',
            title : 'Foods',
            description : 'e-commerce and delivery'
        },
        {
            id : 2,
            icon : 'images/services/services.png',
            title : 'Services',
            description : 'Web and landing pages'
        },
        {
            id : 3,
            icon : 'images/services/education.png',
            title : 'Education',
            description : 'School and Colleges'
        },
        {
            id : 4,
            icon : 'images/services/finance.png',
            title : 'Finances',
            description : 'Banks and CA forms'
        },
        {
            id : 5,
            icon : 'images/services/market.png',
            title : 'Markets',
            description : 'e-commerce and delivery'
        },
        {
            id : 6,
            icon : 'images/services/custom-design.png',
            title : 'Yours?',
            description : 'Let us collaborate.'
        }
    ];
};

const Video = () => {
    return {
        type : 'Explainer Video',
        title : 'Why explainer videos?',
        description : 'Animated explainer videos are the best tools for attracting visitors to your website and telling them about the important features of your product. They are capable to articulate your product or service in less than a minute or two. And since video allows people to get more detailed information in less time compared to reading, you get an amazing chance to win the critical attention of the viewer.',
        linkText : 'Social Video Generates 1200% More Shares Than Text And Images Combined  ➝',
        linkURL : 'asb0db9ef27e3',
        videoURL : 'https://www.youtube.com/embed/tTioEEHGcL4',
    }
};

const Features = () => {
    return {
        title : "Why we are best for explainer videos?",
        description : "We work with customers globally producing memorable explainer videos and campaigns that support tell their story and convert viewers into clients.",
        data : [{
                    key : 1,
                    image : StockUrl + 'features/budget-friendly.svg',
                    title : 'Budget-Friendly Video',
                    description : 'We are chill and not greedy at all. With us, you can get your videos done on a limited budget with a team of creative experts. Everything we make is custom made especially for you and your brand!'
                },
                {
                    key : 2,
                    image : StockUrl + 'features/years-of-experience.svg',
                    title : 'Meeting Deadlines',
                    description : 'Your satisfaction is vital to us, so we promise to make as many revisions as required and meet your hard deadlines. We strongly trust that there will be no need for revisions as we are the best.'
                },
                {
                    key : 3,
                    image : StockUrl + 'features/animated-character.svg',
                    title : 'Animated Characters',
                    description : 'The effectiveness of explainer videos on the chosen focus audience is proven to raise the general interest to about fifty percent and more than regular marketing strategies.'
                },
                {
                    key : 4,
                    image : StockUrl + 'features/revisions.svg',
                    title : 'Unlimited Revisions',
                    description : 'We will provide unlimited revisions for your explainer videos as per your requirement until you are satisfied with the end product. In this way, you will get a remarkable product.'
                },
                {
                    key : 5,
                    image : StockUrl + 'features/intro-n-logo.svg',
                    title : 'Intros and Logos',
                    description : 'Intro & animated logos are essential for your brand. It is used to make your logos get an amazing focus on your video. We use attractive colors that catch the attention of visitors thus helps to improve your sales.'
                },
                {
                    key : 6,
                    image : StockUrl + 'features/scripts.svg',
                    title : 'Script & Voiceovers',
                    description : 'Our writing team is dedicated to delivering true focus grabbers so that every person would hear you and quickly begin loving it! We promise to deliver only real scripts, specially designed to engage your audience.'
                }]
    }
};

const Portfolio = () => {
    return {
        title : 'Video Portfolio of AStrick',
        description : 'We invite you to join our family and become a part of a rising club of successfully told stories. Visit our portfolio videos to get more ideas on our successful projects and works!',
        
        data : [
            {
                id : 1,
                title : 'Short Video Ads',
                proptype : 'Roberto Ruivo',
                image : StockUrl + 'portfolio/roberto.jpg',
                linkType: 'video-modal',
                link : '',
                video : 'IVYHXArDs7c'
            },
            {
                id : 2,
                title : 'Explainer Video',
                proptype : 'YouCook',
                image : StockUrl + 'portfolio/youcook.jpg',
                linkType: 'video-modal',
                link : '',
                video : 'gfp5kZH7UVY'
            },
            {
                id : 3,
                title : 'Explainer Video',
                proptype : 'Samuel Cogo',
                image : StockUrl + 'portfolio/samuelcogo.jpg',
                linkType: 'video-modal',
                link : '',
                video : 'jKb0uYvWZvg'
            },
            {
                id : 4,
                title : 'Explainer Video',
                proptype : 'MEF',
                image : StockUrl + 'portfolio/mef.jpg',
                linkType: 'video-modal',
                link : '',
                video : '9bwkPHWbUoI'
            },
        ]
    }
};

const Queries = () => {
    return [
        {
            id : 1,
            icon : 'images/services/choose.png',
            question : 'Why should I choose AStrick?',
            answer : 'At AStrick, we have the power to humanize your brand, promote your products, educate your customers, and so much more! It is not only about having one best Web Design on your homage. AStrick will improve your marketing effort, and better your ongoing strategy.'
        },
        {
            id : 2,
            icon : 'images/services/duration.png',
            question : 'How long does a video take?',
            answer : 'It depends upon the script of the video. However, we try to deliver within a week. Making a professional and attractive video will always require enough time.'
        },
        {
            id : 3,
            icon : 'images/services/video-process.png',
            question : 'How does our video process work?',
            answer : 'For video making, we will collect your script or details of your video and get a clear idea of what your audience needs then we work on voiceover, and animated characters of your video to make it attractive. We deliver your work always on time.'
        },
        {
            id : 4,
            icon : 'images/services/compare.png',
            question : 'How we differ from others?',
            answer : 'Unlike others, we create our animated characters, backgrounds, and icons to ensure your final video remains pristine and stand out from others. All our products are 100% copyright-free, and thus will give you 100% ownership of these items.'
        },
    ]
};

const Review = () => {
    return {
        review : 'AStrick was truly a great find. It was our first experience getting our product video made and AStrick certainly did a great job at it. The brief given was easily understood and interpreted just the way we wanted. More importantly, even minor changes were incorporated without a glitch. Time schedules were strictly followed and the quality of work delivered was well worth. Thank you AStrick. We are definitely working in the future yet again.',
        customer : 'Delnadordi - India',
    }
};

export default {
    Hero,
    Discover,
    Video,
    Features,
    Portfolio,
    Queries,
    Review,
}