import { createRouter, createWebHistory } from 'vue-router';

// Main Pages/Views
import HomePage from '../views/HomePage.vue';
import Development from '../views/services/Development.vue';
import ExplainerVideo from '../views/services/ExplainerVideo.vue';
import MobileApp from '../views/services/MobileApp.vue';
import Ecommerce from '../views/services/Ecommerce.vue';

// About Pages
import AboutUs from '../views/about/AboutUs.vue';
import WorkingProcess from '../views/about/WorkingProcess.vue';
import Career from '../views/about/Career.vue';
import ContactUs from '../views/about/ContactUs.vue';
import MeetTheTeam from '../views/about/MeetTheTeam.vue';

// policies pages
import TermsPage from '../views/about/Terms.vue';
import PrivacyPage from '../views/about/Privacy.vue';
import RefundPage from '../views/about/Refund.vue';
import CookiesPage from '../views/about/Cookies.vue';

// portfolio pages
import Portfolio from '../views/Portfolio.vue';

// product
import AasPaas from '../views/portfolio/AasPaas.vue';
import AStasks from '../views/portfolio/AStasks.vue';
import Encyclopedia from '../views/portfolio/Encyclopedia.vue';
import TypingGuide from '../views/portfolio/TypingGuide.vue';

// case studies
import CloudFiles from '../views/portfolio/CloudFiles.vue';
import FStarsForex from '../views/portfolio/5StarsForex.vue';
import ChooseYourForex from '../views/portfolio/ChooseYourForex.vue';
import ThriveSocial from '../views/portfolio/ThriveSocial.vue';
import Tigerflo from '../views/portfolio/Tigerflo.vue';

// blogs
import BlogIndex from '../views/blog/BlogIndex.vue';
import BlogDetail from './../views/blog/BlogDetail.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },

  // Services pages
  {
    path : '/web-design/',
    name : 'WebDesign',
    component : Development
  },
  {
    path : '/explainer-video/',
    name : 'ExplainerVideo',
    component : ExplainerVideo
  },
  {
    path : '/mobile-app/',
    name : 'MobileApp',
    component : MobileApp
  },
  {
    path : '/e-commerce/',
    name : 'Ecommerce',
    component : Ecommerce
  },
  
  // About Pages
  {
    path: '/about-us/',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/working-process/',
    name: 'WorkingProcess',
    component: WorkingProcess
  },
  {
    path: '/career/',
    name: 'Career',
    component: Career
  },
  {
    path: '/contact-us/',
    name: 'Contact',
    component: ContactUs
  },
  {
    path: '/team/',
    name: 'MeetTheTeam',
    component: MeetTheTeam
  },

  // policies pages
  {
    path: '/terms-and-condition/',
    name: 'Terms',
    component: TermsPage
  },
  {
    path: '/privacy-policy/',
    name: 'Privacy',
    component: PrivacyPage
  },
  {
    path: '/cookies-policy/',
    name: 'Cookies',
    component: CookiesPage
  },
  {
    path: '/refund-policy/',
    name: 'Refund',
    component: RefundPage
  },

  // portfolio index & portdolio pages
  {
    path: '/portfolio/',
    name: 'Portfolio',
    component: Portfolio
  },
  {
    path: '/aaspass/',
    name: 'AasPass',
    component: AasPaas
  },
  {
    path: '/cloud-files/',
    name: 'CloudFiles',
    component: CloudFiles
  },
  {
    path: '/astasks/',
    name: 'AStasks',
    component: AStasks
  },
  {
    path: '/encyclopedia/',
    name: 'Encyclopedia',
    component: Encyclopedia
  },
  {
    path: '/typing-guide/',
    name: 'TypingGuide',
    component: TypingGuide
  },
  {
    path: '/5stars-forex/',
    name: '5StarsForex',
    component: FStarsForex
  },
  {
    path: '/choose-your-forex/',
    name: 'ChooseYourForex',
    component: ChooseYourForex
  },
  {
    path: '/thrive-social-media/',
    name: 'ThriveSocial',
    component: ThriveSocial
  },
  {
    path: '/tigerflo/',
    name: 'Tigerflo',
    component: Tigerflo
  },

  // blog & details
  {
    path: '/blog/',
    name: 'Blog',
    component: BlogIndex,
  },
  {
    path: '/blog/:blogid',  // => /blog/social-video-generates-1200%-more-shares-than-text-and-images-combined
    name: 'BlogDetail',
    component: BlogDetail,
  }

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;