<script>
    // vue
    import { ref } from '@vue/reactivity';

    // component
    import Fullscreen from './../../components/modal/Fullscreen.vue';

    export default {
        name : 'ServicesHero',
        props : ['content', 'video'],
        components: {
            Fullscreen
        },
        
        setup (props) {

            // get started
            let StartProject = ref(false);
            let ServiceType = props.content.type;

            // close the modal
            let CloseModal = () => {
                StartProject.value = false;
            };
            
            // theme for requested hero section
            let theme = ref(null);
            let sentheme = props.content.theme;
            if (sentheme == 'dark') {
                theme = 'sl-dark';
            }else if (sentheme == 'solid') {
                theme = 'sl-solid';
            }else {
                theme = '';
            }

            // video modal handler
            // portfolio modal reactive
            let PortfolioModal = ref(false)
            let PortfolioModalData = props.video;

            // close the portfolio modal
            let ClosePortfolioModal = () => {
                PortfolioModal.value = false;
            };

            return {
                theme,
                StartProject,
                CloseModal,
                ServiceType,

                PortfolioModal,
                PortfolioModalData,
                ClosePortfolioModal,
            }
        }
    };
</script>

<template>
    <section :class="theme" class="services--landing anas1s fadeInDown" 
            :style="'background-image: url('+content.image+'); background-color: '+content.colormode">
        <div class="wrapper">
            <div class="hero-content">
                <h1 class="anas2s fadeInUp">{{content.title}}</h1>
                <p class="anas3s fadeInUp">{{content.description}}</p>
                <span class="anas4s fadeInUp btn-primary" style="width: 110px;" @click="StartProject = true">{{content.buttonText}}</span>
                <span class="anas4s fadeInUp link" @click="PortfolioModal = true;">or play our video</span>
            </div>
        </div>
    </section>

    <!-- Modals -->
    <Fullscreen v-if="StartProject" :from="'get-started'" :content="ServiceType" @close="CloseModal" />
    <Fullscreen v-if="PortfolioModal == true" :from="'portfolio-modal'" :content="PortfolioModalData" @close="ClosePortfolioModal" />    

</template>

<style scoped>
    .sl-dark h1 {color: #f6fafd !important;}
    .sl-dark p {color: #f6fafd !important;}
    .sl-dark span.link {color: #f6fafd !important;}

    .sl-solid h1 {color: #3e3e3e !important;}
    .sl-solid p {color: #363636 !important;}
    .sl-solid span.link {color: #363636 !important;}
</style>