<template>
    <ul class="nav-footer">
        <li>
            <ul>
                <li>About</li>
                <li>
                    <p>Incepted in 2007, AStrick Software is a leading Development and Design company providing a superior software, web, mobile and Creative Designing solutions and services to companies globally.</p>
                    <p>Having a strong Technology Development and Innovation center in Nepal and with more than 500+ satisfied clients ranging from startup to fortune companies across diverse industries.</p>
                </li>
            </ul>
        </li>

        <li style="margin-left: 2%;width: 16%;">
            <ul>
                <li>Company</li>
                <li><router-link :to="{name : 'AboutUs'}">About</router-link></li>
                <li><router-link :to="{name : 'Blog'}">Blog</router-link></li>
                <li><router-link :to="{name : 'AboutUs'}">Why AStrick ?</router-link></li>
                <li><router-link :to="{name : 'Portfolio'}">Our Works</router-link></li>
                <li><router-link :to="{name : 'WorkingProcess'}">Working Process</router-link></li>
                <li><router-link :to="{name : 'Refund'}">Refund Policy</router-link></li>
                <li><router-link :to="{name : 'Terms'}">Terms & Conditions</router-link></li>
                <li><router-link :to="{name : 'Privacy'}">Privacy Policy</router-link></li>
            </ul>
        </li>

        <li>
            <ul>
                <li>Services</li>                    
                <li><router-link :to="{name : 'WebDesign'}">Web Design And <br>Development</router-link></li>
                <li><router-link :to="{name : 'MobileApp'}">Mobile App <br>Development</router-link></li>
                <li><router-link :to="{name : 'Ecommerce'}">E-commerce and <br> Web Application</router-link></li>
                <li><router-link :to="{name : 'ExplainerVideo'}">Explainer Video <br>Animation</router-link></li>
            </ul>
        </li>

        <li>
            <ul>
                <li>Get Started!</li>
                <li><p>Sign up is free and only takes a few minutes. Order a report immediately.</p></li>
                <li><a href="#start-project" class="startproject" @click="StartProject('get-started')">Get Started</a></li>
                <li><a href="#requestquote" class="requestquote" @click="StartProject('request-quote')">Request a Quote</a></li>
                <li><router-link :to="{name : 'AboutUs'}">Partnerships</router-link></li>
            </ul>
        </li>

        <li>
            <ul>
                <li>Support</li>
                <li><router-link :to="{name : 'Blog'}">Blog</router-link></li>
                <li><router-link :to="{name : 'Contact'}">Contact Us</router-link></li>
                <li><router-link :to="{name : 'AboutUs'}">About Us</router-link></li>
                <li><a href="tel:+977-9802974990">+977-9802974990</a></li>
                <li><a href="mailto:support@astricksoftware.com">support@astricksoftware.com</a></li>
                
                <li>
                    <a href="https://www.facebook.com/astricksoft" target="_blank" style="text-decoration: none;">
                        <img src="../../images/api/icons/facebook.png">
                    </a>
                    <a href="https://www.linkedin.com/company/astricksoftware" target="_blank" style="text-decoration: none;">
                        <img src="../../images/api/icons/linkedin.png">
                    </a>
                    <a href="https://twitter.com/astricksoftware" target="_blank" style="text-decoration: none;">
                        <img src="../../images/api/icons/twitter.png">
                    </a>                        
                    <a href="https://www.youtube.com/channel/UCf0EToFZtBRav4Ns9xNGNxA" target="_blank" style="text-decoration: none;">
                        <img src="../../images/api/icons/youtube.png">
                    </a>
                </li>

            </ul>
        </li>
    </ul>
</template>

<script>

    export default {
        name: 'FooterNav',
        emits: ['showmodal'],
        components: {
        },

        setup (props, {emit}) {
            
            let StartProject = (project) => {
                emit('showmodal', project);
            };

            return {
                StartProject,
            }
        }
    }

</script>