<script>
    // js import
    import PortfolioInternal from './../../api/PortfolioInternal';
    import Config from './../../api/config';

    //@import component
    import BreadCrumb from './../../components/portfolio/BreadCrumb.vue';
    import MoreProject from './../../components/portfolio/MoreProjects.vue';

    export default {
        components: {
            BreadCrumb,
            MoreProject,
        },

        setup(props) {
            let Internal = PortfolioInternal.PortfolioInternals();
            Internal = Internal.thrivesocial;

            let StockUrl = Config.StockUrl;

            // page title
            Config.PageTitle ({
                title : Internal.title + ' - AStrick Software'          
            });

            return {
                Internal,
                StockUrl
            }
        }
    };
</script>

<template>
    <BreadCrumb :content="Internal" />

    <section class="features grey">
        <div class="secwrapper">       
            <h3>Project Planning</h3>
            <p>After the discussion with client we found that the client need a neat and clean website built in WordPress with custom theme. He need workflow of the project in the foot step following up of the PSD design. After finishing edits with PSD design we moved to HTML/CSS according to the desire of client. Finally we finished working with all the pages and design in PSD and HTML/CSS. He was fine with Custom HTML/CSS till then, but further he wants to move the site to custom WordPress theme so that he can easily update his website in the future.</p>
            <img :src="StockUrl + 'portfolio/thrive_psd.jpg'" />
            <h3>PSD Design</h3>
            <p>We always start designing the webpages from the very beginning using Photoshop. We have designed more than 200+ pages using Photoshop to make it responsive and vibrant for different devices like Mobile phones, iPad, Surface etc. we take time to design on PSD to make neat and clean UI/UX design. We did the same with this website. Created just 2 design for homepage and single internal page and after that moved towards WordPress.</p>  
        </div>
    </section>

    <section class="features grey">
        <div class="secwrapper">           
            <div class="multi-image">
                <img :src="StockUrl + 'portfolio/thrive_code.jpg'" style="margin-right:2%;" />
                <img :src="StockUrl + 'portfolio/thrive_html.jpg'"  style="margin-left:2%;"/>
            </div>
            <h3>PSD to HTML/CSS</h3>
            <p>Slicing from PSD to HTML/CSS is really an easy task. We feel easier rather than creating PSDs. There is nothing new and challenging tasks in coding HTML/CSS. Further we feel documentation of every project is more important as everybody can understand the program. We did same with this project. We need readable codes and clean design, which we did it in the documentation of the project.</p>
            <p>After working a single day we have finished this website converting into HTML/CSS and goes further to make more pages and sliders.</p>
        </div>
    </section>

    <section class="features">
        <div class="secwrapper">
            <img :src="StockUrl + 'portfolio/thrive_result.jpg'" />
            <h3>Project Development</h3>
            <p>PSD to HTML/CSS and now finally we have to move to WordPress.</p>
            <p>After finishing everything with WordPress we moved towards managing codes and other works like Visual Design, Responsive, Sitemap, Valid CSS, Cross Browser, Statistics and Tracking etc.</p>
        </div>
    </section>

    <MoreProject :current="'ThriveSocial'" :type="'project'" />

</template>