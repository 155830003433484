<template>
    <section class="ilintport internal--landing" :style="{backgroundImage: 'url('+content.bgimage+')', backgroundColor: content.bgcolor}">
        <div class="wrapper">
            <div class="content">
                <ul class="anas2s fadeInUp">
                    <li><router-link :to="{name : 'Home'}">Home</router-link></li>
                    <li><span class="devider"></span></li>
                    <li><router-link :to="{name : 'Portfolio'}">Portfolio</router-link></li>
                    <li><span class="devider"></span></li>
                    <li><span>{{content.page}}</span></li>
                </ul>
                <h3 class="anas3s fadeInUp">{{content.title}}</h3>
                <p class="anas4s fadeInUp">{{content.description}}</p>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'BreadCrumb',
        props: ['content']
    };
</script>