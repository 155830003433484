<script>
    // js import
    import PortfolioInternal from './../../api/PortfolioInternal';
    import Config from './../../api/config';

    //@import component
    import BreadCrumb from './../../components/portfolio/BreadCrumb.vue';
    import MoreProject from './../../components/portfolio/MoreProjects.vue';

    export default {
        components: {
            BreadCrumb,
            MoreProject,
        },

        setup (props) {

            let Internal = PortfolioInternal.PortfolioInternals();
            Internal = Internal.fstarsforex;

            let StockUrl = Config.StockUrl;

            // page title
            Config.PageTitle ({
                title : Internal.title + ' - AStrick Software'          
            });

            return {
                Internal,
                StockUrl
            }
        }
    };
</script>

<template>
    <BreadCrumb :content="Internal" />

    <section class="features" style="box-shadow: none;">
        <div class="secwrapper">  
            <h3>Sketching wireframes</h3>            
            <p>we did research to the company first of all and after the discussion and inquiry to the clients we found and decided that the forex website need a distinctive face which will be SEO-Friendly, Mobile/Browser Friendly, unlike, the old website of them. This forex website integrate so many APIs for payments and accounts.</p>            
            <p>we did research with many other forex websites and structures and found that few more things need to be added to the project like forex education, promotions page etc. we give a great homepage design to them and the whole team were happy with our design.</p>                    
            <img :src="StockUrl + 'portfolio/5stars-home-page.png'" />
            
            <h3>Home Page Design</h3>
            
            <p>After studying the forex industries and other competitor’s website we found that the homepage, sliders, promotion banners are the most significant things to the site to catch traders/clients. At the initial stage we design 3 different promotions of the company using premium slider jQuery plugin.</p>
            
            <p>Furthermore, we have focused on the products of the company and pointing the account opening links to the homepage and payments options is also the top most things to the forex website. And we take care of the best footer design which includes all their important links.</p>

        </div>
    </section>

    <section class="features grey">
        <div class="secwrapper">  
            <img :src="StockUrl + 'portfolio/5stars-internal-page-design.png'" />
            <h3>Page Design</h3>
            <p>There are about more than 100 internal pages of whole project. We have designed each page according to the content like creating banners on each page according to the page designation, showing article features using un-ordered list and designing icons for them etc.</p>
            <p>Few pages contain web forms where we worked using AJAX to make the website more functional; to the pages like feedback, refer-a-friend and contact page. The side bar and navigation are coded in such a way that they remains same in the screen to the user’s eye like scroll spy in bootstrap framework.</p>
            <h3>Project Development</h3>
            <p>This is our greatest experience as compared to any projects that we have worked with full functions and clients desire alone like Front-End Design, Promotions Banner and Pages, Back-End, E-Mail Campaign design and API Integration like Neteller Payment, Mailchimp, and SMS API etc. we take just 60 Days of clients to give the whole result and replacing the old site.</p>
            <p>The team members of the company love the design and few new ideas. They hired us to the department of Information Technology and Technical Analysist also.</p>
        </div>
    </section>

    <section class="features">
        <div class="secwrapper">   
            <div class="video"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/wCAGmTVWVjE" frameborder="0" allowfullscreen=""></iframe></div>
            <h3>Company Intro Video</h3>
            <p>While working with the project for few months we slowly grow our knowledge on forex trading and the forex broker. Found that, what they actually need in daily life to collect traders. So we converted the company features and promotions in Visual Format (Shown Above)</p>        
        </div>
    </section>

    <MoreProject :current="'5StarsForex'" :type="'project'" />

</template>