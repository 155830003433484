<template>
    <ServicesHero :content="Hero" :video="{data: {video : '_iPQyIaHsiA', type: 'video'}}" />

    <DiscoverArea :content="Discover" :link="'ecommerce'" />

    <VideoBanner :content="Video" style="padding-bottom: 145px;" />

    <PageFeatures :content="Features" :extra="true"  />

    <ServicesPortfolio :content="Portfolio" @modal="ModalPortfolioHandler" />

    <ServicesFaQs :content="Faqs" />

    <ServicesReview :review="Review" />

    <!-- Modals -->
    <Fullscreen v-if="PortfolioModal == true" :from="'portfolio-modal'" :content="PortfolioModalData" @close="ClosePortfolioModal" />

</template>

<script>

    // vue
    import { reactive, ref } from '@vue/reactivity';

    // javascript Library
    import PageContent from './../../api/services/ecommerce';
    import Config from './../../api/config';

    //@ component import
    import ServicesHero from '../../components/reusable/ServicesHero.vue';
    import DiscoverArea from '../../components/web-design/DiscoverArea.vue';
    import VideoBanner from '../../components/reusable/VideoBanner.vue';

    import PageFeatures from '../../components/reusable/PageFeatures.vue';
    import ServicesPortfolio from '../../components/reusable/ServicesPortfolio.vue';
    import ServicesFaQs from '../../components/reusable/ServicesFaQs.vue';
    import ServicesReview from '../../components/reusable/ServicesReview.vue';

    // component
    import Fullscreen from './../../components/modal/Fullscreen.vue';


    export default {
        name : 'Ecommerce',
        components : {
            ServicesHero,
            DiscoverArea,
            VideoBanner,
            PageFeatures,
            ServicesPortfolio,
            ServicesFaQs,
            ServicesReview,

            Fullscreen
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'E-commerce Development - AStrick Software'          
            });

            // portfolio modal reactive
            let PortfolioModal = ref(false)
            let PortfolioModalData = reactive({
                data : ''
            });

            // close the portfolio modal
            let ClosePortfolioModal = () => {
                PortfolioModal.value = false;
            };

            // portfolio modal opener
            let ModalPortfolioHandler = (data) => {
                PortfolioModalData.data = data; 
                PortfolioModal.value = true;
            };

            // services content
            let Hero = PageContent.Hero();
            let Discover = PageContent.Discover();
            let Video = PageContent.Video();
            let Features = PageContent.Features();

            let Portfolio = PageContent.Portfolio();
            let Faqs = PageContent.Queries();
            let Review = PageContent.Review();

            return {
                Hero,
                Discover,
                Video,
                Features,
                Portfolio,
                Faqs,
                Review,
                
                PortfolioModal,
                PortfolioModalData,
                ClosePortfolioModal,
                ModalPortfolioHandler
            }
        },
        
    };
</script>