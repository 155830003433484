<script>
    export default {
        name : 'VideoBanner',
        props : ['content']
    }
</script>

<template>
    <section class="as--videoadvs">
        <div class="wrapper">
            <div class="vi-content">
                <div class="vic-article">
                    <span>{{ content.type }}</span>                    
                    <h3>{{ content.title }}</h3>                    
                    <p>{{ content.description }}</p>    
                    <router-link :to="{path: '/blog/'+content.linkURL+'/'}">                
                        {{ content.linkText}}
                    </router-link>
                </div>

                <div class="vic-video">
                    <div class="vid-wrapper">
                        <iframe enablejsapi="true" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" :src="content.videoURL"></iframe>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>