import Config from "./config";
const StockUrl = Config.StockUrl;

const PortfolioInternals = () => {
    return {
        astasks : {
            page : 'AStasks',
            title : 'Tasks Management Web App',
            description: 'AStasks is a web application purely developed to manage the workloads and tasks for the professionals. It helps the professionals to keep the track on each and every employee with ease of single button. This further helps the employer to find out the potential of its employee.',
            bgcolor : '#2d4085',
            bgimage : StockUrl + 'internal/astasks.jpg'
        },

        fstarsforex : {
            page : 'Forex Website',
            title : 'Brand and Web Design',
            description: '5Stars Forex is a leading forex company. They have started from the year 2013. I have re-designed their brand and website in 2015. The re-design site makes them easier to make them their leads.',
            bgcolor : '#2d4085',
            bgimage : StockUrl + 'internal/5stars-forex.jpg'
        },

        aaspaas : {
            page : 'AasPaas',
            title : 'Property & Vehicel on Rent',
            description: 'AasPass solves the crucial problem of advertising your property to find the right customers for it. Unfortunately, most of the property owners can end up losing a lot of their hard-earned money just for advertisement.',
            bgcolor : '#2d4085',
            bgimage : StockUrl + 'internal/aaspass-mobile-app.jpg'
        },

        chooseyourforex : {
            page : 'Choose Your Forex',
            title : 'Custom Website Design',
            description: 'Choose Your Forex is a leading web portal for those who wants to learn about the forex. Established in 2015, since then we provide help, support and guidelines in choosing the best available forex broker.',
            bgcolor : '#2d4085',
            bgimage : StockUrl + 'internal/choose-your-forex.jpg'
        },

        cloudfiles : {
            page : 'CloudFiles',
            title : 'File Sharing for Businesses',
            description: 'Supercharge your files with enterprise grade security, page-by-page analytics & deep integrability.',
            bgcolor : '#100337',
            bgimage : StockUrl + 'internal/cloud-files-site.jpg'
        },

        encyclopedia : {
            page : 'Encyclopedia',
            title : 'Nritana, Encyclopedia of Nepal',
            description: 'Nritana, registered multimedia software is a grand software that I have developed with few team members from 2008 after my school level to show Nepals popular and rural tourism places online and in digital format.',
            bgcolor : '#2d4085',
            bgimage : StockUrl + 'internal/nritana-encyclopedia.jpg'
        },

        thrivesocial : {
            page : 'WordPress Theme',
            title : 'Wordpress Theme, Social Marketing Website',
            description: 'Thrive social media, a WordPress theme, created using HTML5, CSS3, JQUERY, AJAX and WordPress, is a social marketing website. Developing the site was started from the scratch with the PSD design, as the client demand the same first of all.',
            bgcolor : '#2d4085',
            bgimage : StockUrl + 'internal/thrive-social.jpg'
        },

        tigerflo : {
            page : 'Tigerflo',
            title : 'Custom Website Design',
            description: 'We believe financial services should be transparent and easy to understand for everyone. That is why we fully disclose our FX margin and fees on every payment. Most payment providers can not say the same.',
            bgcolor : '#1379cd',
            bgimage : StockUrl + 'internal/tigerflo-global.jpg'
        },

        typinguide : {
            page : 'Typing Guide',
            title : 'Multimedia Typing Guide 4.0',
            description: 'This typing tutor software for windows; We have started from CUI based using Qbasic Programming on 2005, the CUI based was also a truly great software for real-life who want to increase the typing speed.',
            bgcolor : '#136894',
            bgimage : StockUrl + 'internal/typing-guide.jpg'
        }



    };
};

export default {
    PortfolioInternals
};
