<script>
    // js import
    import Config from './../../api/config';

    //@ import component
    import ImageApp from './../app/ImageApp.vue';

    export default {
        name : 'IllustrationHero',
        props: ['content'],
        components: {
            ImageApp,
        },

        setup (props) {
            let Image = props.content.image;
            Image = Config.StockUrl + 'internal/' + Image;
            return {Image};
        }
    }

</script>

<template>
    <section class="illustration--landing">
        <div class="wrapper">
            <div class="il--content">
                <div class="content">
                    <ul class="anas2s fadeInUp">
                        <li><router-link :to="{name : 'Home'}">Home</router-link></li>
                        <li><span class="devider"></span></li>
                        <li><span>{{content.page}}</span></li>
                    </ul>
                    <h3 class="anas3s fadeInUp">{{content.title}}</h3>
                    <p class="anas4s fadeInUp">{{content.description}}</p>
                </div>

                <div class="illustration anas2s fadeInDown">
                    <ImageApp :source="Image" />
                </div>
            </div>
        </div>
    </section>
</template>