import Config from '../config'; // confugration and links
const StockUrl = Config.StockUrl;

const Hero = () => {
    return {
        type: 'mobile',
        title : 'Do you need a mobile app for better business?',
        description : "With the latest developments in mobile apps and the introduction of innovative technological features, user trends have been changed massively. Designing a mobile app isn't easy at all but, what's more difficult is to make sure that the users have fun while using the app.",

        isVideo : true,
        video : 'https://youtu.be/tTioEEHGcL4',
        buttonText : 'Start Project',
        buttonLink : 'start-web-development',
        image : StockUrl + 'services/mobile-app-bg.jpg',
        colormode : '#fbe4de',
        theme : 'normal'
    }
};

const Discover = () => {
    return [
        {
            id : 0,
            icon : 'images/services/medical.png',
            title : 'Medical',
            description : 'Website and web apps'
        },
        {
            id : 1,
            icon : 'images/services/food.png',
            title : 'Foods',
            description : 'e-commerce and delivery'
        },
        {
            id : 2,
            icon : 'images/services/services.png',
            title : 'Services',
            description : 'Web and landing pages'
        },
        {
            id : 3,
            icon : 'images/services/education.png',
            title : 'Education',
            description : 'School & Colleges'
        },
        {
            id : 4,
            icon : 'images/services/finance.png',
            title : 'Finances',
            description : 'Banks & CA forms'
        },
        {
            id : 5,
            icon : 'images/services/market.png',
            title : 'Markets',
            description : 'e-commerce and delivery'
        },
        {
            id : 6,
            icon : 'images/services/custom-design.png',
            title : 'Yours?',
            description : 'Let us collaborate.'
        }
    ];
};

const Video = () => {
    return {
        type : 'App Development',
        title : 'Cross-Platform Mobile App Development: Best Frameworks 2022',
        description : 'Cross-platform app development marks the successful future of any digital business. Today, every business needs an app. However, not everyone can spend hundreds to thousands of dollars on app development, so they need solutions that deliver high quality even on a low budget. That is where the cross-platform apps step in.',

        linkText : 'Want to create your mobile app? ➝',
        linkURL : 'asb324p264b0a',
        videoURL : 'https://www.youtube.com/embed/-P6Qr3gdlxw',
    }
};


const Features = () => {
    return {
        title : "App Design and development features!",
        description : "We create mobile apps with a unique look and feel, which not only instills trust to your customer but also leaves them with a positive image of your brand.",
        data : [{
                    key : 1,
                    image : StockUrl + 'features/simplicity.svg',
                    title : 'Simplicity',
                    description : 'The first crucial mobile app feature is simplicity; it means that your interface should be kept as simple as possible so everyone can access it easily. If the app is free to access for all, then it’s another perk.'
                },
                {
                    key : 2,
                    image : StockUrl + 'features/performance.svg',
                    title : 'Finest Performance',
                    description : 'People are more likely to be frustrated if the app doesn’t open up fast and has a slow loading speed. Therefore, the app should always offer the best performance to its users and should never be down.'
                },
                {
                    key : 3,
                    image : StockUrl + 'features/flexibility.svg',
                    title : 'Flexibility',
                    description : 'The three most common mobile operating systems in today’s world are iOS, Android, and Windows. If you wish to achieve the best out of your app, you need to make it available on all of these platforms. '
                },
                {
                    key : 4,
                    image : StockUrl + 'features/color-scheme.svg',
                    title : 'Unlimited Color Schemes',
                    description : 'Your users might get bored when seeing the same colors and theme for a long time. Our design team works with the best colors that suit your app and depict what you do. Once the theme is ready, users can easily customize it.'
                },
                {
                    key : 5,
                    image : StockUrl + 'features/security.svg',
                    title : 'All-inclusive Security',
                    description : 'All the online applications must have a robust security system to make sure it’s safe from hackers and any viruses. We know that internet security is a matter of concern and the same applies for your app as well. '
                },
                {
                    key : 6,
                    image : StockUrl + 'features/regular-updates.svg',
                    title : 'Regular Updates',
                    description : 'The trends in the digital world change continuously; to keep up with those trends and improve your app’s user experience, you must give regular up-gradation after you create an app and publish it to your audience.'
                }]
    }
};

const Portfolio = () => {
    return {
        title : 'Mobile App Portfolio',
        description : 'Designing a mobile app is not easy at all but, what is more difficult is to make sure that the users have fun while using the app.',
        
        data : [
            {
                id : 1,
                title : 'AasPass Mobile App',
                proptype : 'Property or Vehicle on Rent',
                image : StockUrl + 'portfolio/aaspass.jpg',
                linkType: 'link',
                link : 'AasPass',
                video : ''
            },
            {
                id : 2,
                title : 'ASvry Mobile App',
                proptype : 'Food Delivery',
                image : StockUrl + 'portfolio/asvry.jpg',
                linkType: 'image',
                link : 'asvry-mobile-app.jpg',
            },
            {
                id : 3,
                title : 'AStry Mobile App',
                proptype : 'Property Management',
                image : StockUrl + 'portfolio/astry.jpg',
                linkType: 'image',
                link : 'astry-mobile-app.jpg',
            }
        ]
    }
};

const Queries = () => {
    return [
        {
            id : 1,
            icon : 'images/services/choose.png',
            question : 'Why should I choose AStrick?',
            answer : 'At AStrick, we have the power to humanize your brand, promote your products, educate your customers, and so much more! It is not only about having one best Web Design on your homage. AStrick will improve your marketing effort, and better your ongoing strategy.'
        },
        {
            id : 2,
            icon : 'images/services/duration.png',
            question : 'How long does a mobile app take?',
            answer : 'It depends upon the app requirements, However, we try to deliver within 30 days of working days for professional and attractive simple mobile app.'
        },
        {
            id : 3,
            icon : 'images/services/video-process.png',
            question : 'How does our design process work?',
            answer : 'We are dedicated to helping our customers succeed in their business. Our mission is to provide our clients and customers with exceptional service at the best possible value. You can check out our detailed working process.'
        },
        {
            id : 4,
            icon : 'images/services/compare.png',
            question : 'How we differ from others?',
            answer : 'Unlike others, we create our unique mobile app, and even we add life to your app with an explainer video if needed. All our assets are 100% copyright-free, and thus will give you your won ownership of these items.'
        },
    ]
};

const Review = () => {
    return {
        review : 'There is no doubt in my mind that, this is absolutely the best web design company. Their web development skills are the best I have seen ever and their work ethics are very professional. They created a high-quality web site for my business and it was the best investment I made for my business. Sales have increased dramatically thank you AStrick and the Team!',
        customer : 'Arlington - United States',
    }
};

export default {
    Hero,
    Discover,
    Video,
    Features,
    Portfolio,
    Queries,
    Review,
}