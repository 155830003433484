<script>

    // javascript Library
    import Locale from './../../api/Locale';
    import Config from './../../api/config';

    export default {
        props: ['current', 'type'],

        setup (props) {
            
            // collect portfolio content
            let Portfolio = Locale.AStrickPortfolio ();

            // AStrick more projects
            let MoreProjects;
            if (props.type == 'project') MoreProjects = Portfolio.projects;
            else MoreProjects = Portfolio.products;

            // filter not to include current one
            MoreProjects = MoreProjects.filter((project) => {
                if (project.route == props.current) {}
                else {
                    return project;
                }
            });

            // filter only 3 
            MoreProjects = MoreProjects.filter((project, index) => {
                if (index < 3) return project;
            });

            return {
                MoreProjects,
            }
        }
    }

</script>

<template>
    <section class="asportfolio" style="border:none;">
        <div class="wrapper">
            <div class="product-box">
                <ul class="products" style="display: flex;flex-wrap: wrap;justify-content: center;">
                    <li v-for="project in MoreProjects" :key="project">
                        <router-link :to="{name : project.route}" class="box">
                            <div class="left">
                                <img :src="require(`../../images/portfolio/${project.image}`)" :alt="project.title">
                            </div>
                            <div class="right">
                                <h2>{{project.title}}</h2>
                                <p class="career__p">{{project.description}}</p>
                            </div>
                        </router-link>
                    </li>                           
                </ul>
            </div>
        </div>
    </section>
</template>
