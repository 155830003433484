<script>
    // import js
    import Config from './../../api/config';

    // import component
    import Crumb from './../../components/policy/Crumb.vue';
    import Links from './../../components/policy/Links.vue';

    export default {
        name: 'Terms',
        components: {
            Crumb,
            Links
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'Terms and Conditions - AStrick Software'              
            });
            
        }
    };
</script>

<template>

    <Crumb :page="'Terms and Conditions'" />

    <section class="_policies_page">
        <div class="content-wrapper">

            <h3>Terms of Site Usage and <br />General Terms</h3>

            <p>The following Terms and Conditions may be updated from time to time without notice. This website, www.astricksoftware.com (the “Site”), may, from time to time, offer and promote services other than this Site, which services may be governed by different Terms and Conditions.</p>
            
            <h2>Terms of Service Usage</h2>

            <p>We currently offer special flat rates for three different packages namely “Mover”, “Shaker” and “Earthquaker” depending upon the no of days to complete the project and services included, which are available at our order page; fixed rates for Email Template design, and custom quotes for complex front-end and CMS-based projects based on the complexity of the project and the skills required to accomplish it.</p>
            
            <p>Additional charges may apply depending on the project complexity. We reserve the right to amend our pricing for any changes or additions to the project after our commencement of the project. We also reserve the right to revise the quote or refuse to work on a project and issue a refund in case an actual scope of work required for the project completion substantially exceeds the flat-rate amount paid.</p>
            
            <h2>WORKING SCHEDULE</h2>

            <p>Development Consultants and Live Chat Support: Monday-Saturday, around the clock.</p>

            <p>The Development Consultants provide the quote &amp; ETA within 1 hour for mark-up and within 3 hours for implementation/development according to the schedule above.</p>
            
            <h2>DELIVERY TIMELINES</h2>

            <p>The standard turnaround time for the first page of non-responsive HTML / CSS layout usually takes 2 working days and 3 working days for the responsive one. Each additional/inner page associated with the same project generally takes 3 working days, depending on the complexity and specifications of the project. Further SEO and social networking link up take another 3 days.</p>
            
            <p>The delivery timeline takes effect at the time that </p>
            
            <p>(1) Payment has been made for the order and </p>
            <p>(2) The order details have been accepted and agreed upon by the parties. </p>
            
            <p>The order is considered to be commenced at the time your project manager gives you the estimated delivery date. The estimated timelines for JavaScript, CMS development are provided after the investigation of the designs and requirements.</p>
            
            <h2>ACCEPTANCE</h2>
            
            <p>Default acceptance criteria apply to all projects and all project stages. A delivery is considered accepted if:</p>
            
            <p>The client approves a delivery in the order thread or</p>
            
            <p>The client does not send any feedback/change request within one week (7) days for mark-up project and </p>
            
            <p>Within thirty (20) days for CMS integration/development projects.</p>
            
            <h2>FIXES/UPDATES AND POST-DELIVERY SUPPORT</h2>
            
            <p>We provide all fixes (if any) for free until the project is accepted according to the “ACCEPTANCE” paragraph.</p>
            
            <p>Paid updates/changes are available upon requests.</p>
            
            <p>Any updates for accepted implementation/development projects delivered, beyond the revision times as provided in the package will not be entertained. If any revision and updates required, we will be charged ten (10) USD per hour. All further updates are charged at ten (10) USD per hour.</p>
            
            <h2>PAYMENT</h2>

            <p>Payments will be charged on a pre-pay basis on the day that the order is created. Refunds are provided under certain circumstances, as outlined in the paragraph “REFUND POLICY” of this Terms of Service Agreement.</p>
            
            <h2>CONTENT AND TERMS AND CONDITIONS</h2>
            
            <p>We reserve the right to refuse to work on any project, without the need for justifying therefore. We will not provide services for any project or potential client for the content which is offensive, illegal, or which contains illicit or infringing material. The Client is fully responsible for the provision, exploitation, maintenance, and use of all content, provided by him, including, but not limited to, issues related to:</p>
            
            <p>Correspondence of the Client's content to provisions of laws about copyright protection of all applicable jurisdictions; Any claims, related to the content;</p>
            
            <p>The immediate reaction for notices to the Client (or affiliated persons) by any person, claiming that the Client's content is infringing the rights of such person.</p>

            <p>The Client shall indemnify and hold AStrick Software Pvt. Ltd. its affiliates, subsidiaries, and related companies harmless from any losses, claims, obligations, or other liabilities related to or arising out of the foregoing.</p>
            
            <h2>CONSENT TO RECORDING OF CONVERSATIONS</h2>
            
            <p>Any conversation a User has with any representative of AStrick Software may be monitored and recorded for quality assurance purposes.</p>
            
            <p>A User who does not wish to have a conversation recorded must warn the representative with whom such User is speaking to cease recording such conversation.</p>
            
            <p>A User's continued participation in a conversation without expressly requesting for the conversation to not be recorded shall serve as such User's express consent to have the conversation monitored and recorded.</p>

        </div>
    </section>

    <Links />    

</template>