<template>
    <IllustrationHero :content="BreadCrumbsData" />

    <!-- Section Page Content -->
    <section class="_working__process">
        <div class="wrapper">
            
            <div class="products-details">
                <div class="pd-wrap">                            
                    <div class="centerpixel" :style="{'background':'url('+require('../../images/icons/pix.png')+')  repeat-y 50% 0'}">
                
                        <div class="product-section">
                        
                            <a href="#!" class="icon-container">
                                <div class="icon">
                                    <h3>1</h3>
                                </div>
                            </a>
                                        
                            <div class="maia-cols">
                            
                                <div class="maia-col-6 text left">                                
                                    <div class="vcenter">
                                        <h2>Analysis<br /><strong>Step 1</strong></h2>
                                        <p>This is where everything begins when you've placed your order, maybe via our order form or over the phone. We start by listening to our clients. We read through your project specifications, check all your files, and in most cases, see how we can make your project better. If we have any questions or suggestions, we will let you know and go over everything together one on one. Once everything is clear, we'll start working on your project.</p>
                                    </div>                                
                                </div>
                                
                                <div class="maia-col-6 image right">                                
                                    <div class="vcenter">
                                        <ImageApp :source="StockUrl + 'about/analysis.svg'" alt="Analysis" title="Analysis" width="522" style="margin-top: 0px;margin-right: 85px;width: 350px;" />
                                    </div>                                
                                </div>                                
                            </div>
                        
                        </div>
                        
                        <div class="product-section">                        
                            <a href="#!" class="icon-container">
                                <div class="icon">
                                    <h3>2</h3>
                                </div>
                            </a>                        
                            <div class="maia-cols clearfloat">                            
                                <div class="maia-col-6 text right">                                
                                    <div class="vcenter">
                                        <h2>Planning<br /><strong>Step 2</strong></h2>
                                        <p>Great front-end development takes skill and craft to deliver impeccable results. Well-planned intelligent mark-up takes analysis and experience. Solid implementation projects involve deep investigations into how things should be done. Your project manager will decide who the best fit is for your project. Mark-up developers analyze the client provided specification files to see the best way to slice your requirement and plan the HTML hierarchy and CSS structure.</p>
                                    </div>                                
                                </div>                                
                                <div class="maia-col-6 image left">                                
                                    <div class="vcenter">
                                        <ImageApp :source="StockUrl + 'about/planning.svg'" alt="Planning" title="Planning" width="421" />
                                    </div>                                
                                </div>                                
                            </div>

                        </div>
                        
                        <div class="product-section">                        
                            <a href="#!" class="icon-container">
                                <div class="icon">
                                    <h3>3</h3>
                                </div>
                            </a>
                                        
                            <div class="maia-cols">                            
                                <div class="maia-col-6 text left">                                
                                    <div class="vcenter">
                                        <h2>Development <br /><strong>Step 3</strong></h2>
                                        <p>At AStrick, we are always ahead of the curve. Our developers continually work to explore new technologies, methods, and techniques in mark-up creation as well as development. All the developers assigned to your project are trained internally to maintain the high-quality standards of AStrick. Working on one project at a time assures dedicated project focus and a rapid turnaround, thus ensuring clients always receive top-notch and the most intelligent mark-up and implementation development.</p>
                                    </div>                                
                                </div>
                                
                                <div class="maia-col-6 image right">                                
                                    <div class="vcenter">
                                        <ImageApp :source="StockUrl + 'about/development.svg'" alt="Back-End Design" title="Back-End Design" width="300" style="margin-right:85px;" />
                                    </div>                                
                                </div>                                
                            </div>
                        
                        </div>
                                
                        <div class="product-section">                        
                            <a href="#!" class="icon-container">
                                <div class="icon">
                                    <h3>4</h3>
                                </div>
                            </a>
                        
                            <div class="maia-cols clearfloat">                            
                                <div class="maia-col-6 text right">                                
                                    <div class="vcenter">
                                        <h2>Quality Assurance <br /> <strong>Step 4</strong></h2>
                                        <p>After your developer(s) finishes your project, our quality assurance phase starts. This is where your project manager, our quality assurance team, and the developer(s) all meet together to make sure everything is perfect and exactly the way you wanted. This includes cross-browser and -device testing, ensuring your conversion is pixel perfect, reviewing mark-up, thorough testing of your implementation, and auditing the final project checklist.</p>
                                    </div>                                
                                </div>
                                
                                <div class="maia-col-6 image left">                                
                                    <div class="vcenter">
                                        <ImageApp :source="StockUrl + 'about/qa-engineering.svg'" alt="Quality" title="Quality" width="275" />
                                    </div>                                
                                </div>
                                
                            </div>
                        </div>            
                        
                        <div class="product-section">
                        
                            <a href="#!" class="icon-container">
                                <div class="icon">
                                    <h3>5</h3>
                                </div>
                            </a>                                        
                            <div class="maia-cols">                            
                                <div class="maia-col-6 text left">                                
                                    <div class="vcenter">
                                        <h2>Delivery <br /> <strong>Step 5</strong></h2>
                                        <p>Once the project is complete, your project manager will deliver a preview for you to review and to make sure everything is flawless. If you have any questions, need any assistance, want to make some changes, or in the rare case, find we missed something, your project manager and your developer(s) will be right there helping you through the process and making sure you are completely satisfied.</p>
                                    </div>                                
                                </div>                                
                                <div class="maia-col-6 image right">                                
                                    <div class="vcenter">
                                        <ImageApp :source="StockUrl + 'about/qa-engineering.svg'" alt="Delivery" title="Delivery" width="318" />
                                    </div>                                    
                                </div>                                
                            </div>                        
                        </div>                        
                    
                    </div>
                </div>
            </div>

        </div>
    </section>
    <!-- Section Page Content -->

    <CompanyCount />
</template>

<script>
    // import js
    import Config from './../../api/config';
    
     //@ import resuable components
    import IllustrationHero from '../../components/reusable/IllustrationHero.vue';
    import CompanyCount from '../../components/reusable/CompanyCount.vue';
    import ImageApp from './../../components/app/ImageApp.vue';

    export default {
        name : 'AboutUs',

        components : {
            IllustrationHero,
            CompanyCount,
            ImageApp
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'Working Process - AStrick Software'              
            });

            const StockUrl = Config.StockUrl;

            const BreadCrumbsData = {
                page : 'Working Process',
                title : 'How We Do It?',
                description : 'We have fine-tuned our process to meet the level of expectations our customers deserve. We guarantee a process that ends in smiles.',
                image : 'work-in-process.svg'
            }

            return {
                BreadCrumbsData,
                StockUrl
            };
        }
    };
</script>