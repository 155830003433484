<template>
    <div class="blog--list">
        <div class="wrapper">
            <div class="default-list">
                <ul class="dl-large">
                    <li v-for="item in LargeItems" :key="item" :style="{backgroundImage: 'url('+ item.bgimage +')'}">
                        <a href="#">
                            <div class="content">
                                 <Skeleton :loop="1" :type="'line'" :dark="true" :css="'width: 85%;'" />
                                 <Skeleton :loop="1" :type="'line'" :dark="true" :css="'width: 75%;'" />
                                 <Skeleton :loop="1" :type="'small-line'" :dark="true" :css="'width: 40%;'" />
                            </div>
                        </a>
                    </li>
                </ul>

                <ul class="dl-small">
                    <li v-for="item in SmallITems" :key="item">
                        <a href="#">
                            <div class="image"></div>
                            <div class="content">
                                <Skeleton :loop="1" :type="'line'" :dark="true" :css="'width: 85%;'" />
                                <Skeleton :loop="1" :type="'line'" :dark="true" :css="'width: 75%;'" />
                                <Skeleton :loop="1" :type="'small-line'" :dark="true" :css="'width: 40%;'" />
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import Skeleton from './../app/Skeleton.vue';

    export default {
        name: "DefaultList",
        components: {
            Skeleton,
        },

        setup (props) {
            let Content = [
                {id: 1},{id: 1},{id: 1},{id: 1},{id: 1},{id: 1}
            ];

            let LargeItems = [];
            let SmallITems = [];

            Content.map ((item, index) => {
                if (index == 0 || index == 1) {
                    LargeItems.push (item);
                }else {                
                    SmallITems.push(item);
                }
            });

            return {LargeItems, SmallITems}
        }
    };
</script>

<style scoped>
    ul.dl-large > li, ul.dl-small > li > a div.image {
    background-color: #e4e4e4;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;}
    ul.dl-large > li > a:after {
    background: linear-gradient(to bottom, rgb(228 228 228) 0%,rgb(228 228 228) 6%,rgb(195 195 195 / 85%) 77%,rgb(167 167 167) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#26042172', endColorstr='#d9042172',GradientType=0 );
    }
</style>