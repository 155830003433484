<template>
    <section class="__counts" :style="{'background-image':'url('+require('../../images/large/black-pattern-bg.png')+')'}">
        <div class="wrapper">        
            <div class="content">
                <h3>We have clients in over 20 countries.</h3>
            </div>
            <ul>
                <li>
                    <h3>2500+</h3>
                    <h2>COMPLETED PROJECTS</h2>
                    <p>5-star delivery in more than 2k projects.</p>
                </li>
                <li>
                    <h3>26</h3>
                    <h2>Team Members</h2>
                    <p>A dedicated team to help you succeed in your business.</p>
                </li>
                <li>
                    <h3>14</h3>
                    <h2>Years Completed</h2>
                    <p>Delivering on the promise we made to our clients 14 years ago.</p>
                </li>
                <li>
                    <h3>1500+</h3>
                    <h2>Online PROJECTS</h2>
                    <p>Ranging from Web to Mobile Development, we have done it all.</p>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
    export default {
        name : 'CompanyCount',
    }
</script>