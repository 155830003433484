<template>
    <IllustrationHero :content="BreadCrumbsData" />

    <!-- Section Page Content -->
    <section class="__team-page">
        <div class="wrapper">

            <h2>AStrick's Team Members</h2>
            <p>The best one can ask for, helping our clients in making their dream business a reality. Supporting your business and helping it grow around todays technology.</p>

            <ul class="_tp_executive">
                <li>
                    <div class="image">
                        <ImageApp :source="StockUrl + 'team/arjun-kandel.jpg'" />
                    </div>
                    <h3>Arjun Kandel</h3>
                    <p>His vision as the founder of the company is vital for the development and growth of the business. He is working as Full Stack Developer and managing the whole concerns in AStrick., always keen to learn new things.</p>
                </li>

                <li>
                    <div class="image">
                        <ImageApp :source="StockUrl + 'team/dhruba-kandel.jpg'" />
                    </div>
                    <h3>Dhruba Kandel</h3>
                    <p>Dhruba started as a technical officer from the year 2010 to AStrick and focusing his time on developing and growing the company, by night he's a cooking addict.</p>
                </li>

                <li>
                    <div class="image">
                        <ImageApp :source="StockUrl + 'team/deepak-sigdel.jpg'" />
                    </div>
                    <h3>Deepak Sigdel</h3>
                    <p>Sigdel is a creative front-end designer at the beginning when he started on AStrick. He's here in the company focusing on the operation of things, in the spare and vacation time he is the best to choose a good location.</p>
                </li>

                <li>
                    <div class="image">
                        <ImageApp :source="StockUrl + 'team/raju-gaire.jpg'" />
                    </div>
                    <h3>Raju Gaire</h3>
                    <p>A good friend of all the team members. He has been managing all the financial operations of the company since he joined and working hard for the company's financial stability and sustainability.</p>
                </li>

                <li>
                    <div class="image">
                        <ImageApp :source="StockUrl + 'team/surya-kandel.jpg'" />
                    </div>
                    <h3>Surya Kandel</h3>
                    <p>Front-End Designer, a freelancer to the company, partnering with AStrick to work on all Front-end requirements and handle our freelancers for each tasks.</p>
                </li>

                <li>
                    <div class="image">
                        <ImageApp :source="StockUrl + 'team/shobha-kandel.jpg'" />
                    </div>
                    <h3>Shobha Sapkota Kandel</h3>
                    <p>She has started AStrick to work with the SEO, later she made herself a well-educated digital geek, and now she is the head of the e-Marketing team.</p>
                </li>

                <li>
                    <div class="image">
                        <ImageApp :source="StockUrl + 'team/mike-subedi.jpg'" />
                    </div>
                    <h3>Mike Subedi Jr.</h3>
                    <p>Mike currently handles the front-end design tasks and also he is working to manage the WordPress based websites and other CMS.</p>
                </li>
            </ul>

            <ul class="_tp_staff">
                <li>
                    <h3>Sushant Thapa</h3>
                    <p>Development Consultant, with his ample experience and knowledge, he helps our team in meeting their deadlines.</p>
                </li>

                <li>
                    <h3>Preeti Subedi</h3>
                    <p>Search Engine Optimization, Preeti works to serve our clients with the best digital marketing strategies out there.</p>
                </li>

                <li>
                    <h3>Nisha Sapkota</h3>
                    <p>SEO and Digital Marketing, assisting in ensuring the Marketing department runs smoothly.</p>
                </li>

                <li>
                    <h3>Sonisha Subedi</h3>
                    <p>Search Engine Optimization, providing her expertise in the Marketing department.</p>
                </li>

                <li>
                    <h3>Kajol Gaire</h3>
                    <p>Search Engine Optimization, assisting in the Marketing department.</p>
                </li>

                <li>
                    <h3>Sandeep Kandel</h3>
                    <p>Technical Support Head, working as a support agent.</p>
                </li>
            </ul>

        </div>
    </section>
    <!-- Section Page Content -->

    <CompanyCount />
</template>

<script>

    // import js
    import Config from './../../api/config';

     //@ import resuable components
    import IllustrationHero from '../../components/reusable/IllustrationHero.vue';
    import CompanyCount from '../../components/reusable/CompanyCount.vue';
    import ImageApp from './../../components/app/ImageApp.vue';

    export default {
        name : 'AboutUs',

        components : {
            IllustrationHero,
            CompanyCount,
            ImageApp
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'Team - AStrick Software'              
            });

            const StockUrl = Config.StockUrl;

            const BreadCrumbsData = {
                page : 'Team',
                title : 'Meet The Team',
                description : 'We are dedicated to helping our customers succeed in their business. Our mission is to provide our clients and customers with exceptional service at the best possible value.',
                image : 'team.svg'
            }

            return {
                BreadCrumbsData,
                StockUrl
            };
        }
    };
</script>