<template>
    <section class="aservices-footer">
        <div class="wrapper">
            <ul class="ase-header">                
                <li :class="{ 'active' : service.active}" v-for="service in Services" :key="service.key" @click="serviceClicked(service)">
                    <div class="title">
                        <h3>{{service.title}}</h3>
                    </div>
                    <div class="content ease-effect">
                        <p>{{service.description}}</p>
                    </div>
                    <span class="SpanWidth" :style="`width:` + SpanWidth + '%'"></span>
                </li>
            </ul>
            
            <ul class="ase-images">
                <div v-for="service in Services" :key="service.key">
                    <li v-if="service.active && service.key == 1" class="animated fadeInUp">
                        <ImageApp :source="service.image" />
                        <router-link :to="{name : service.link}" class="app-btn anas3s fadeInUp">View Detail</router-link>
                    </li>

                    <li v-if="service.active && service.key == 2" class="animated fadeInUp">
                        <ImageApp :source="service.image" />
                        <router-link :to="{name : service.link}" class="app-btn anas3s fadeInUp">View Detail</router-link>
                    </li>

                    <li v-if="service.active && service.key == 3" class="animated fadeInUp">
                        <ImageApp :source="service.image" />
                        <router-link :to="{name : service.link}" class="app-btn anas3s fadeInUp" style="margin-top: -100px;">View Detail</router-link>
                    </li>

                    <li v-if="service.active && service.key == 4" class="animated fadeInUp">
                        <ImageApp :source="service.image" />
                        <router-link :to="{name : service.link}" class="app-btn anas3s fadeInUp">View Detail</router-link>
                    </li>

                    <li v-if="service.active && service.key == 5" class="animated fadeInUp">
                        <ImageApp :source="service.image" />
                        <router-link :to="{name : service.link}" class="app-btn anas3s fadeInUp">View Detail</router-link>
                    </li>
                </div>
            </ul>
        </div>
    </section>
</template>

<script>
    
    // javascript Library
    import Locale from './../../api/Locale';

    // component
    import ImageApp from './../../components/app/ImageApp.vue';

    // vue
    import {ref, reactive, computed} from 'vue';
    import { useRouter, useRoute } from 'vue-router';

    export default {
        name: 'FooterServices',
        components : {
            ImageApp,
        },
        
        setup () {

            const Router = useRouter();
            const Route = useRoute();            

            // footer services data
            let Services = reactive(Locale.AStrickServices ());
            
            // auto loop
            let Looping = 0;
            let CurrentService = reactive(Services[Looping]);

            let ServicesTimer = ref(0);
            let SpanWidth = ref(0);
            setInterval (()=> {
                ServicesTimer.value++;

                // span width
                SpanWidth.value = Number(ServicesTimer.value) / 120 * 100;

                // reset timing
                if (ServicesTimer.value == 120) {
                    ServicesTimer.value = 0;

                    Looping++;
                    if (Looping == 5) Looping = 0;
                    CurrentService = Services[Looping];
                    
                    serviceClicked(CurrentService);                    
                }

            }, 50);

            const serviceClicked = (service) => {
                Looping = service.key - 1;
                CurrentService = Services[Looping];
                
                Services = Services.filter(srcv => {
                    srcv.active = false;
                    if (service.key == srcv.key) srcv.active = true;
                    return srcv;
                });

                ServicesTimer.value = 0;
            };

            // return reactive data
            return {
                Services,
                CurrentService,
                serviceClicked,
                ServicesTimer,
                SpanWidth
            }
        }
    }
</script>