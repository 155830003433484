<script>
    // js import
    import PortfolioInternal from './../../api/PortfolioInternal';
    import Config from './../../api/config';

    //@import component
    import BreadCrumb from './../../components/portfolio/BreadCrumb.vue';
    import MoreProject from './../../components/portfolio/MoreProjects.vue';

    export default {
        components: {
            BreadCrumb,
            MoreProject,
        },

        setup(props) {
            let Internal = PortfolioInternal.PortfolioInternals();
            Internal = Internal.chooseyourforex;

            let StockUrl = Config.StockUrl;

            // page title
            Config.PageTitle ({
                title : Internal.title + ' - AStrick Software'          
            });

            return {
                Internal,
                StockUrl
            }
        }
    };
</script>

<template>
    <BreadCrumb :content="Internal" />

    <section class="features grey">
        <div class="secwrapper">                    
        
            <h3>Project Planning</h3>

            <p>We have, already, worked with around 10 companies in forex industry.  We are also the website and social media consultant to one of the Forex Company for more than 5 years.</p>      

            <p>Working as a website developer and email marketing consultant so many years with one of the leading forex broker, we can help you start your first broker company right from zero.</p>

            <p>We can start together from very beginning – from developing your custom website to help you fetch your first client all the way.
If your excuse is website development and marketing – please feel free to contact us today. It will cost you 0$ only.</p>
            
            <img :src="StockUrl + 'portfolio/choose-your-forex-homepage.jpg'" />

            <h3>Home Page Design</h3>

            <p>We always start designing the static web pages prototype from the very beginning using Adobe XD. This design contains blog, promotions, bonuses, articles and other payment integrated pages. We had a nice and clean porotype of Homepage and few other internal pages which we share with client if they meet their expectation and have any ideas to incorporate? The result was perfect and according to them it was beyond their expectation.</p>
        </div>
    </section>

    <section class="features">
        <div class="secwrapper"> 
            <h3>Project Development</h3>
            <p>After the prototype designing we move forward working to design html/css static pages. Including homepage, blog and other internal pages, we made sure all the pages are ready on html version.</p>
            
            <p>After finishing everything with front-end design we moved forwards managing codes and other development works like Responsive Design, SEO and Cross Browser Compatibility support, Database and Dynamic content on the website.</p>

            <p>Finally we were done with all the works the project and it takes about 6 months to final the whole project. Further, 2 months free support and revision (if any) were provided as after sales service and they were more than happy.</p>

            <div class="video"><iframe width="100%" height="100%" src="https://www.youtube.com/embed/3_agwOo9tY8" frameborder="0" allowfullscreen=""></iframe></div>
            
            <h3>Premium Offer Explainer Video</h3>
            
            <p>While working with the project for few months we slowly grow our knowledge on forex trading and the forex broker's business. And we converted the company features and promotions in Visual Format (Shown Above)</p>                        
        </div>
    </section>

    <MoreProject :current="'ChooseYourForex'" :type="'project'" />

</template>