<template>
    <ServicesHero :content="Hero" :video="{data: {video : '_iPQyIaHsiA', type: 'video'}}" />

    <DiscoverArea :content="Discover" :link="'development'" />

    <VideoBanner :content="Video" style="padding-bottom: 145px;" />

    <PageFeatures :content="Features" :extra="true"  />
    
    <ServicesPortfolio :content="Portfolio" />

    <ServicesFaQs :content="Faqs" />

    <ServicesReview :review="Review" />

</template>

<script>

    // javascript Library
    import PageContent from './../../api/services/development';
    import Config from './../../api/config';

    //@ component import
    import ServicesHero from '../../components/reusable/ServicesHero.vue';
    import DiscoverArea from '../../components/web-design/DiscoverArea.vue';
    import VideoBanner from '../../components/reusable/VideoBanner.vue';

    import PageFeatures from '../../components/reusable/PageFeatures.vue';
    import ServicesPortfolio from '../../components/reusable/ServicesPortfolio.vue';
    import ServicesFaQs from '../../components/reusable/ServicesFaQs.vue';
    import ServicesReview from '../../components/reusable/ServicesReview.vue';


    export default {
        name : 'Development',
        components : {
            ServicesHero,
            DiscoverArea,
            VideoBanner,
            PageFeatures,
            ServicesPortfolio,
            ServicesFaQs,
            ServicesReview
        },

        setup () {

            // page title
            Config.PageTitle ({
                title : 'Web Design and Development - AStrick Software'          
            });
            
            // services content
            let Hero = PageContent.Hero();
            let Discover = PageContent.Discover();
            let Video = PageContent.Video();
            let Features = PageContent.Features();

            let Portfolio = PageContent.Portfolio();
            let Faqs = PageContent.Queries();
            let Review = PageContent.Review();

            return {Hero, Discover, Video, Features, Portfolio, Faqs, Review}
        },
        
    };
</script>